import { useEffect, useState } from "react";
import tutorialPoster from "../../../assets/tutorialPoster.png";
import { Pagination } from "react-bootstrap";
import RequestTutModal from "./RequestTutModal";
import axios from "../../../api/axios";

const RequestedTut = ({ tutorials }) => {
  const userId = sessionStorage.getItem("userID");
  const [reqTutorials, setreqTutorials] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `ShowRequestedTutorial/${userId}/pending`
        );
        const resp = response.data.data;
        if (resp.status === "success") {
          setreqTutorials(resp.message);
          console.log(resp.message);
        } else {
          setreqTutorials([]);
          console.log(resp.message);
        }
      } catch (error) {
        console.log("Error", error);
      }
    };
    fetchData();
  }, [userId]);

  const CARDS_PER_PAGE = 6;

  const [activePage, setActivePage] = useState(1);

  const totalPages = Math.ceil(reqTutorials.length / CARDS_PER_PAGE);

  const handleClick = (event) => {
    const targetPage = Number(event.target.text);
    setActivePage(targetPage);
  };
  const startIndex = (activePage - 1) * CARDS_PER_PAGE;
  const endIndex = startIndex + CARDS_PER_PAGE;
  const visibleItems = reqTutorials.slice(startIndex, endIndex);

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === activePage} onClick={handleClick}>
        {i}
      </Pagination.Item>
    );
  }

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };
  return (
    <div className="container-fluid tutorial-container">
      {reqTutorials.length === 0 ? (
        <p className="text-center mt-5">No requested tutorials available</p>
      ) : (
        <div className="row row-cols-1 row-cols-md-3">
          {visibleItems.map((tutorial, index) => (
            <div className="col" key={index}>
              <div className="tutorial-card-container d-block p-3 mb-3">
                <div className="card border-0">
                  <img
                    src={tutorialPoster}
                    alt=""
                    className="tutorial-card-img"
                  />
                </div>

                <div className="p-2 d-block">
                  <div className="d-flex align-items-center mt-2">
                    <span className={tutorial.classname}>
                      {tutorial.duration} mins
                    </span>
                    {/* <span className="tutorial-fee ms-auto">250 Coins</span> */}
                    <span className="tutorial-fee ms-auto text-warning">
                      Pending
                    </span>
                  </div>
                  <h5 className="font-20-700">{tutorial.title}</h5>
                  <p className="font-12-400 ">{tutorial.about}</p>
                </div>
                {/* <div className="d-flex w-100">
                  <span className="lato-12-300 pe-1">Tutor: </span>
                  <div className="d-block">
                    <p className="lato-12-400 mb-0 fw-bold"> Mr olu Amayo</p>
                    <span className="lato-8-400">
                      Lecturer II, REdeemers University, dept of computer
                      science
                    </span>
                  </div>
                </div> */}
                <div className="d-flex">
                  <span className="lato-12-300 pe-1">Date: </span>
                  <div className="">
                    <p className="lato-12-400 mb-0 fw-bold">
                      {formatDateTime(tutorial.time)}
                    </p>
                    {/* <h6 className="lato-8-400 ms-auto">8pm</h6> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="card-pagination d-flex">
        <Pagination className="ms-auto me-4">{paginationItems}</Pagination>
      </div>
      <RequestTutModal />
    </div>
  );
};

export default RequestedTut;
