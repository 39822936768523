import wallet from "../../../assets/wallet.svg";

const OrgHighlightCards = () => {
  const students = sessionStorage.getItem("orgTotalStudents");
  const credits = sessionStorage.getItem("orgTotalCredits");
  const courses = sessionStorage.getItem("orgTotalCourses");

  return (
    <div className="mb-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="org-highlight-card shadow">
            <div className="d-flex align-items-center">
              <div className="d-block">
                <p className="viga-14-400 text-grey">Total Credits</p>
                <div className="d-flex align-items-center">
                  <h4>₦{credits}</h4>
                  {/* <span className="text-success">+ 55%</span> */}
                </div>
              </div>
              <div className="ms-auto">
                <div className="org-highlight-card-icon">
                  <img src={wallet} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="org-highlight-card shadow">
            <div className="d-flex align-items-center">
              <div className="d-block">
                <p className="viga-14-400 text-grey">Total Students</p>
                <div className="d-flex align-items-center">
                  <h4>{students}</h4>
                  {/* <span className="text-success">+ 55%</span> */}
                </div>
              </div>
              <div className="ms-auto">
                <div className="org-highlight-card-icon">
                  <img src={wallet} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="org-highlight-card shadow">
            <div className="d-flex align-items-center">
              <div className="d-block">
                <p className="viga-14-400 text-grey">Total Courses</p>
                <div className="d-flex align-items-center">
                  <h4>{courses}</h4>
                  {/* <span className="text-success">+ 55%</span> */}
                </div>
              </div>
              <div className="ms-auto">
                <div className="org-highlight-card-icon">
                  <img src={wallet} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgHighlightCards;
