import React from "react";
import whiteLogo from "../../assets/whiteLogo.png";
import { NavLink } from "react-router-dom";
// import logout from "../../assets/img/logout.svg";
const DashboardSidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={whiteLogo} alt="Logo" />
      </div>
      <nav className="navbar">
        <NavLink
          to="/userdashboard"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-grid-fill nav-icon"></i>
            Feeds
          </div>
        </NavLink>
        <NavLink
          to="/courses"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi-file-earmark-richtext nav-icon"></i>
            Study Resources
          </div>
        </NavLink>
        <NavLink
          to="/usertutorials"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-people nav-icon"></i>
            Tutorials
          </div>
        </NavLink>
        <NavLink
          to="/userlms"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-building nav-icon"></i>
            LMS
          </div>
        </NavLink>

        <NavLink
          to="/userwallet"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-wallet nav-icon"></i>
            My Wallet
          </div>
        </NavLink>
        <NavLink
          to="/awards"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-award nav-icon"></i>
            Scholarships
          </div>
        </NavLink>
        <NavLink
          to="/notifications"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-bell nav-icon"></i>
            Notifications
          </div>
        </NavLink>
      </nav>

      <div className="sidebar-tools">
        <nav className="navbar">
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            }
          >
            <div className="nav-item d-flex">
              <i className="bi bi-person-circle nav-icon"></i>
              Profile
            </div>
          </NavLink>
          <NavLink
            to="/help"
            className={({ isActive }) =>
              isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            }
          >
            <div className="nav-item d-flex">
              <i className="bi bi-question-circle nav-icon"></i>
              Help
            </div>
          </NavLink>
          {/* <NavLink
            // to="#"
            // className={({ isActive }) =>
            //   isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            // }
          > */}
          <div
            role="button"
            className="nav-item d-flex"
            data-bs-toggle="modal"
            data-bs-target="#logoutModal"
          >
            <i className="bi bi-box-arrow-in-left nav-icon"></i>
            Logout
          </div>
          {/* </NavLink> */}
        </nav>
      </div>
    </div>
  );
};

export default DashboardSidebar;
