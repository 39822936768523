import underr from "../../assets/Vector 1.png";
import Button from "react-bootstrap/Button";
import pic1on1 from "../../assets/Rectangle 14.png";
import Earn from "../../assets/Earnn.png";
import { Link } from "react-router-dom";
import { AnimateOnScroll } from "animate-on-scroll-framer";
import Partners from "../../assets/Partners.png";

const HomeTutorials = () => {
  return (
    <div className="tutorials">
      <div className="vectt">
        <h1>Tailored Tutorials</h1>
        <img src={underr} alt="" />
      </div>
      <h2>
        Get tutorials from expert educators, designed to address your individual
        learning gaps.
      </h2>
      <div className="ptutor">
        <div className="ptutorimg">
          <AnimateOnScroll duration={2} animation="flipIn">
            <img src={pic1on1} alt="" />
          </AnimateOnScroll>
        </div>
        <div className="ptutortext">
          <h3>Personalized and Group Tutorial Sessions</h3>
          <h5>
            Tailored Learning Experiences Just for You! Discover personalized
            tutorials designed to match your unique learning style and pace. Get
            the support you need to excel in your studies, all on ETC Vibes!.
          </h5>
          <Link to="/signup">
            <Button className="my-4" size="lg" variant="primary">
              Get Started
            </Button>
          </Link>
        </div>
      </div>
      <div className="ptutor gtutor">
        <div className="ptutorimg">
          <AnimateOnScroll duration={2} animation="flipIn">
            <img src={Earn} alt="" />
          </AnimateOnScroll>
        </div>
        <div className="ptutortext">
          <h3>Upload and Earn on ETC!</h3>
          <h5>
            Upload your notes, summaries, flashcards, or any study materials
            you've created. Help your peers ace their exams while you earn
            rewards! Every resource you contribute earns you tokens that can be
            redeemed for amazing rewards. It's a win-win!
          </h5>
          <Link to="/signup">
            <Button className="my-4" size="lg" variant="primary">
              Get Started
            </Button>
          </Link>
        </div>
      </div>
      <div className="ptutor">
        <div className="ptutorimg">
          <AnimateOnScroll duration={2} animation="flipIn">
            <img src={Partners} alt="" />
          </AnimateOnScroll>
        </div>
        <div className="ptutortext">
          <h3>Partner Onboard</h3>
          <h5>
            Join our platform as an affiliate organization and unlock exclusive
            tools to enhance your team's learning experience. Get started with
            seamless integration and tailored resources to empower your
            organization!
          </h5>
          <Link to="/signup">
            <Button className="my-4" size="lg" variant="primary">
              Get Started
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeTutorials;
