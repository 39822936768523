import React, { useEffect, useState } from "react";
import DashboardSidebar from "../DashboardSidebar";
import LogoutModal from "../../../components/LogoutModal";
import DashboardHeader from "../DashboardHeader";
import MobileNav from "../../../components/MobileNav";
import { toast, ToastContainer, Zoom } from "react-toastify";
import axios from "../../../api/axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import pqImg from "../../../assets/img/Pastquestions solution.jpg";
import noteImg from "../../../assets/img/note.jpg";
import textbookImg from "../../../assets/img/textbook.jpg";
import videoImg from "../../../assets/Videoresources.png";
import ratingBadge from "../../../assets/Ratings badge.png";
import { JellyTriangle } from "@uiball/loaders";

const CoursePreview = () => {
  const navigate = useNavigate();
  const [ratings, setRatings] = useState({});
  const userId = sessionStorage.getItem("userID");
  const [loading, setloading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [courseNameLoading, setCourseNameLoading] = useState(false);

  const { state } = useLocation();
  const {
    pdfUrl,
    id,
    name,
    description,
    author,
    views,
    institution,
    resourceType,
    courseId,
  } = state;

  // Function to handle rating changes
  const handleRatingChange = (id, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [id]: rating,
    }));

    // Send rating to endpoint
    sendRatingToEndpoint(id, rating);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      setSuggestionsLoading(true);
      try {
        const response = await axios.get(`/suggestions/${userId}/${courseId}`);
        const data = response.data;
        setSuggestions(data);
        setSuggestionsLoading(false);
      } catch (error) {
        toast.error("Error fetching Suggested Resources");
      }
    };

    fetchSuggestions();

    const fetchCourseName = async () => {
      if (resourceType !== "org_notes") {
        setCourseNameLoading(true);
        try {
          const response = await axios.get(`ListCourses/${courseId}`);
          const data = response.data.name;
          setCourseName(data);
          setCourseNameLoading(false);
        } catch (error) {
          toast.error("Error Fetching Course Name");
        }
      }
    };
    fetchCourseName();
  }, [userId, courseId, pdfUrl, resourceType]);

  const viewMore = async () => {
    setloading(true);
    try {
      const response = await axios(`/view_more_access/${userId}/${courseId}`);
      const resp = response.data;
      if (resp.message === "You have access to this course.") {
        navigate(`/courses/coursepage/${courseId}`);
      } else {
        toast.warning(resp.message || "Access denied", {
          theme: "colored",
          autoClose: 2000,
        });
      }
      setloading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong", {
        theme: "colored",
        autoClose: 2000,
      });
      setloading(false);
    }
  };

  // Function to send rating to an endpoint
  const sendRatingToEndpoint = async (id, rating) => {
    const obj = {
      user_id: userId,
      resource_type: resourceType,
      resource_id: parseInt(id),
      rating,
    };
    try {
      const response = await axios.post(`reviews/${userId}`, obj);
      const resp = response.data;
      if (resp.message === "Review added successfully") {
        toast.success("Review added", {
          theme: "colored",
          autoClose: "2000",
        });
      } else {
        toast.warning("Error !", {
          theme: "colored",
          autoClose: "2000",
        });
      }
    } catch (error) {
      // Handle error
      console.error(error.response.data.error);
      toast.warning(error.response.data.error, {
        theme: "colored",
        autoClose: "2000",
      });
    }
  };
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content px-2 px-md-4">
        <DashboardHeader />
        <div className="course-header px-2 pt-0 pb-3 course-name">
          <div className="d-flex align-items-center">
            {resourceType === "org_notes" ? (
              <p className="viga-20-400 course-name m-0">{name}</p>
            ) : courseNameLoading ? (
              <span className="spinner-border spinner-border-sm text-blue"></span>
            ) : (
              <p className="viga-20-400 course-name m-0">{courseName}</p>
            )}
            <div className="viga-24-400 text-grey ms-auto" onClick={viewMore}>
              <button className="view-btn-active" disabled={loading}>
                {loading ? "Loading..." : "View Course"}
              </button>
            </div>
          </div>
        </div>
        <div
          className="d-md-flex gap-3 container-fluid"
          style={{
            marginBottom: "100px",
          }}
        >
          <div className="course-preview">
            <Link
              to={
                resourceType === "video" || resourceType === "videos"
                  ? `/courses/coursepage/resourcepage/${encodeURIComponent(
                      pdfUrl
                    )}`
                  : `/courses/coursepage/resourcepage/document/${encodeURIComponent(
                      pdfUrl
                    )}`
              }
            >
              <img
                src={
                  resourceType === "pastquestion" ||
                  resourceType === "past_questions"
                    ? pqImg
                    : resourceType === "org_notes" ||
                      resourceType === "note" ||
                      resourceType === "org_note"
                    ? noteImg
                    : resourceType === "video"
                    ? videoImg
                    : textbookImg
                }
                alt="Past Question"
                className="w-100"
              />
            </Link>
            <div className="my-2 d-flex justify-content-between gap-4 align-items-center">
              <h1 className="viga-24-400">{description}</h1>
              <div className="d-flex gap-2 viga-24-400">
                <i className="bi bi-eye"></i>
                {views || 0}
              </div>
            </div>
            <div className="d-md-flex d-block justify-content-between mb-4">
              <div>
                <p className="viga-20-400">
                  Uploaded by :{" "}
                  {author == null || author === "" ? "ETC Vibes" : author}
                </p>
                <p className="viga-20-400">From : {institution}</p>
                {resourceType === "pastquestion" && (
                  <p className="viga-20-400">Year : {name}</p>
                )}
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="">
                  {/* 5-star rating input */}
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleRatingChange(id, star)}
                      onMouseEnter={() =>
                        setRatings({ ...ratings, hoverRating: star })
                      }
                      onMouseLeave={() =>
                        setRatings({ ...ratings, hoverRating: null })
                      }
                      style={{
                        cursor: "pointer",
                        color:
                          star <= (ratings.hoverRating || ratings[id] || 0)
                            ? "gold"
                            : "gray",
                        transition: "color 0.2s ease-in-out", // Animation duration
                        fontSize: "34px",
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <img src={ratingBadge} alt="Rating Badge" />
              </div>
            </div>
            <p className="lato-20-400">
              You can access more resources | Access checks
              <br />
              -Free Plan: Limited access for 3 days.
              <br />
              -Basic Plan: Unlock 5 courses with essential resources. <br />
              -Silver Plan: Access 11 courses with essential resources. <br />
              <br />
              📚 Get more! With Basic and Silver Plans, dive deeper into
              additional resources for the course you're exploring. Expand your
              knowledge effortlessly!
            </p>
          </div>
          <div className="suggested-resources">
            <p className="viga-24-400 text-center">Suggested Resources</p>
            {suggestionsLoading ? (
              <div className="container d-flex justify-content-center">
                <JellyTriangle size={80} speed={1.75} color="#044aac" />
              </div>
            ) : (
              suggestions.map((suggestion) => (
                <div
                  key={suggestion.description}
                  className="card resource-card border-0 my-3"
                >
                  <Link
                    replace={true}
                    state={{
                      pdfUrl: suggestion.source_url,
                      id: suggestion.id,
                      name: suggestion.name,
                      description: suggestion.description,
                      course: suggestion.course,
                      author: suggestion.author,
                      views: suggestion.views,
                      rating: suggestion.rating,
                      institution: suggestion.institution,
                      resourceType: suggestion.type,
                      courseId: suggestion.courseId,
                    }}
                    className="text-decoration-none text-dark"
                    onClick={() => {
                      sessionStorage.setItem("activeResourceID", suggestion.id);
                      sessionStorage.setItem(
                        "activeResourceType",
                        suggestion.type
                      );
                    }}
                  >
                    <img
                      src={
                        suggestion.type === "pastquestion"
                          ? pqImg
                          : suggestion.type === "org_note" ||
                            suggestion.type === "note" ||
                            suggestion.type === "notes"
                          ? noteImg
                          : suggestion.type === "video"
                          ? videoImg
                          : textbookImg
                      }
                      className="w-100"
                      alt={suggestion.name}
                    />
                  </Link>
                  <div className="p-2 mt-4">
                    <div className="d-flex">
                      <h6 className="card-title">{suggestion.description}</h6>
                      <div className="ms-auto">
                        <i className="bi bi-eye pe-2"></i>
                        {suggestion.views || 0}
                      </div>
                    </div>
                    <div className="footnote-grey">
                      <h6 className="viga-14-400 m-0">{suggestion.title}</h6>
                      <div className="viga-14-400">{suggestion.year}</div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>{" "}
        </div>
        <MobileNav />

        <ToastContainer transition={Zoom}></ToastContainer>
      </div>
    </div>
  );
};

export default CoursePreview;
