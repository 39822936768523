import { useEffect, useState } from "react";
import axios from "../../../api/axios";

const WalletActivities = () => {
  const orgID = sessionStorage.getItem("orgID");
  const [loading, setLoading] = useState(true);
  const pin = sessionStorage.getItem("orgggg");
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage] = useState(5);
  useEffect(() => {
    const fetchOrgTransaction = async () => {
      try {
        const response = await axios.post(`org/${orgID}/transactions`, {
          pin: pin,
        });
        const resp = response;
        if (resp.status === 200) {
          console.log(resp.data.transaction_history);
          setActivities(resp.data.transaction_history);
        } else {
          setActivities([]);
        }
        setLoading(false);
      } catch (error) {
        console.log(error, "error ");
        setLoading(false);
      }
    };
    fetchOrgTransaction();
  }, [orgID, pin]);
  const indexOfLastNotification = currentPage * activitiesPerPage;
  const indexOfFirstNotification = indexOfLastNotification - activitiesPerPage;
  const currentActivities = activities.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const dateTime = new Date(dateTimeString);
    return `${dateTime.toLocaleString("en-US", options)}`;
  };
  return (
    <div className="container-fluid notification-wrapper mt-5 p-1 p-md-4">
      <h3 className="lato-24-600">Transaction History</h3>
      {loading ? (
        <span className="spinner-border spinner-border-lg" role="status"></span>
      ) : (
        <div className="d-block">
          {currentActivities.length === 0 ? ( // Check if activities array is empty
            <p className="lato-20-400 text-blue">No transactions</p>
          ) : (
            <div className="row row-cols-1">
              {currentActivities.map((activity) => (
                <div className="col p-2" key={activity.id}>
                  <div className="tutor-activity-box">
                    <div
                      className="d-flex"
                      id={activity.trans_type}
                      style={{ textTransform: "capitalize" }}
                    >
                      <span className="viga-24-400 m-0">{activity.trans_type}</span>
                      <span className="viga-24-400 my-0 ms-auto">
                        ₦{activity.trans_amount}
                      </span>
                    </div>
                    <span className="lato-20-400">{activity.trans_desc}</span>{" "}
                    <br />
                    <span className="lato-14-400">
                      {formatDateTime(activity.created_at)}
                    </span>
                  </div>
                </div>
              ))}
              <ul className="pagination" style={{ marginTop: "20px" }}>
                {Array.from(
                  {
                    length: Math.ceil(activities.length / activitiesPerPage),
                  },
                  (_, i) => (
                    <li
                      key={i}
                      className={`page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        onClick={() => paginate(i + 1)}
                        className="page-link"
                      >
                        {i + 1}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default WalletActivities;