import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
const OrganizationTable = () => {
  const itemsPerPage = 15; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [TableData, setTableData] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    const getOrganization = async () => {
      try {
        const response = await axios.get("admin/organizations/all");
        const organizationData = response.data.organizations;
        console.log(organizationData);
        setTableData(organizationData);
        setloading(false);
      } catch (error) {
        toast.error(error);
        setloading(false);
      }
    };
    getOrganization();
  }, []);

  // Calculate the indexes of the items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = TableData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const navigate = useNavigate();
  const handleDetails = (id) => {
    navigate(`/adminorg/details/${id}`);
  };
  return (
    <div className="container-fluid p-0">
      <div className="container-fluid">
        <h4 className="viga-24-400 text-blue">All Organization</h4>
        <div className="table-responsive p-2">
          <table className="table table-hover">
            <thead>
              <tr className="resource-head">
                <th scope="col" className="py-3">
                  ID
                </th>
                <th scope="col" className="py-3">
                  Organization Name
                </th>
                <th scope="col" className="py-3">
                  Description
                </th>
                <th scope="col" className="py-3">
                  Country
                </th>
                <th scope="col" className="py-3">
                  Status
                </th>
              </tr>
            </thead>
            {loading ? (
              <div
                className="spinner-border spinner-border-lg text-primary"
                role="status"
              ></div>
            ) : (
              <tbody>
                {currentItems.map((item) => (
                  <tr
                    key={item.id}
                    className="table-row"
                    onClick={() => handleDetails(item.id)}
                  >
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>{item.country}</td>
                    <td>{item.verified === 1 ? "Verified" : "Pending"}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination">
          {Array.from(
            { length: Math.ceil(TableData.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index}
                className={`btn pagination-button mx-1 ${
                  currentPage === index + 1
                    ? "btn-primary active"
                    : "btn-secondary"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationTable;
