import { useEffect, useState } from "react";
import AddAccount from "./AddOrgAccount";
import axios from "../../../api/axios";

const OrgWalletCards = () => {
  const [loading, setLoading] = useState(true);
  const [bankDetails, setBankDetails] = useState([]);
  const orgId = sessionStorage.getItem("orgID");
  const pin = sessionStorage.getItem("orgggg");
  const [balance, setBalance] = useState(0);
  const [openAddAccount, setopenAddAccount] = useState(false);
  const closeAddAccount = () => {
    setopenAddAccount(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const bankResponse = await axios.get(
          `ShowBankDetails/${orgId}/organization`
        );
        const bankDetails = bankResponse.data.data;
        setBankDetails(bankDetails);
        //
        setLoading(false);
      } catch (error) {
        console.log("error");
        setLoading(false);
      }
    };

    const fetchOrgTransaction = async () => {
      try {
        const response = await axios.post(`org/${orgId}/transactions`, {
          pin: pin,
        });
        const resp = response;
        if (resp.status === 200) {
          setBalance(resp.data.wallet_balance);
        } else {
          setBalance([]);
        }
        setLoading(false);
      } catch (error) {
        console.log(error, "error ");
        setLoading(false);
      }
    };
    fetchOrgTransaction();
    fetchData();
  }, [orgId, pin]);
  return (
    <div className="row">
      <div className="col-sm-12 col-md-4 col-lg-5 mb-2">
        <div className="tutor-balance-card p-3 m-auto">
          <h3 className="lato-32-700">Account Balance</h3>

          <h2 className="lato-40-800">
            ₦
            {loading ? (
              <span
                className="spinner-border spinner-border-md"
                role="status"
              ></span>
            ) : (
              balance || 0
            )}
          </h2>
        </div>
      </div>
      <div className="col-sm-12 col-md-8 col-lg-7 mb-2">
        {loading ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
          ></span>
        ) : (
          <div className="tutor-acct-details p-3">
            {bankDetails === "Record does not exist" ? (
              <div className="d-block">
                <div className="d-flex">
                  <p className="lato-20-400">Account Number:</p>
                  <p className="ps-3 lato-20-700">Null</p>
                </div>
                <div className="d-flex">
                  <p className="lato-20-400">Account Name: </p>
                  <p className="ps-3 lato-20-700">Null</p>
                </div>
                <div className="d-flex">
                  <p className="lato-20-400">Bank:</p>
                  <p className="ps-3 lato-20-700">Null</p>
                </div>
              </div>
            ) : (
              <div className="d-block">
                <div className="d-flex">
                  <p className="lato-20-400">Account Number:</p>
                  <p className="ps-3 lato-20-700">{bankDetails.AccountName}</p>
                </div>
                <div className="d-flex">
                  <p className="lato-20-400">Account Name: </p>
                  <p className="ps-3 lato-20-700">
                    {bankDetails.AccountNumber}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="lato-20-400">Bank:</p>
                  <p className="ps-3 lato-20-700">{bankDetails.BankName}</p>
                </div>
              </div>
            )}

            <div className="d-flex align-items-center">
              <button
                className="blue-filled-btn ms-auto"
                onClick={(e) => setopenAddAccount(true)}
              >
                Edit Account
              </button>
              <AddAccount show={openAddAccount} handleClose={closeAddAccount} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrgWalletCards;