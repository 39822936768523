import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const OrgCourseHighlights = () => {
  const orgId = sessionStorage.getItem("orgID");
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`OrgViewCoursesCreated/${orgId}`);
        const data = response.data.courses;
        setCourses(data);
        setLoading(false);
      } catch (error) {
        toast.error("failed to fetch data", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    };
    fetchUserData();
  }, [orgId]);

  return (
    <div className="org-course-highlight shadow">
      <div className="d-flex">
        <h4 className="viga-18-400">Courses</h4>
        <Link className="ms-auto">View All</Link>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Course title</th>
            <th>Users Enrolled</th>
            <th>Revenue</th>
            <th>Restriction</th>
          </tr>
        </thead>
        {loading ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
          ></span>
        ) : (
          <tbody>
            {courses.length > 0 &&
              courses.map((course) => (
                <tr key={course.id}>
                  <td>{course.title}</td>
                  <td>Nil</td>
                  <td>₦0.00</td>
                  <td>{course.is_restricted ? "Private" : "Public"}</td>
                </tr>
              ))}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default OrgCourseHighlights;
