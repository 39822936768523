import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, Zoom, toast } from "react-toastify";
import api from "../../../api/axios";
import pdf from "../../../assets/ETC Privacy.pdf";
import countries from "../../../components/Countries";

const OrgForm = () => {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get("referral_by");
  sessionStorage.setItem("referralID", ref || "");
  const [logo, setLogo] = useState(null);
  const [addressVal, setAddressVal] = useState(null);
  const [addressValOption, setAddressOption] = useState("");
  const [fullname, setFullname] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [c_password, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [isChecked, setIschecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const validateForm = () => {
    if (fullname.trim() === "") {
      toast.warning("Please enter your Organization name", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (logo === null) {
      toast.warning("Please provide your Organization logo", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (addressVal === null) {
      toast.warning("Please provide your Organization logo", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (email.trim() === "") {
      toast.warning("Please enter your email address", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password.trim() === "") {
      toast.warning("Please enter a password", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (address.trim() === "") {
      toast.warning("Please enter your address", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (country.trim() === "") {
      toast.warning("Please select your country", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (description.trim() === "") {
      toast.warning("Please enter your organization description", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password !== c_password) {
      toast.warning("Passwords do not match", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password.length < 8) {
      toast.warning("Password should be at least 8 characters long", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (pin.length < 4) {
      toast.warning("Password should be at least 8 characters long", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    return true;
  };

  const toggleShowPin = () => {
    setShowPin(!showPin);
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcPassword = () => {
    setShowcPassword(!showcPassword);
  };

  const handlecheckbox = (e) => {
    setIschecked(!isChecked);
  };

  const handleLogoChange = (event) => {
    const uploadedFile = event.target.files[0];
    setLogo(uploadedFile);
  };

  const handleDropLogo = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setLogo(droppedFile);
  };

  const handleAddressChange = (event) => {
    const uploadedFile = event.target.files[0];
    setAddressVal(uploadedFile);
  };

  const handleDropAddress = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setAddressVal(droppedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      const formData = new FormData();
      const regobj = {
        name: fullname,
        email,
        address,
        country,
        password,
        description,
        proveAddressOption: addressValOption,
        pin,
        phone,
      };

      // Append each property of the object individually
      for (const key in regobj) {
        if (regobj.hasOwnProperty(key)) {
          formData.append(key, regobj[key]);
        }
      }

      // Append other fields
      formData.append("banner", logo);
      formData.append("proveAddressDoc", addressVal);

      console.log(formData);

      try {
        const response = await api.post("RegisterOrgs", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setIsLoading(false);
        const orgdata = response.data.organization;
        sessionStorage.setItem("orgID", orgdata.id);
        sessionStorage.setItem("orgEmail", orgdata.email);
        sessionStorage.setItem("orgStatus", orgdata.verified);
        sessionStorage.setItem("orgName", orgdata.name);
        sessionStorage.setItem("orgAmount", orgdata.amount);
        sessionStorage.setItem("orgLogo", orgdata.banner);
        // dispatch((dashboard))
        navigate("/org");
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.data) {
          // If the error response contains a message key, display it
          if (error.response.data.message) {
            toast.error(error.response.data.message, {
              theme: "colored",
              autoClose: 3000,
            });
            setIsLoading(false);
          }

          // If the error response contains errors key, display the first error
          if (error.response.data.errors) {
            const firstErrorKey = Object.keys(error.response.data.errors)[0];
            const firstError = error.response.data.errors[firstErrorKey][0];
            toast.error(firstError, {
              theme: "colored",
              autoClose: 3000,
            });
            setIsLoading(false);
          }
        } else {
          // Display a generic error message if the response doesn't contain expected keys
          toast.error("Signup failed, error: " + error.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <form action="" className="login-form mt-5 mb-3" onSubmit={handleSignup}>
      <div className="mb-4">
        <p>Company Logo</p>
        <div
          className="upload-container"
          onDrop={handleDropLogo}
          onDragOver={handleDragOver}
        >
          <label htmlFor="logoUpload" className="upload-panel">
            {logo ? (
              <p>{logo.name}</p>
            ) : (
              <>
                <div className="upload-icon">📤</div>
                <p>Click to upload or drag and drop</p>
                <p className="upload-details">
                  SVG, PNG, JPG, or JPEG (max. 800x400px)
                </p>
              </>
            )}
          </label>
          <input
            id="logoUpload"
            type="file"
            accept=".svg,.png,.jpg,.jpeg"
            onChange={handleLogoChange}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div className="mb-4 d-flex gap-3">
        <div className="w-50">
          <input
            type="text"
            className="login-input"
            placeholder="Full Name"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
          />
        </div>
        <div className="w-50">
          <input
            type="email"
            className="login-input"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-4 d-flex gap-3">
        <div className="w-50">
          <input
            type="text"
            className="login-input"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="w-50">
          <input
            type="number"
            className="login-input"
            placeholder="Phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-4">
        <select
          className="form-select text-grey ps-1 py-0"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          <option value="">Select a country</option>
          {countries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <select
          className="form-select text-grey ps-1 py-0 mb-2"
          value={addressValOption}
          onChange={(e) => setAddressOption(e.target.value)}
        >
          <option value="">Address Validation Type</option>
          <option value="Bank Statement">Bank Statement</option>
          <option value="CAC">CAC Certificate</option>
          <option value="Utility Bill">Utility Bill</option>
          <option value="Voters Card">Voters Card</option>
        </select>
        {/* <p>
          Address Validation (i.e Utility Bill, Bank Statements, Voter's Card
          e.t.c)
        </p> */}
      </div>
      <div
        className="upload-container mb-4"
        onDrop={handleDropAddress}
        onDragOver={handleDragOver}
      >
        <label htmlFor="addressUpload" className="upload-panel">
          {addressVal ? (
            <p>{addressVal.name}</p>
          ) : (
            <>
              <div className="upload-icon">📤</div>
              <p>Click to upload or drag and drop</p>
              <p className="upload-details">
                PDF, DOC, JPG, JPEG, PNG (max. 800x400px)
              </p>
            </>
          )}
        </label>
        <input
          id="addressUpload"
          type="file"
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
          onChange={handleAddressChange}
          style={{ display: "none" }}
        />
      </div>
      <div className="mb-4 d-flex ">
        <input
          type="text"
          className="login-input"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="mb-4 d-flex">
        <input
          type={showPin ? "text" : "password"}
          className="login-input"
          placeholder="Organization Pin"
          value={pin}
          maxLength={4}
          onChange={(e) => setPin(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showPin ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowPin}
        ></i>
      </div>
      <div className="mb-4 d-flex">
        <input
          type={showPassword ? "text" : "password"}
          className="login-input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowPassword}
        ></i>
      </div>
      <div className="mb-4 d-flex">
        <input
          type={showcPassword ? "text" : "password"}
          className="login-input"
          placeholder="Confirm Password"
          value={c_password}
          onChange={(e) => setCPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showcPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowcPassword}
        ></i>
      </div>

      <div className="text-center">
        <input type="checkbox" checked={isChecked} onChange={handlecheckbox} />
        <span>
          {" "}
          By signing up, I accept ETC Vibes'{" "}
          <a href={pdf} target="blank">
            Privacy Policy
          </a>
        </span>
      </div>
      <div className="mt-4">
        <button
          type="submit"
          className={`${isChecked ? "submit-btn" : "checked-btn"}`}
          disabled={isLoading || !isChecked}
        >
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          ) : (
            <span style={{ color: "white" }}>Create Account</span>
          )}
        </button>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </form>
  );
};

export default OrgForm;
