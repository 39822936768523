import OrgHeader from "../OrgHeader";
import LogoutModal from "../../../components/LogoutModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OrgSidebar from "../OrgSidebar";
import MobileOrgNav from "../../../components/MobileOrgNav";
import { useState } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import OrgCourseNote from "./courseMaterials/OrgCourseNote";
import OrgCourseVideos from "./courseMaterials/OrgCourseVideos";
import OrgCourseQuiz from "./courseMaterials/OrgCourseQuiz";
import OrgCoursePosts from "./courseMaterials/OrgCoursePosts";

const OrgCoursePage = () => {
  const [activeResource, setActiveResource] = useState("notes");
  const navigate = useNavigate();
  const { courseId } = useParams();
  const location = useLocation();
  const courseName = new URLSearchParams(location.search).get("title");

  const resourceComponents = {
    notes: <OrgCourseNote courseId={courseId} />,
    videos: <OrgCourseVideos courseId={courseId} />,
    quiz: <OrgCourseQuiz courseId={courseId} />,
    announcements: <OrgCoursePosts courseId={courseId} />,
  };

  const handleResourceClick = (resource) => {
    setActiveResource(resource);
  };
  return (
    <div className="container-fluid p-0">
      <OrgSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 orgDashboard">
        <OrgHeader />
        <div className="container-fluid">
          <div className="course-header ps-1">
            <button
              className="btn p-0 mb-1 text-grey lato-24-600"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
            <p className="lato-20-600">{courseName}</p>
            {/* Course Navbar */}
            <div className="scrolling-wrapper d-flex p-2">
              {Object.keys(resourceComponents).map((resourceKey) => (
                <div
                  className={`nav-item resource-button pe-3 ${
                    activeResource === resourceKey
                      ? "resource-active"
                      : "resource-inactive"
                  }`}
                  role="button"
                  key={resourceKey}
                  onClick={() => handleResourceClick(resourceKey)}
                >
                  {resourceKey.charAt(0).toUpperCase() + resourceKey.slice(1)}
                </div>
              ))}
            </div>
          </div>
          {/* Course resources */}
          <div className="course-resources py-3">
            {resourceComponents[activeResource]}
          </div>
        </div>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
      <MobileOrgNav />
    </div>
  );
};

export default OrgCoursePage;
