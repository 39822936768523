import { useEffect, useState } from "react";
import Flashcard from "./Flashcard";
import { Row } from "react-bootstrap";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const FlashCardList = ({ courseId }) => {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`Flashcard/${courseId}`);
        const resp = response.data;
        if (
          resp.message === "" ||
          resp.message === null ||
          resp.message === "Flash questions not found for this course."
        ) {
          setCards([]);
        } else {
          setCards(resp.quiz_questions);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId]);

  return (
    <div className="flashQuizzes">
      {loading ? (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-blue"
            role="status"
          ></span>
        </div>
      ) : cards.length === 0 ? (
        <div className="text-center">
          <p>No resource available</p>
        </div>
      ) : (
        <Row className="row-cols-1 row-cols-lg-4 row-cols-md-3">
          {cards.map((flashcard, index) => (
            <Flashcard
              key={index}
              flash={flashcard.flash}
              topic={flashcard.topic}
            />
          ))}
        </Row>
      )}
    </div>
  );
};

export default FlashCardList;
