import { useState } from "react";
import { Col, Card } from "react-bootstrap";
import flashCardSvg from "../../../../assets/flashCardSvg.svg";

function  Flashcard({ flash, topic }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  // function handleShowAnswer() {
  //   setShowAnswer(true);
  // }

  // function handleHideAnswer() {
  //   setShowAnswer(false);
  // }

  function handleNextQuestion() {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setShowAnswer(false);
  }

  const currentQuestion = flash[currentQuestionIndex];

  return (
    <Col>
      <Card
        className={`mb-3 ${showAnswer ? "flipped" : ""}`}
        onClick={() => setShowAnswer(!showAnswer)} // Toggle showAnswer state on card click
      >
        <div className="d-flex justify-content-center card-header">
          <img src={flashCardSvg} alt="" />
          <div className="ms-3 d-block">
            <h3 className="poppins-24-700">{topic}</h3>
          </div>
        </div>
        <div className="card-inner">
          <div className="card-front">
            <Card.Body>
              <Card.Title>{currentQuestion?.question}</Card.Title>
              {!showAnswer && (
                <button className="flashcard-btn">Show Answer</button>
              )}
            </Card.Body>
          </div>
          <div className="card-back">
            <Card.Body>
              <Card.Title>{currentQuestion?.answer}</Card.Title>
              {currentQuestionIndex < flash.length - 1 && (
                <button onClick={handleNextQuestion} className="flashcard-btn">
                  Next
                </button>
              )}
            </Card.Body>
          </div>
        </div>
      </Card>
    </Col>
  );
}

export default Flashcard;
