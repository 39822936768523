import funmi from "../../assets/Funmilayo.png";
import isaac from "../../assets/testimonial2.png";
const Testimonials = () => {
  return (
    <div className="testi">
      <div className="testimonials">
        <h2>Success Stories</h2>
      </div>
      <div className="testicards">
        <div className="cardd">
          <h4>
            I am glad i took the bold step to join ETC vibes in 2021, I had 5.0
            CGPA twice consecutively since Istarted using ETC Vibes.I enjoyed
            the self explanatory course materials and the Live tutorials It was
            a wonderful Experience!
          </h4>
          <div className="details">
            <img src={funmi} alt="" />
            <div className="text">
              <h5>Ogundokun Oluwafunmilayo</h5>
              <h6>(Nursing), Redeemer's University</h6>
            </div>
          </div>
        </div>
        <div className="cardd">
          <h4>
            Vibed in as the best graduating student in my department after using
            the ETC vibes solution. I had several 5.0 GPA
          </h4>
          <div className="details">
            <img src={isaac} alt="" />
            <div className="text">
              <h5>Oyekunle Isaac</h5>
              <h6>(Computer Science), Redeemer's University</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
