import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import LogoutModal from "../../../components/LogoutModal";
import MobileNav from "../../../components/MobileNav";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../../../api/axios";

const Videoresource = () => {
  const { video } = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const userID = sessionStorage.getItem("userID");
  const activeResourceID = sessionStorage.getItem("activeResourceID");
  const activeResourceType = sessionStorage.getItem("activeResourceType");
  useEffect(() => {
    const createView = async () => {
      const obj = {
        reviewable_type: activeResourceType,
        reviewable_id: parseInt(activeResourceID),
      };
      try {
        await axios.post(`CreateView/${userID}`, obj);
      } catch (error) {
        toast.error("Error creating view");
      }
    };
    createView();
  }, [userID, activeResourceID, activeResourceType]);
  return (
    <section className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3">
        <DashboardHeader />
        <div className="course-header px-2 pt-0 pb-3">
          <div className="d-flex">
            <p className="viga-24-400">Video Resource</p>
            <Link
              to=""
              className="viga-24-400 text-grey ms-auto"
              onClick={goBack}
            >
              <i className="bi bi-arrow-left"></i>
              Back
            </Link>
          </div>
        </div>
        <div className="card rounded p-2 border-0">
          <video controls src={video} alt="" />
        </div>
      </div>
      <MobileNav />
    </section>
  );
};

export default Videoresource;
