import React, { useState } from "react";
import quizImg from "../../../../assets/img/Quiz.jpg";
import QuizInfo from "../../StudyResources/QuizInfo";
import QuizModal from "../../StudyResources/QuizModal";

const Quiz = ({ feeds }) => {
  const filteredFeed = feeds.filter((item) => item.type === "quiz");
  const [showQuizInfo, setShowQuizInfo] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState([]);
  const [currentTopic, setcurrentTopic] = useState([]);
  const openQuizInfo = () => {
    setShowQuizInfo(true);
  };
  const closeQuizInfo = () => {
    setShowQuizInfo(false);
  };
  const openQuizModal = () => {
    closeQuizInfo();
    setShowQuizModal(true);
  };
  const closeQuizModal = () => {
    setShowQuizModal(false);
  };
  return (
    <div className="feedCards">
      {filteredFeed.length === 0 && "No resources available"}
      {filteredFeed.map((each) => (
        <div className="col" key={each.id}>
          <div
            className="card resource-card border-0"
            onClick={(e) => {
              e.preventDefault();
              openQuizInfo(each.quiz);
              setCurrentQuiz(each.quiz);
              setcurrentTopic(each.topic);
            }}
            role="button"
          >
            <img src={quizImg} className="card-img-top" alt="" />
            <div className="p-2 mt-4">
              <div className="d-flex">
                <h6 className="card-title">{each.topic}</h6>
                {/* <div className="ms-auto">
                  <i className="bi bi-eye pe-2"></i>
                </div> */}
              </div>
              <div className="footnote-grey">
                <h6 className="viga-14-400 m-0">
                  {each.tutor || "ETC Vibes"} . {each.type}
                </h6>
                <div className="viga-14-400 text-grey">
                  From: {each.university}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <QuizInfo
        show={showQuizInfo}
        handleClose={closeQuizInfo}
        openQuizModal={openQuizModal}
      />
      <QuizModal
        show={showQuizModal}
        handleClose={closeQuizModal}
        currentQuiz={currentQuiz}
        topic={currentTopic}
      />
    </div>
  );
};

export default Quiz;
