import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const OrgCreateCourseModal = ({ show, handleClose }) => {
  const orgID = sessionStorage.getItem("orgID");
  const [description, setDescription] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState("0");
  const [is_restricted, setis_restricted] = useState("0");
  const [banner, setBanner] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    let result = true;
    if (courseTitle === "" || courseTitle === null) {
      result = false;
      toast.warning("Input a course");
    }

    if (description === "" || description === null) {
      result = false;
      toast.warning("Write a description");
    }
    if (status === "" || status === null) {
      result = false;
      toast.warning("Pick a type");
    }
    if (is_restricted === "" || is_restricted === null) {
      result = false;
      toast.warning("Select a status type!");
    }
    if (banner === "" || banner === null) {
      result = false;
      toast.warning("Upload a thumbnail!");
    }
    if (price === "" && is_restricted === "1") {
      result = false;
      toast.warning("Please input price!");
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const formData = new FormData();

      const requestData = {
        title: courseTitle,
        description,
        is_restricted,
        is_visible: status,
        price,
      };
      for (const key in requestData) {
        if (requestData.hasOwnProperty(key)) {
          formData.append(key, requestData[key]);
        }
      }
      formData.append("banner", banner);
      try {
        const response = await axios.post(
          `organizations/${orgID}/courses`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const resp = response.data;
        if (resp.message === "Course created successfully") {
          toast.success("Course created successfully");
        } else {
          toast.warning(resp.message);
        }
        setIsLoading(false);
        handleClose();
      } catch (error) {
        toast.error("Error submitting request:", {
          theme: "colored",
          autoClose: "2000",
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <div className="request-tutorial">
          <h3 className="poppins-20-600 text-blue text-center">
            Input Course Details
          </h3>
          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-20-500">
              Course Title
            </label>
            <input
              type="text"
              className="login-input"
              value={courseTitle}
              placeholder="Machine learning"
              onChange={(e) => setCourseTitle(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-20-500">
              Description
            </label>
            <input
              type="text"
              className="login-input"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="A makeup tutorial"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="Course" className="poppins-20-500">
              Status
            </label>
            <select
              className="login-input"
              value={is_restricted}
              onChange={(e) => {
                setis_restricted(e.target.value);
                if (e.target.value === "0") {
                  setPrice("");
                }
              }}
            >
              <option value="">Select an option</option>
              <option value="0">Free</option>
              <option value="1">Paid</option>
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="" className="poppins-20-500">
              Type
            </label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="login-input"
            >
              <option value="">Select an option</option>
              <option value="0">Private</option>
              <option value="1">Public</option>
            </select>
          </div>

          {is_restricted === "1" && (
            <div className="mb-3">
              <label className="poppins-20-500"> Price</label>
              <input
                type="number"
                value={price}
                placeholder="₦2000"
                className="login-input"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          )}
          <div className="mb-3">
            <label className="poppins-20-500"> Course Thumnail</label>
            <input
              type="file"
              accept=".jpeg, .png, .jpg"
              className="form-control"
              onChange={(e) => setBanner(e.target.files[0])}
            />
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="blue-filled-btn"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <>Create</>
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OrgCreateCourseModal;
