import { useNavigate } from "react-router-dom";
// import Category from "../../../assets/img/Category.svg";
import Arrow from "../../../assets/img/ArrowRight.svg";
// import With from "../../../assets/img/Withdraw.svg";
import Wallet from "../../../assets/img/Wallet.svg";
import AddMoneyButton from "./AddMoneyButton";
const WalletOperations = ({ userPlan, reloadWalletData, subDays }) => {
  const navigate = useNavigate();

  return (
    <div className="container p-0 mb-5 wallet-operations">
      <div className="d-flex">
        <h2 className="viga-24-400">Operations</h2>
        <h2 className="viga-24-400 ms-auto text-blue text-capitalize">
          {userPlan} Plan ({subDays} {subDays > 1 ? "days" : "day"})
        </h2>
      </div>
      <div className="d-flex scrolling-wrapper">
        <div className="me-4">
          <AddMoneyButton reloadWalletData={reloadWalletData} />
          <span className="lato-24-400">Add Money</span>
        </div>
        <div className="me-4">
          <div
            className="wallet-op wallet-op-2"
            onClick={(e) => {
              navigate("/userWallet/walletsub");
            }}
          >
            <img src={Wallet} alt="" className="m-auto" />
          </div>
          <span className="lato-24-400">Subscription</span>
        </div>
        {/* <SubscriptionPlan /> */}
        <div className="me-4">
          <div
            className="wallet-op wallet-op-4"
            onClick={(e) => {
              navigate("/userWallet/coinwallet");
            }}
          >
            <img src={Arrow} alt="" className="m-auto" />
          </div>
          <span className="lato-24-400">Withdraw</span>
        </div>
        {/* <div className="me-5">
          <div className="wallet-op wallet-op-4">
            <img src={Category} alt="" className="m-auto" />
          </div>
          <span className="lato-24-400">More</span>
        </div> */}
      </div>
    </div>
  );
};

export default WalletOperations;
