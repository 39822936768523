import OrgSidebar from "../OrgSidebar";
import LogoutModal from "../../../components/LogoutModal";
import OrgHeader from "../OrgHeader";
import MobileOrgNav from "../../../components/MobileOrgNav";
import OrgCourseContainer from "./OrgCourseContainer";

const OrgCourses = () => {
  return (
    <div>
      <section className="container-fluid p-0">
        <OrgSidebar />
        <LogoutModal />
        <div className="main-content p-1 p-md-3 orgDashboard">
          <OrgHeader />
          <OrgCourseContainer />
        </div>
        <MobileOrgNav />
      </section>
    </div>
  );
};

export default OrgCourses;