import React, { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast, ToastContainer, Zoom } from "react-toastify";
import OrgCreatePost from "./OrgCreatePost";
import { format } from "date-fns";
import DeeletePostModal from "./DeletePostModal";

const OrgCoursePost = ({ courseId }) => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const orgId = sessionStorage.getItem("orgID");
  const [courseModal, setCourseModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedPostID, setSelectedPostID] = useState(null);

  const openModal = () => setCourseModal(true);
  const closeModal = () => setCourseModal(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `Org/viewAnnouncement/${orgId}/${courseId}`
      );
      const resp = response.data;

      if (
        resp.message === "" ||
        resp.message === "No record Found" ||
        resp.message === "no record found" ||
        resp.message === null
      ) {
        setPosts([]);
      } else {
        setPosts(resp.announcements);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [courseId, orgId]);

  const openDeleteConfirmation = (courseId) => {
    setSelectedPostID(courseId);
    setShowDeleteConfirmation(true);
  };
  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setSelectedPostID(null);
  };
  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `Org/viewAnnouncement/${orgId}/${selectedPostID}/delete`
      );
      closeDeleteConfirmation();
      toast.success("Announcement Deleted Successfully", {
        theme: "colored",
        autoClose: "2000",
      });
      fetchData();
    } catch (error) {
      toast.error("Failed to delete announcement", {
        theme: "colored",
        autoClose: 3000,
      });
    }
  };
  return (
    <div>
      <header className="d-flex">
        <button className="blue-filled-btn ms-auto" onClick={openModal}>
          Add New Post
        </button>
      </header>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 mt-2">
        {loading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm text-blue"
              role="status"
            ></span>
          </div>
        ) : posts.length === 0 ? (
          <div className="text-center">
            <p>No announcement available</p>
          </div>
        ) : (
          posts.map(
            (post) =>
              post !== null && (
                <div className="col" key={post.id}>
                  <div className="card resource-card border-0 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="card-title">{post.title}</h6>
                        <h6 className="card-title">
                          {format(
                            new Date(post.created_at),
                            "MMM dd, yyyy hh:mm a"
                          )}
                        </h6>
                      </div>
                      <p>{post.message}</p>
                      <div className="d-flex justify-content-end">
                        <i
                          className="bi bi-trash3 text-danger pe-auto"
                          role="button"
                          onClick={() => openDeleteConfirmation(post.id)}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )
        )}
      </div>

      <ToastContainer transition={Zoom}></ToastContainer>
      <OrgCreatePost
        show={courseModal}
        handleClose={closeModal}
        courseId={courseId}
      />
      <DeeletePostModal
        show={showDeleteConfirmation}
        handleClose={closeDeleteConfirmation}
        handleConfirmDelete={handleConfirmDelete}
      />
    </div>
  );
};

export default OrgCoursePost;