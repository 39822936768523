import { useEffect } from "react";
import blueLogo from "../../../assets/img/blueLogo.svg";
import iconSuccess from "../../../assets/img/successIcon.svg";
import { Link } from "react-router-dom";
import lottie from "lottie-web";
import { useRef } from "react";
import { Modal, ModalBody } from "react-bootstrap";
const UserSuccess = ({ show, handleClose }) => {
  const container = useRef(null);
  const animationLoaded = useRef(false); // Use a ref to track if the animation has already been loaded

  useEffect(() => {
    if (!animationLoaded.current) {
      // Check if animation has not been loaded yet
      lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("../../../assets/confetti.json"),
      });
      animationLoaded.current = true;
    }
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody className="success-modal">
        <div className="row">
          <div className="col-md-5 usersuccess-left">
            <div className="px-4 mt-auto d-flex justify-content-center"></div>
          </div>
          <div className="col-sm-7 p-3">
            <div
              className="container congratulations text-center"
              style={{ width: "fit-content" }}
            >
              <div className="logo-center text-center">
                <img src={blueLogo} alt="Logo" className="logo" />
              </div>
              <h1 className="viga-32-400 text-blue">
                Congratulations !
                <img src={iconSuccess} alt="" className="blue-check" />
              </h1>
              <div className="container">
                <h5 className="font-20-600 text-blue" ref={container}>
                  Your account is now active.
                </h5>
              </div>

              <div className="toDashboard my-2">
                <Link to="/userdashboard">
                  <button
                    className="blue-filled-btn mt-4"
                    data-bs-dismiss="modal"
                  >
                    Start Learning
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UserSuccess;
