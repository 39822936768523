import React from "react";
import Logo from "../assets/blueLogo.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        flexDirection: "column",
      }}
    >
      <img src={Logo} alt="" />
      <h1>4 0 4 - Page Not Found</h1>
      <p>
        Sorry, the page you requested cannot be found, Please navigate back to
        the <Link to="/">Home Page</Link>
      </p>
    </div>
  );
};

export default NotFound;
