import { useEffect, useState } from "react";
import { toast, ToastContainer, Zoom } from "react-toastify";
import axios from "../../../api/axios";

const AdminPostContainer = () => {
  return (
    <section className="px-1 px-md-2">
      <div>
        <AdminScholarship />
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default AdminPostContainer;

const AdminScholarship = () => {
  const [scholarship, setScholarship] = useState([]);
  const [dataloader, setDataloader] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [eligibility, setEligibility] = useState("");
  const [deadline, setDeadline] = useState("");
  const [apply_url, setApplyUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteloading, setDeleteLoading] = useState(false);

  const publish = async () => {
    setLoading(true);
    const obj = {
      title,
      description,
      deadline,
      eligibility,
      apply_url,
    };
    try {
      const response = await axios.post("scholarships", obj);
      const resp = response.data;

      if (resp.message === "Scholarship Created Successfully") {
        toast.success(resp.message, {
          theme: "colored",
        });
        const updatedPosts = await axios.get("scholarships");
        setScholarship(updatedPosts.data);
        // Clear the input fields
        setTitle("");
        setDescription("");
        setEligibility("");
        setDeadline("");
        setApplyUrl("");
      } else {
        toast.error(resp.message || "Failed to publish announcement", {
          theme: "colored",
        });
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while publishing",
        {
          theme: "colored",
        }
      );
      setLoading(false);
    }
  };

  const deletePost = async (id) => {
    setDeleteLoading(true);
    try {
      const response = await axios.delete(`scholarships/${id}`);
      if (response.data.message === "Scholarship deleted successfully") {
        toast.success(response.data.message, {
          theme: "colored",
        });
        const updatedPosts = await axios.get("scholarships");
        setScholarship(updatedPosts.data);
      } else {
        toast.error("Failed to delete post", {
          theme: "colored",
        });
      }
      setDeleteLoading(false);
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    const getAllPosts = async () => {
      try {
        const response = await axios.get("scholarships");
        setScholarship(response.data);
        setDataloader(false);
      } catch (error) {
        toast.error(error.message, {
          theme: "colored",
        });
      }
    };
    getAllPosts();
  }, []);

  return (
    <main className="px-2 py-3">
      <div className="d-flex">
        <div className="new-post-container">
          <div className="d-flex border-bottom px-2">
            <span className="viga-20-400">Scholarship Offer</span>
          </div>
          <div className="d-flex border-bottom px-2">
            <input
              type="text"
              placeholder="Title"
              className="new-post-input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="d-flex border-bottom align-items-center px-2">
            <textarea
              placeholder="Description"
              className="new-post-input w-100"
              rows={3}
              cols={50}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="d-flex border-bottom px-2">
            <input
              type="text"
              placeholder="Eligibility"
              className="new-post-input"
              value={eligibility}
              onChange={(e) => setEligibility(e.target.value)}
            />
          </div>
          <div className="d-flex border-bottom px-2">
            <input
              type="text"
              placeholder="Application Link"
              className="new-post-input"
              value={apply_url}
              onChange={(e) => setApplyUrl(e.target.value)}
            />
          </div>
          <div className="d-flex border-bottom px-2 new-post-input">
            Deadline:
            <input
              type="date"
              className="new-post-input ps-2"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />
          </div>
        </div>
        <div className="p-2 d-flex">
          <div className="mt-auto">
            <br />
            <button
              className="blue-filled-btn"
              onClick={publish}
              disabled={loading}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                ></span>
              ) : (
                <>Publish</>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="admin-posts-highlights">
        <div className="posts-highlight-container">
          {dataloader ? (
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            scholarship &&
            scholarship.map((post, index) => (
              <div className="posts-highlight-item my-3" key={index}>
                <div className="d-flex align-items-center">
                  <p className="text-blue viga-20-400">{post.title}</p>
                  <p className="text-secondary ms-4">
                    <i className="bi bi-calendar-check pe-2"></i>
                    {post.created_at}
                  </p>
                </div>
                <p>{post.description}</p>
                <h6>Eligibilty:</h6>
                <p>{post.eligibility}</p>
                <h6>Deadline:</h6>
                <p>{post.deadline}</p>
                <button
                  className="blue-filled-btn"
                  disabled={deleteloading}
                  onClick={() => deletePost(post.id)}
                >
                  {deleteloading ? "Loading" : "Delete"}
                </button>
              </div>
            ))
          )}
        </div>
      </div>
    </main>
  );
};
