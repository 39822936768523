import { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import LogoutModal from "../../../components/LogoutModal";
import MobileNav from "../../../components/MobileNav";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const UserScholarships = () => {
  const [loading, setLoading] = useState(false);
  const [scholarships, setScholarships] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get("scholarships");
        const resp = response.data;

        if (!resp || resp.message === "Record was not found") {
          setScholarships([]);
        } else {
          setScholarships(resp);
        }
        setLoading(false);
      } catch (error) {
        toast.error("Error fetching scholarships.");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content px-2 px-md-4">
        <DashboardHeader />
        <section className="px-4" style={{ marginBottom: "100px" }}>
          <h4 className="viga-24-400">Scholarships</h4>
          <div className="">
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
              ></span>
            ) : (
              scholarships.map((each) => (
                <div className="container scholarship-container my-3" key={each.id}>
                  <Link
                    to={`/awards/${each.id}`} // Navigate to details page
                    className="scholarship-item p-3"
                    style={{ textDecoration: "none", color: "inherit" }} // Remove underline
                  >
                    <h5 className="scholarship-title poppins-14-700">
                      {each.title}
                    </h5>
                    <div className="scholarship-deadline">
                      Deadline: {each.deadline}
                    </div>
                  </Link>
                </div>
              ))
            )}
          </div>
        </section>
      </div>
      <MobileNav />
    </div>
  );
};

export default UserScholarships;
