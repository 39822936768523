import React, { useEffect, useState } from "react";
import LogoutModal from "../../../components/LogoutModal";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import MobileNav from "../../../components/MobileNav";
import axios from "../../../api/axios";
import { JellyTriangle } from "@uiball/loaders";
import MyUpdates from "./MyUpdates";
import NotifyAnnouncements from "./NotifyAnnouncements";
import { useNavigate } from "react-router-dom";

const UserNotification = () => {
  const [updateBtn, setUpdateBtn] = useState("notification-active");
  const [announceBtn, setAnnounceBtn] = useState("notification-inactive");
  const userId = sessionStorage.getItem("userID");
  const [notifications, setNotifications] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(10);
  const [currentAlert, setCurrentAlert] = useState("updates");
  const [allPosts, setAllPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchNoty = async () => {
      const response = await axios(`ViewUsersNotification/${userId}`);
      const notification = response.data.data.message;
      const responseTwo = await axios.get("fetchPublishedAnnouncement");
      setAllPosts(responseTwo.data);
      setNotifications(notification);
      setLoading(false);
    };
    fetchNoty();
  }, [userId]);

  const handleMyupdates = () => {
    setUpdateBtn("notification-active");
    setAnnounceBtn("notification-inactive");
    setCurrentPage(1); // Reset to the first page when switching to recent notifications.
    setCurrentAlert("updates");
  };

  const handleAnnouncements = () => {
    setUpdateBtn("notification-inactive");
    setAnnounceBtn("notification-active");
    setCurrentAlert("announce");
  };

  // Function to get the current notifications to display based on the current page and notifications per page.
  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content  px-2 px-md-4">
        <DashboardHeader />
        <section className="container-fluid px-3 px-md-4">
          <button
            className=" d-block d-lg-none btn p-0 mb-1 text-grey lato-24-600"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="bi bi-chevron-left"></i>
          </button>
          <div className="d-flex">
            <div className="notification-header d-flex m-auto">
              <button className={updateBtn} onClick={handleMyupdates}>
                My Updates
              </button>
              <button className={announceBtn} onClick={handleAnnouncements}>
                Announcement
              </button>
            </div>
          </div>

          <div className="notification-container">
            {loading ? (
              <div className="container-fluid d-flex justify-content-center align-items-center py-5">
                <div className="container d-flex justify-content-center">
                  <JellyTriangle size={80} speed={1.75} color="#044aac" />
                </div>
              </div>
            ) : currentAlert === "updates" ? (
              <MyUpdates notifications={notifications} />
            ) : (
              <NotifyAnnouncements notifications={allPosts} />
            )}
          </div>
          {/* Pagination */}
          {/* {announceBtn === "notification-active" && (
            <ul className="pagination">
              {Array.from(
                {
                  length: Math.ceil(
                    notifications.length / notificationsPerPage
                  ),
                },
                (_, i) => (
                  <li
                    key={i}
                    className={`page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      onClick={() => paginate(i + 1)}
                      className="page-link"
                    >
                      {i + 1}
                    </button>
                  </li>
                )
              )}
            </ul>
          )} */}

          <MobileNav />
        </section>
      </div>
    </div>
  );
};

export default UserNotification;
