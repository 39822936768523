import { useEffect, useState } from "react";
import OrgCreateCourseModal from "./OrgCreateCourseModal";
import axios from "../../../api/axios";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { Link } from "react-router-dom";
import { JellyTriangle } from "@uiball/loaders";
import courseIcon from "../../../assets/img/courses.jpg";

const OrgCourseContainer = () => {
  const [courseModal, setCourseModal] = useState(false);
  const orgId = sessionStorage.getItem("orgID");
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const openModal = () => setCourseModal(true);
  const closeModal = () => setCourseModal(false);

  useEffect(() => {
    setLoading(true);
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`OrgViewCoursesCreated/${orgId}`);
        const data = response.data.courses;
        setCourses(data);
        setLoading(false);
      } catch (error) {
        toast.error("failed to fetch data", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    };
    fetchUserData();
  }, [orgId]);

  return (
    <section>
      <header className="d-flex">
        <h4 className="viga-24-400">Courses</h4>
        <button className="ms-auto blue-filled-btn" onClick={openModal}>
          + Create Course
        </button>
      </header>
      <section>
        <>
          {loading ? (
            <div className="container d-flex justify-content-center pt-5">
              <JellyTriangle size={80} speed={1.75} color="#044aac" />
              <ToastContainer transition={Zoom}></ToastContainer>
            </div>
          ) : (
            <section className="container-fluid p-3 p-md-3 mb-5 mb-md-2 mb-lg-0">
              {courses.length === 0 && (
                <div className="container text-center mt-5">
                  <p>You have not added any course</p>
                </div>
              )}
              {courses.length > 0 && (
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2 ">
                  {courses.map((course) => (
                    <div className="col" key={course.id}>
                      <div className="my-course-card mb-4 mb-md-5">
                        <div className="my-courses-img">
                          <img
                            src={course.banner || courseIcon}
                            className="my-course-img"
                            alt=""
                          />
                        </div>
                        <div className="my-course-body p-3 w-100">
                          <div className="d-block">
                            <h4 className="roboto-14-700">{course.title}</h4>
                            <span className="roboto-12-400">
                              {course.description}
                            </span>
                            <h6 className="lato-12-700">
                              {course.price === null ||
                              course.price === undefined
                                ? "Free"
                                : `₦${course.price}`}
                            </h6>
                          </div>
                          <div className="d-flex mt-4">
                            <Link
                              to={`/orgcourses/coursepage/${
                                course.id
                              }?title=${encodeURIComponent(course.title)}`}
                              className="ms-auto roboto-10-400 text-decoration-none"
                            >
                              View
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </section>
          )}
        </>
      </section>
      <OrgCreateCourseModal show={courseModal} handleClose={closeModal} />
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default OrgCourseContainer;
