import React, { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import AllFeeds from "./AllFeeds";
import PQ from "./PQ";
import Videos from "./Videos";
import Notes from "./Notes";
import Quiz from "./Quiz";
import Textbook from "./Textbook";
import Flashcards from "./Flashcards";
import { JellyTriangle } from "@uiball/loaders";

const TAB_CONFIG = [
  { id: "all", label: "All", Component: AllFeeds },
  { id: "pq", label: "PQ & Solutions", Component: PQ },
  { id: "videos", label: "Videos", Component: Videos },
  { id: "notes", label: "Notes", Component: Notes },
  { id: "quiz", label: "Quiz", Component: Quiz },
  { id: "textbook", label: "Textbook", Component: Textbook },
  { id: "flashcards", label: "Flashcards", Component: Flashcards },
];

const Feeds = () => {
  const userId = sessionStorage.getItem("userID");
  const [currentTab, setCurrentTab] = useState("all");
  const [loading, setLoading] = useState(true);
  const [allfeeds, setAllfeeds] = useState([]);
  console.log(allfeeds);

  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const response = await axios.get(`feeds/user/${userId}`);
        const feeds = response.data.resources;
        setAllfeeds(feeds);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setAllfeeds([]);
      }
    };
    fetchFeeds();
  }, [userId]);

  const getCurrentComponent = () => {
    const tab = TAB_CONFIG.find((tab) => tab.id === currentTab);
    return <tab.Component feeds={allfeeds} />;
  };
  console.log(allfeeds);
  return (
    <div className="container-fluid px-2 px-md-4">
      <div className="d-flex align-items-center feeds-topnav">
        {TAB_CONFIG.map((tab) => (
          <button
            key={tab.id}
            className={`${
              currentTab === tab.id
                ? "course-btn-active"
                : "course-btn-inactive"
            } text-nowrap`}
            onClick={() => {
              setCurrentTab(tab.id);
            }}
          >
            <h3 className="lato-18-700 m-1">{tab.label}</h3>
          </button>
        ))}
      </div>
      <div className="my-3">
        {loading ? (
          <div className="container d-flex justify-content-center pt-5">
            <JellyTriangle size={80} speed={1.75} color="#044aac" />
          </div>
        ) : (
          getCurrentComponent()
        )}
      </div>
    </div>
  );
};

export default Feeds;
