import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import LogoutModal from "../../../components/LogoutModal";
import MobileNav from "../../../components/MobileNav";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const LmsCourse = () => {
  const [searchParams] = useSearchParams();
  const OrgName = searchParams.get("name");
  const { id } = useParams();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCoursesDetails = async () => {
      try {
        const response = await axios.post(`Orgcourses/${id}/access`);
        setCourses(response.data);
      } catch (error) {
        toast.error("Error fetching Courses details.", {
          theme: "colored",
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchCoursesDetails();
  }, [id]);

  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content px-2 px-md-4">
        <DashboardHeader />
        <section className="px-2 px-md-3">
          <h4 className="viga-24-400">{OrgName}</h4>
        </section>
      </div>
      <MobileNav />
    </div>
  );
};

export default LmsCourse;
