import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout as logoutAction } from "../features/userSlice";

const UnverifiedOrg = ({ show }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutOrg = (e) => {
    e.preventDefault();
    dispatch(logoutAction());
    navigate("/login");
  };
  return (
    <Modal
      show={show}
      // onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className="container">
          <h2 className="viga-24-400 text-blue text-center mb-3">
            🔔 Important Notice 🔔
          </h2>
          <p className="text-danger poppins-15-400">Unverified Organization</p>
          <p className="poppins-15-400">
            Your organization is currently under review. Kindly contact
            info@etcvibes.com if verification is taking more than 24 hours!
          </p>
        </div>
        <div onClick={logoutOrg} role="button" className="btn btn-danger mx-2">
          Logout
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UnverifiedOrg;
