import React from "react";
import pqImg from "../../../../assets/img/Pastquestions solution.jpg";
import { Link } from "react-router-dom";

const PQ = ({ feeds }) => {
  const filteredFeed = feeds.filter((item) => item.type === "pastquestion");
  return (
    <div className="feedCards">
      {filteredFeed.length === 0 && "No resources available"}
      {filteredFeed.map((each) => (
        <div className="col " key={each.id}>
          <div className="card resource-card border-0 h-100">
            <Link
              to="/courses/coursepage/info"
              state={{
                pdfUrl: each.url,
                id: each.id,
                name: each.Name,
                description: each.Description,
                course: each.course,
                author: each.author,
                views: each.views,
                rating: each.rating,
                institution: each.institution,
                resourceType: each.type,
                courseId: each.course_id,
              }}
              className="text-decoration-none text-dark"
              onClick={() => {
                sessionStorage.setItem("activeResourceID", each.id);
                sessionStorage.setItem("activeResourceType", "pastquestion");
              }}
            >
              <img src={pqImg} className="card-img-top" alt={each.type} />
            </Link>
            <div className="p-2 mt-4">
              <div className="d-flex">
                <h6 className="card-title">{each.description}</h6>
                {/* <div className="ms-auto">
                  <i className="bi bi-eye pe-2"></i>
                </div> */}
              </div>
              <div className="footnote-grey">
                <h6 className="viga-14-400 m-0">
                  {each.tutor || "ETC Vibes"} . {each.type}
                </h6>
                <div className="viga-14-400 text-grey">
                  From: {each.university}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PQ;
