import whiteLogo from "../../assets/whiteLogo.png";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "../../assets/menu-04.png";
import Cancel from "../../assets/x-close.png";
import { useState } from "react";
import { toast, ToastContainer, Zoom } from "react-toastify";

const BecomeNavbar = () => {
  const [mobileView, setMobileView] = useState(false);
  return (
    <>
      <nav className="navbarSec">
        <img src={whiteLogo} alt="Logo" className="navlogo" style={{}} />
        <div className="navbar-mobile">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              toast.warning("Login to explore your course of interest!", {
                theme: "colored",
                autoClose: 3000,
              });
            }}
            className="searchbar"
          >
            <i style={{ color: "black" }} class="bi bi-search"></i>
            <input
              type="text"
              placeholder="What do you want to study today?"
              className="searchInput"
            />
          </form>
          <ul className="navigation-links">
            <li className="px-2 my-auto">
              <NavLink
                className={({ isActive }) => (isActive ? "activeNav" : "")}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="px-2 my-auto">
              <NavLink
                className={({ isActive }) => (isActive ? "activeNav" : "")}
                to="/login"
              >
                Study
              </NavLink>
            </li>
            <li className="px-2 my-auto">
              <NavLink
                className={({ isActive }) => (isActive ? "activeNav" : "")}
                to="/createAccount/tutor"
              >
                Become a Tutor
              </NavLink>
            </li>
            <li className="px-2 my-auto">
              <NavLink
                className={({ isActive }) => (isActive ? "activeNav" : "")}
                to="/createAccount/org"
              >
                LMS
              </NavLink>
            </li>
          </ul>
          <div className="navbar-buttons">
            <div
              className="upload"
              onClick={() =>
                toast.warning("Login to upload and earn!", {
                  theme: "colored",
                  autoClose: 3000,
                })
              }
              role="button"
            >
              <i class="bi bi-upload"></i>
              <p className="px-2 my-auto">Upload & Earn</p>
            </div>
            <Link to="/login">
              <button className="login">Login</button>
            </Link>
            <Link to="/signup">
              <button className="signup">Sign Up</button>
            </Link>
          </div>
        </div>
        <img
          onClick={() => setMobileView(true)}
          role="button"
          src={MenuIcon}
          className="mobile-icon"
          alt=""
        />
      </nav>
      <nav className={`mobileView ${mobileView && "open"}`}>
        <div
          onClick={() => setMobileView(false)}
          role="button"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "30px",
          }}
        >
          <img src={Cancel} alt="" />
        </div>
        <ul className="navigation-links">
          <li className="px-2 my-auto">
            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "")}
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="px-2 my-auto">
            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "")}
              to="/login"
            >
              Study
            </NavLink>
          </li>
          <li className="px-2 my-auto">
            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "")}
              to="/createAccount/tutor"
            >
              Become a Tutor
            </NavLink>
          </li>
          <li className="px-2 my-auto">
            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "")}
              to="/org"
            >
              LMS
            </NavLink>
          </li>
          <li className="px-2 my-auto">
            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "")}
              to="/login"
            >
              <div className="upload">
                <i class="bi bi-upload"></i>
                <p className="px-2 my-auto">Upload & Earn</p>
              </div>
            </NavLink>
          </li>
          <li className="px-2 my-auto">
            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "")}
              to="/login"
            >
              Login
            </NavLink>
          </li>
          <li className="px-2 my-auto mobileSignup">
            <NavLink
              className={({ isActive }) => (isActive ? "activeNav" : "")}
              to="/signup"
            >
              Sign Up
            </NavLink>
          </li>
        </ul>
      </nav>
      <ToastContainer transition={Zoom} />
    </>
  );
};

export default BecomeNavbar;
