import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../api/axios";
const LoginOrg = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      const logobj = { email, password };

      axios
        .post("LoginOrgs", logobj, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsLoading(false);
          let resp = response.data.organization;
          let data2 = response.data;
          sessionStorage.setItem("orgID", resp.id);
          sessionStorage.setItem("orgEmail", resp.email);
          sessionStorage.setItem("orgName", resp.name);
          sessionStorage.setItem("orgStatus", resp.verified);
          sessionStorage.setItem("orgLogo", resp.banner);
          sessionStorage.setItem("orgTotalCourses", data2.totalcourses);
          sessionStorage.setItem("orgTotalStudents", data2.totalStudents);
          sessionStorage.setItem("orgTotalCredits", data2.totalCredits);
          sessionStorage.setItem("orgggg", password);

          navigate("/org");
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.message) {
              toast.error(error.response.data.message, {
                theme: "colored",
                autoClose: 3000,
              });
              setIsLoading(false);
            }

            // If the error response contains errors key, display the first error
            if (error.response.data.errors) {
              const firstErrorKey = Object.keys(error.response.data.errors)[0];
              const firstError = error.response.data.errors[firstErrorKey][0];
              toast.error(firstError, {
                theme: "colored",
                autoClose: 3000,
              });
              setIsLoading(false);
            }
          } else {
            // Display a generic error message if the response doesn't contain expected keys
            toast.error("Login failed, error: " + error.message, {
              theme: "colored",
              autoClose: 3000,
            });
            setIsLoading(false);
          }
        });
    }
  };
  const validate = () => {
    let result = true;
    if (email === "" || email === null) {
      result = false;
      toast.warning("Please Enter Username", {
        theme: "colored",
        autoClose: 3000,
      });
    }
    if (password === "" || password === null) {
      result = false;
      toast.warning("Please Enter Password", {
        theme: "colored",
        autoClose: 3000,
      });
    }
    return result;
  };
  return (
    <form className="login-form text-center my-5" onSubmit={handleLogin}>
      <div className="mb-4 d-flex">
        <label htmlFor="email" className="login-label">
          Email Address
        </label>
        <input
          type="email"
          placeholder="name@email.com"
          className="login-input"
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex">
        <label htmlFor="password" className="login-label">
          Password
        </label>
        <input
          type={showPassword ? "text" : "password"}
          placeholder="*******"
          className="login-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowPassword}
        ></i>
      </div>
      <div className="mb-3 d-flex justify-content-center login-additions">
        <input type="checkbox" name="" id="" />
        <span htmlFor="" className="ps-1 login-reminder">
          Remember me
        </span>
        <span className="ms-auto">
          <Link to="/resetPassword">Forget Password?</Link>
        </span>
      </div>
      <div className="mt-4">
        <button type="submit" className="submit-btn">
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          ) : (
            <span>Log In</span>
          )}
        </button>
      </div>
      <span className="font-12">
        Need help? Contact support at{" "}
        <a href="mailto:contact@etcvibes.com">contact@etcvibes.com</a>
      </span>
    </form>
  );
};

export default LoginOrg;
