import { Link } from "react-router-dom";
import DashboardSidebar from "../pages/UserDashboard/DashboardSidebar";
import LogoutModal from "./LogoutModal";
import DashboardHeader from "../pages/UserDashboard/DashboardHeader";
import MobileNav from "./MobileNav";

const ProfileInfoPage = () => {
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-1 ">
        <DashboardHeader />
        <section className="container-fluid px-4">
          <div className="my-4">
            <Link to="/userprofileinfo/profile" className="profile-info-link">
              <div className="d-flex poppins-20-500 py-3">
                <i className="bi bi-person-circle pe-3"></i>
                <span>Profile Details</span>
                <i className="bi bi-chevron-right ms-auto"></i>
              </div>
            </Link>
            <Link to="/userwallet" className="profile-info-link">
              <div className="d-flex poppins-20-500 py-3">
                <i className="bi bi-wallet pe-3"></i>
                <span>Wallet</span>
                <i className="bi bi-chevron-right ms-auto"></i>
              </div>
            </Link>
            <Link to="/etcbot" className="profile-info-link">
              <div className="d-flex poppins-20-500 py-3">
                <i className="bi bi-robot pe-3"></i>
                <span>Chat ETC</span>
                <i className="bi bi-chevron-right ms-auto"></i>
              </div>
            </Link>
            {/* <Link to="/notifications" className="profile-info-link">
              <div className="d-flex poppins-20-500 py-3">
                <i className="bi bi-bell pe-3 fw-bold"></i>
                <span>Notifications</span>
                <i className="bi bi-chevron-right ms-auto"></i>
              </div>
            </Link> */}
            <Link to="/profile" className="profile-info-link">
              <div className="d-flex poppins-20-500 py-3">
                <i className="bi bi-send pe-3 fw-bold"></i>
                <span>Refer a friend</span>
                <i className="bi bi-chevron-right ms-auto"></i>
              </div>
            </Link>
            <Link to="/help" className="profile-info-link">
              <div className="d-flex poppins-20-500 py-3">
                <i className="bi bi-question-circle pe-3 fw-bold"></i>
                <span>Help</span>
                <i className="bi bi-chevron-right ms-auto"></i>
              </div>
            </Link>
          </div>
          
          <div className="d-flex ">
            <button
              className="btn btn-danger m-auto"
              data-bs-toggle="modal"
              data-bs-target="#logoutModal"
            >
              <i className="bi bi-box-arrow-right"></i> Logout
            </button>
          </div>
          <MobileNav />
        </section>
      </div>
    </div>
  );
};

export default ProfileInfoPage;
