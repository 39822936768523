import React from "react";
import "./home.css";
import PersonalizedExp from "./PersonalizedExp";
import Materials from "./Materials";
import Tutorials from "./Tutorials";
import Partners from "./Partners";
import Footer from "./Footer";
import Aboutus from "./Aboutus";
import Testimonials from "./Testimonials";
import Faq from "./FAQ";
import Plans from "./Plans";
import Community from "./Community";
import { JellyTriangle } from "@uiball/loaders";
import { useState, useEffect } from "react";
import bot from "../../assets/FrameBot.png";
import AIModal from "./AIModal";
import Navbar from "./Navbar";
import Hero from "./Hero";
import axios from "../../api/axios";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [aishow, setaiShow] = useState(false);

  const [landing, setLanding] = useState([]);
  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const response = await axios.get(`landing`);
        const feeds = response.data.materials;
        setLanding(feeds);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setLanding([]);
      }
    };
    fetchFeeds();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="container-fluid d-flex justify-content-center align-items-center preloader-container">
          <div className="container d-flex justify-content-center">
            <JellyTriangle size={80} speed={1.75} color="#044aac" />
          </div>
        </div>
      ) : (
        <div>
          <div onClick={() => setaiShow(true)} className="bot">
            <img src={bot} alt="ai vibes" />
          </div>
          <AIModal show={aishow} onHide={() => setaiShow(false)} />
          <Navbar />
          <Hero landing={landing} />
          <PersonalizedExp />
          <Materials />
          <Tutorials />
          <Community />
          <Testimonials />
          <Plans />
          <Aboutus />
          <Faq />
          <Partners />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Home;
