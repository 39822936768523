import { useState } from "react";
import axios from "../../../../api/axios";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";

const OrgCreateVideo = ({ show, handleClose, courseId }) => {
  const orgID = sessionStorage.getItem("orgID");
  const [description, setDescription] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [year, setYear] = useState("");
  const [file, setFile] = useState("");
  const [videoLink, setvideoLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    let result = true;
    if (courseTitle === "" || courseTitle === null) {
      result = false;
      toast.warning("Input a course");
    }

    if (description === "" || description === null) {
      result = false;
      toast.warning("Write a description");
    }
    if (file === "" || file === null) {
      result = false;
      toast.warning("Upload a file");
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const formData = new FormData();

      const requestData = {
        title: courseTitle,
        description,
        video_url: videoLink,
        year,
      };
      for (const key in requestData) {
        if (requestData.hasOwnProperty(key)) {
          formData.append(key, requestData[key]);
        }
      }
      formData.append("video_file", file);
      try {
        const response = await axios.post(
          `CreateOrgVideo/${orgID}/${courseId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const resp = response.data;
        if (resp.message === "Video uploaded successfully") {
          toast.success("Video uploaded successfully");
          setIsLoading(false);
          handleClose();
        } else {
          throw new Error(resp.message || "Failed to upload video");
        }
      } catch (error) {
        console.log(error)
        toast.error(error.response.data.message || "Error submitting request", {
          theme: "colored",
          autoClose: "2000",
        });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <div className="request-tutorial">
          <h3 className="poppins-24-600 text-blue text-center">
            Input Resource Details
          </h3>
          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-20-500">
              Title
            </label>
            <input
              type="text"
              className="login-input"
              value={courseTitle}
              placeholder="Machine learning"
              onChange={(e) => setCourseTitle(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-20-500">
              Description
            </label>
            <input
              type="text"
              className="login-input"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="A makeup tutorial"
            />
          </div>

          <div className="mb-3">
            <label className="poppins-20-500"> Year</label>
            <input
              type="number"
              value={year}
              placeholder="2025"
              className="login-input"
              onChange={(e) => setYear(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="poppins-20-500"> Video File</label>
            <input
              type="file"
              className="form-control"
              accept=".mp4, .mov, .avi"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <div className="mb-3">
            <label className="poppins-20-500"> Video Link (Optional)</label>
            <input
              type="text"
              value={videoLink}
              placeholder="Enter video link"
              className="login-input"
              onChange={(e) => setvideoLink(e.target.value)}
            />
          </div>

          <div className="text-center">
            <button
              disabled={isLoading}
              type="submit"
              className="blue-filled-btn"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <>Create</>
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OrgCreateVideo;