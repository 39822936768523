import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

const OrgWithdrawal = ({ show, handleClose, tutorId }) => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const orgId = sessionStorage.getItem("orgID");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const bankResponse = await axios.get(
          `ShowBankDetails/${orgId}/organization`
        );
        const bankDetails = bankResponse.data.data;
        setBankDetails(bankDetails);
      } catch (error) {
        console.log("error");
      }
    };
    fetchData();
  }, [orgId]);
  const debitAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `Withdrawal/${orgId}/organization/pay`,
        {
          amount_requested: amount,
          account_number: bankDetails.AccountNumber,
          account_name: bankDetails.AccountName,
          bank_name: bankDetails.BankName,
        }
      );
      const resp = response.data;
      console.log(resp);
      if (resp.status === "success") {
        toast.success(resp.message, {
          theme: "colored",
          autoClose: "2000",
        });
        handleClose();
      } else {
        toast.warning(resp.message, {
          theme: "colored",
          autoClose: "2000",
        });
      }
      
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader></ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="d-block">
            <h5 className="lato-24-500 text-grey">Withdraw Funds</h5>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="lato-20-600 form-control"
              placeholder="Enter amount"
            />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button className="mx-3 blue-filled-btn">
              {loading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                <span onClick={debitAccount}>Make Withdrawal</span>
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OrgWithdrawal;