import React, { useState } from "react";
import { Link } from "react-router-dom";
import pqImg from "../../../../assets/img/Pastquestions solution.jpg";
import flashImg from "../../../../assets/img/Flashcards.jpg";
import quizImg from "../../../../assets/img/Quiz.jpg";
import noteImg from "../../../../assets/img/note.jpg";
import textbookImg from "../../../../assets/img/textbook.jpg";
import videoImg from "../../../../assets/Videoresources.png";
import QuizInfo from "../../StudyResources/QuizInfo";
import QuizModal from "../../StudyResources/QuizModal";
import { Modal, Card, Col, Row } from "react-bootstrap";
import flashCardSvg from "../../../../assets/flashCardSvg.svg";

const getImageForType = (type) => {
  const imageMap = {
    video: videoImg,
    pastquestion: pqImg,
    note: noteImg,
    org_notes: noteImg,
    org_note: noteImg,
    quiz: quizImg,
    flashcard: flashImg,
    textbook: textbookImg,
  };
  return imageMap[type];
};

const AllFeeds = ({ feeds }) => {
  const [showQuizInfo, setShowQuizInfo] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState([]);
  const [currentTopic, setcurrentTopic] = useState([]);
  const openQuizInfo = () => {
    setShowQuizInfo(true);
  };
  const closeQuizInfo = () => {
    setShowQuizInfo(false);
  };
  const openQuizModal = () => {
    closeQuizInfo();
    setShowQuizModal(true);
  };
  const closeQuizModal = () => {
    setShowQuizModal(false);
  };
  const [showFlashcardModal, setShowFlashcardModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] = useState(null);

  function handleNextQuestion() {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setShowAnswer(false);
  }
  const handleFlashcardClick = (card) => {
    setCurrentFlashcard(card);
    setCurrentQuestionIndex(0);
    setShowAnswer(false);
    setShowFlashcardModal(true);
  };
  return (
    <div className="feedCards">
      {feeds.map((each) =>
        each.type === "video" ||
        each.type === "pastquestion" ||
        each.type === "note" ||
        each.type === "org_notes" ||
        each.type === "textbook" ? (
          <div className="col h-100" key={each.id}>
            <div className="card resource-card border-0 h-100">
              <Link
                to="/courses/coursepage/info"
                state={{
                  pdfUrl: each.url,
                  id: each.id,
                  name: each.name,
                  description: each.description,
                  course: each.course,
                  author: each.tutor,
                  views: each.views,
                  rating: each.rating,
                  institution: each.university,
                  resourceType: each.type,
                  courseId: each.course_id,
                }}
                className="text-decoration-none text-dark"
                onClick={() => {
                  sessionStorage.setItem("activeResourceID", each.id);
                  sessionStorage.setItem("activeResourceType", each.type);
                }}
              >
                <img
                  src={getImageForType(each.type)}
                  className="card-img-top"
                  alt={each.type}
                />
              </Link>
              <div className="p-2 mt-4">
                <div className="d-flex">
                  <h6 className="card-title">{each.description}</h6>
                  {/* <div className="ms-auto">
                    <i className="bi bi-eye pe-2"></i>
                    {each.view | 0}
                  </div> */}
                </div>
                <div className="footnote-grey">
                  <h6 className="viga-14-400 m-0">
                    {each.tutor || "ETC Vibes"} . {each.type}
                  </h6>
                  <div className="viga-14-400 text-grey">
                    From: {each.university}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : each.type === "quiz" ? (
          <div className="col h-100">
            <div
              className="card resource-card border-0 h-100"
              onClick={(e) => {
                e.preventDefault();
                openQuizInfo(each.quiz);
                setCurrentQuiz(each.quiz);
                setcurrentTopic(each.topic);
              }}
              role="button"
            >
              <img src={quizImg} className="card-img-top" alt="" />
              <div className="p-2 mt-4">
                <div className="d-flex">
                  <h6 className="card-title">{each.topic}</h6>
                  <div className="ms-auto">
                    <i className="bi bi-eye pe-2"></i>
                  </div>
                </div>
                <div className="footnote-grey">
                  <h6 className="viga-14-400 m-0">ETC Vibes . {each.type}</h6>
                  <div className="viga-14-400 text-grey">
                    From: {each.university}
                  </div>
                  {/* <div className="viga-14-400">2023</div> */}
                </div>
              </div>
            </div>
          </div>
        ) : each.type === "flashcard" ? (
          <div
            className="card resource-card border-0"
            onClick={() => handleFlashcardClick(each)}
          >
            <img
              src={getImageForType(each.type)}
              className="card-img-top"
              alt={each.type}
            />
            <div className="p-2 mt-4">
              <div className="d-flex">
                <h6 className="card-title">{each.topic}</h6>
                <div className="ms-auto"></div>
              </div>
              <div className="footnote-grey">
                <h6 className="viga-14-400 m-0">ETC Vibes . {each.type}</h6>
                <div className="viga-14-400 text-grey">
                  From: {each.university}
                </div>
              </div>
            </div>
          </div>
        ) : null
      )}
      <QuizInfo
        show={showQuizInfo}
        handleClose={closeQuizInfo}
        openQuizModal={openQuizModal}
      />
      <QuizModal
        show={showQuizModal}
        handleClose={closeQuizModal}
        currentQuiz={currentQuiz}
        topic={currentTopic}
      />
      <Modal
        show={showFlashcardModal}
        onHide={() => setShowFlashcardModal(false)}
        centered
        size="md"
      >
        <Modal.Header closeButton>Flashcard Quiz</Modal.Header>
        <Modal.Body style={{ minHeight: "50vh" }}>
          {currentFlashcard && (
            <Row className="row-cols-1">
              <Col>
                <Card
                  className={`mb-3 ${showAnswer ? "flipped" : ""}`}
                  onClick={() => setShowAnswer(!showAnswer)}
                >
                  <div className="d-flex justify-content-center card-header">
                    <img src={flashCardSvg} alt="" />
                    <div className="ms-3 d-block">
                      <h3 className="poppins-24-700">
                        {currentFlashcard.topic}
                      </h3>
                    </div>
                  </div>
                  <div className="card-inner flashQuizzes">
                    <div className="card-front">
                      <Card.Body>
                        <Card.Title>
                          {
                            currentFlashcard.flashcards[currentQuestionIndex]
                              ?.question
                          }
                        </Card.Title>
                        {!showAnswer && (
                          <button className="flashcard-btn">Show Answer</button>
                        )}
                      </Card.Body>
                    </div>
                    <div className="card-back">
                      <Card.Body>
                        <Card.Title>
                          {
                            currentFlashcard.flashcards[currentQuestionIndex]
                              ?.answer
                          }
                        </Card.Title>
                        {currentQuestionIndex <
                        currentFlashcard.flashcards.length - 1 ? (
                          <button
                            onClick={handleNextQuestion}
                            className="flashcard-btn"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setCurrentQuestionIndex(0);
                              setShowAnswer(false);
                            }}
                            className="flashcard-btn"
                          >
                            Restart Quiz
                          </button>
                        )}
                      </Card.Body>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllFeeds;
