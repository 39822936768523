import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { Row } from "react-bootstrap";
import "../UserDashboard/StudyResources/PDF.css";
import { Card, Col } from "react-bootstrap";
import flashCardSvg from "../../assets/flashCardSvg.svg";

const Resource = ({ show, handleClose, link, type }) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function handleNextQuestion() {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setShowAnswer(false);
  }
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size={type === "Flashcard" ? "md" : "lg"}
    >
      <Modal.Header closeButton>Resource Preview</Modal.Header>
      {type === "video" ? (
        <Modal.Body>
          <div className="card rounded p-2 border-0">
            <video controls autoPlay src={link} alt="" />
          </div>
        </Modal.Body>
      ) : type === "Flashcard" ? (
        <Modal.Body style={{ minHeight: "50vh" }}>
          <Row className="row-cols-1">
            <Col>
              <Card
                className={`mb-3 ${showAnswer ? "flipped" : ""}`}
                onClick={() => setShowAnswer(!showAnswer)}
              >
                <div className="d-flex justify-content-center card-header">
                  <img src={flashCardSvg} alt="" />
                  <div className="ms-3 d-block">
                    <h3 className="poppins-24-700">{link.topic}</h3>
                  </div>
                </div>
                <div className="card-inner flashQuizzes">
                  <div className="card-front">
                    <Card.Body>
                      <Card.Title>
                        {link.flash[currentQuestionIndex]?.question}
                      </Card.Title>
                      {!showAnswer && (
                        <button className="flashcard-btn">Show Answer</button>
                      )}
                    </Card.Body>
                  </div>
                  <div className="card-back">
                    <Card.Body>
                      <Card.Title>
                        {link.flash[currentQuestionIndex]?.answer}
                      </Card.Title>
                      {currentQuestionIndex < link.flash.length - 1 ? (
                        <button
                          onClick={handleNextQuestion}
                          className="flashcard-btn"
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setCurrentQuestionIndex(0);
                            setShowAnswer(false);
                          }}
                          className="flashcard-btn"
                        >
                          Restart Quiz
                        </button>
                      )}
                    </Card.Body>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      ) : type === "note" || type === "textbook" || type === "pastquestion" ? (
        <Modal.Body>
          <div className="resource-container p-3 p-md-5">
            <p style={{ textAlign: "center" }}>
              Page {pageNumber} of {numPages}
            </p>
            <div className="nav-but">
              {pageNumber > 1 ? (
                <Button
                  className="mr-2"
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}
                >
                  Previous
                </Button>
              ) : (
                ""
              )}
              {pageNumber < numPages ? (
                <Button
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}
                >
                  Next
                </Button>
              ) : (
                ""
              )}
            </div>
            <div>
              <Document
                file={decodeURIComponent(link)}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  customTextRenderer={false}
                  className="pdf-page"
                  pageNumber={pageNumber}
                />
              </Document>
            </div>
          </div>
        </Modal.Body>
      ) : null}
    </Modal>
  );
};

export default Resource;
