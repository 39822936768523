import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { Link } from "react-router-dom";
import noteIcon from "../../../../assets/img/note.jpg";
import OrgCreateNewNote from "./OrgCreateNewNote";

const OrgCourseNote = ({ courseId }) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const orgId = sessionStorage.getItem("orgID");
  const [courseModal, setCourseModal] = useState(false);

  const openModal = () => setCourseModal(true);
  const closeModal = () => setCourseModal(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`Org/viewNote/${orgId}/${courseId}`);
        const resp = response.data;

        if (
          resp.message === "" ||
          resp.message === "No record Found" ||
          resp.message === "no record found" ||
          resp.message === null
        ) {
          setNotes([]);
        } else {
          setNotes(resp.notes);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId, orgId]);

  return (
    <div>
      <header className="d-flex">
        <button className="blue-filled-btn ms-auto" onClick={openModal}>
          Add New Note
        </button>
      </header>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 mt-2">
        {loading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm text-blue"
              role="status"
            ></span>
          </div>
        ) : notes.length === 0 ? (
          <div className="text-center">
            <p className="text-center">No resource available</p>
          </div>
        ) : (
          notes.map(
            (note) =>
              note !== null && (
                <div className="col" key={note.id}>
                  <div className="card resource-card border-0">
                    <Link
                      to={`/orgcourses/coursepage/resourcepage/document/${encodeURIComponent(
                        note.file
                      )}`}
                      className="text-decoration-none text-dark"
                      onClick={() => {
                        sessionStorage.setItem("activeResourceID", note.id);
                        sessionStorage.setItem("activeResourceType", "note");
                      }}
                    >
                      <img src={noteIcon} className="card-img-top" alt="" />
                    </Link>

                    <div className="card-body">
                      {" "}
                      {/* <div className=" me-auto rating-overlay">
                              <span className="text-white">
                                {" "}
                                Rated {note.rating}
                              </span>
                              <span className="text-gold fs-5">★</span>
                            </div> */}
                      <div className="d-flex align-items-center">
                        <h6 className="card-title">{note.title}</h6>
                      </div>
                      <div className="footnote-grey">
                        <div className="d-flex">
                          <div>
                            <span className="viga-14-400">{note.year}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )
        )}
      </div>

      <ToastContainer transition={Zoom}></ToastContainer>
      <OrgCreateNewNote
        show={courseModal}
        handleClose={closeModal}
        courseId={courseId}
      />
    </div>
  );
};

export default OrgCourseNote;
