import { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import Select from "react-select";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const AddOrgAccount = ({ show, handleClose }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNameDisabled, setAccountNameDisabled] = useState(true);
  const [accountType, setAccountType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const orgID = sessionStorage.getItem("orgID");
  useEffect(() => {
    axios
      .get("GetBankCode")
      .then((response) => {
        const data = response.data.data.message;
        const bankOptions = data.map((bank) => ({
          value: bank.BankCode,
          label: bank.BankName,
        }));
        setOptions(bankOptions);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  function handleSelect(selected) {
    setSelectedOption(selected);
  }
  function handleAccountNumberChange(event) {
    const inputAccountNumber = event.target.value;

    if (inputAccountNumber.length >= 10) {
      // Made a GET request to verify the account number
      const bankCode = selectedOption ? selectedOption.value : "";
      axios
        .get(`VerifyAccountNumber/${inputAccountNumber}/${bankCode}`)
        .then((response) => {
          const data = response.data.data.message;
          const verifiedAccountName = data.AccountName;

          setAccountName(verifiedAccountName);
          setAccountNameDisabled(true); // Disable manual input
        })
        .catch((error) => {
          console.error("Error verifying account number:", error);
          // Handle the error here
        });
    }

    setAccountNumber(inputAccountNumber);
  }

  const AddAccount = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const obj = {
      AccountNumber: accountNumber,
      BankName: selectedOption.label,
      AccountName: accountName,
      AccountType: accountType,
      //   orgId: orgID,
    };
    try {
      const response = await axios.post(
        `AddAccount/${orgID}/organization`,
        obj
      );
      const resp = response.data;
      if (resp.status === "success") {
        console.log(resp.status);
        toast.success("Bank details added successfully", {
          theme: "colored",
          autoClose: "2000",
        });
        setIsLoading(false);
      } else {
        toast.warning(resp.message, {
          theme: "colored",
          autoClose: "2000",
        });
        setIsLoading(false);
      }
      handleClose();
    } catch (error) {
      toast.error(error, {
        theme: "colored",
        autoClose: "2000",
      });
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <form onSubmit={AddAccount}>
          <div className="mb-3">
            <label htmlFor="" className="lato-24-500 text-grey">
              Bank
            </label>
            <Select
              options={options}
              value={selectedOption}
              onChange={handleSelect}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="lato-24-500 text-grey">
              Account Number
            </label>
            <input
              type="number"
              className="form-control"
              value={accountNumber}
              onChange={handleAccountNumberChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="lato-24-500 text-grey">
              Account Name
            </label>
            <input
              type="text"
              className="form-control"
              value={accountName}
              disabled={accountNameDisabled}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="lato-24-500 text-grey">
              Account Type
            </label>
            <select
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              className="form-select"
            >
              <option value=""></option>
              <option value="savings">Savings</option>
              <option value="current">Current</option>
            </select>
          </div>
          <div className="d-flex">
            <button type="submit" className="blue-filled-btn ms-auto">
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddOrgAccount;
