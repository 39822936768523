import noteIcon from "../../../../assets/img/note.jpg";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";
import { Link } from "react-router-dom";

const NoteResources = ({ courseId }) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const userID = sessionStorage.getItem("userID");
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`loadNotes/${courseId}/${userID}`);
        const resp = response.data.data;
        console.log(resp.message);

        if (
          resp.message === "" ||
          resp.message === "No record Found" ||
          resp.message === "no record found" ||
          resp.message === null
        ) {
          setNotes([]);
        } else {
          setNotes(resp.message);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId, userID]);

  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 ">
      {loading ? (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-blue"
            role="status"
          ></span>
        </div>
      ) : (
        <>
          {notes.length === 0 ? (
            <div className="text-center">
              <p>No resource available</p>
            </div>
          ) : (
            <>
              {notes.map(
                (note) =>
                  note !== null && (
                    <div className="col" key={note.id}>
                      <div className="card resource-card border-0">
                        <Link
                          to="/courses/coursepage/info"
                          state={{
                            pdfUrl: note.NoteUrl,
                            id: note.id,
                            name: note.Notename,
                            description: note.NoteDescription,
                            course: note.NoteCourse,
                            author: note.NoteTutor,
                            views: note.views,
                            rating: note.rating,
                            institution: note.institution,
                            resourceType: "note",
                            courseId: courseId,
                          }}
                          className="text-decoration-none text-dark"
                          onClick={() => {
                            sessionStorage.setItem("activeResourceID", note.id);
                            sessionStorage.setItem(
                              "activeResourceType",
                              "note"
                            );
                          }}
                        >
                          <img src={noteIcon} className="card-img-top" alt="" />
                        </Link>
                        <div className="card-body">
                          {" "}
                          <div className=" me-auto rating-overlay">
                            <span className="text-white">
                              Rated {note.rating}
                            </span>
                            <span className="text-gold fs-5">★</span>
                          </div>
                          <div className=" d-flex align-items-center">
                            <h6 className="card-title">{note.Notename}</h6>
                            <i className="bi bi-eye pe-2 ms-auto"></i>
                            {note.views}
                          </div>
                          <div className="footnote-grey">
                            <div className="d-flex">
                              <div>
                                <span className="viga-14-400">
                                  {note.NoteTutor}
                                </span>
                                <div className="viga-14-400">
                                  From: {note.institution}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default NoteResources;
