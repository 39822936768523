import whiteLogo from "../../assets/img/whiteLogo.svg";
import logout from "../../assets/img/logoutG.png";
import { NavLink } from "react-router-dom";
const OrgSidebar = () => {
  return (
    <div className="tutor-sidebar">
      <div className="sidebar-logo">
        <img src={whiteLogo} alt="Logo" />
      </div>
      <div className="navbar">
        <NavLink
          to="/org"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-grid-fill nav-icon"></i>
            Home
          </div>
        </NavLink>
        <NavLink
          to="/orgcourses"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-file-earmark-richtext nav-icon"></i>
            Courses
          </div>
        </NavLink>
        <NavLink
          to="/orgquiz"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-building nav-icon"></i>
            Quiz Feedback
          </div>
        </NavLink>

        <NavLink
          to="/orgwallet"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-wallet nav-icon"></i>
            Wallet
          </div>
        </NavLink>

        <NavLink
          to="/orgnotifications"
          className={({ isActive }) => (isActive ? "navactive" : "inactive")}
        >
          <div className="nav-item d-flex">
            <i className="bi bi-bell nav-icon"></i>
            Notifications
          </div>
        </NavLink>
      </div>
      <div className="tutor-sidebar-tools">
        <nav className="navbar">
          <NavLink
            to="/orgposts"
            className={({ isActive }) =>
              isActive ? "sidebar-tools-active" : "sidebar-tools-inactive"
            }
          >
            <div className="nav-item d-flex">
              <i className="bi bi-cloud-arrow-up nav-icon"></i>
              Posts
            </div>
          </NavLink>

          <NavLink to="#">
            <div
              className="nav-item d-flex"
              data-bs-toggle="modal"
              data-bs-target="#logoutModal"
            >
              <img src={logout} className="px-3" alt="" />
              Logout
            </div>
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default OrgSidebar;
