import { useState } from "react";
import AllTutorial from "./AllTutorial";
import RequestedTut from "./RequestedTut";
import { Link } from "react-router-dom";
import RequestTutModal from "./RequestTutModal";
import Recorded from "./Recorded";
export const TutorialContainer = () => {
  const [tutorialState, setTutorialState] = useState(<AllTutorial />);
  const [allTutBtn, setAllTutBtn] = useState("course-btn-active");
  const [reqTutBtn, setReqTutBtn] = useState("course-btn-inactive");
  const [recTutBtn, setRecTutBtn] = useState("course-btn-inactive");
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const handleAllTut = () => {
    setReqTutBtn("course-btn-inactive");
    setRecTutBtn("course-btn-inactive");
    setAllTutBtn("course-btn-active ");
    setTutorialState(<AllTutorial />);
  };
  const handleRecordedTut = () => {
    setReqTutBtn("course-btn-inactive");
    setAllTutBtn("course-btn-inactive ");
    setRecTutBtn("course-btn-active ");
    setTutorialState(<Recorded />);
  };
  const handleRequestedTut = () => {
    setAllTutBtn("course-btn-inactive ");
    setRecTutBtn("course-btn-inactive ");
    setReqTutBtn("course-btn-active");
    setTutorialState(<RequestedTut />);
  };
  const closeRequestModal = () => {
    setOpenRequestModal(false);
  };

  return (
    <div className="container-fluid tutorial-container px-2 px-md-4 mb-5 mb-md-0">
      <div className="d-block d-md-flex tutorial-top-nav">
        <div className="tutorial-routes d-flex">
          <button className={allTutBtn} onClick={handleAllTut}>
            <h3 className="lato-18-700 m-1">All</h3>
          </button>
          <button className={reqTutBtn} onClick={handleRequestedTut}>
            <h3 className="lato-18-700 m-1">Requested</h3>
          </button>
          <button className={recTutBtn} onClick={handleRecordedTut}>
            <h3 className="lato-18-700 m-1">Recorded</h3>
          </button>
        </div>
        <div className="d-flex ms-auto align-items-center">
          <Link className="mt-4 m-md-0">
            <button
              className="blue-filled-btn"
              onClick={() => setOpenRequestModal(true)}
            >
              <i className="bi bi-plus"></i> Request Tutorial
            </button>
          </Link>
          <RequestTutModal
            show={openRequestModal}
            handleClose={closeRequestModal}
          />
        </div>
      </div>
      <div className="py-4">{tutorialState}</div>
    </div>
  );
};
