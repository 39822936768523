import { useState } from "react";
import NoteResources from "./CourseNav/NoteResources";
import Textbooks from "./CourseNav/Textbooks";
import PqResources from "./CourseNav/PqResources";
import VideoResources from "./CourseNav/VideoResources";
import Quizresources from "./CourseNav/Quizresources";
import FlashCardList from "./CourseNav/FlashCardList";
import DashboardSidebar from "../DashboardSidebar";
import LogoutModal from "../../../components/LogoutModal";
import DashboardHeader from "../DashboardHeader";
import MobileNav from "../../../components/MobileNav";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";

const CoursePage = () => {
  const [activeResource, setActiveResource] = useState(
    "Past Questions & Solution"
  );
  const navigate = useNavigate();
  const { courseId } = useParams();
  const location = useLocation();
  const courseName = new URLSearchParams(location.search).get("courseName");

  const resourceComponents = {
    "Past Questions & Solution": <PqResources courseId={courseId} />,
    textbooks: <Textbooks courseId={courseId} />,
    notes: <NoteResources courseId={courseId} />,
    videos: <VideoResources courseId={courseId} />,
    quiz: <Quizresources courseId={courseId} />,
    flashcards: <FlashCardList courseId={courseId} />,
  };

  const handleResourceClick = (resource) => {
    setActiveResource(resource);
  };

  return (
    <section className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-1">
        <DashboardHeader />
        <div className="container-fluid">
          <div className="course-header ps-1">
            <button
              className="btn p-0 mb-1 text-grey lato-24-600"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            <p className="lato-20-600">{courseName}</p>
            {/* Course Navbar */}
            <div className="course-nav scrolling-wrapper d-flex py-2">
              {Object.keys(resourceComponents).map((resourceKey) => (
                <div
                  className={`nav-item resource-button ${
                    activeResource === resourceKey
                      ? "resource-active"
                      : "resource-inactive"
                  }`}
                  role="button"
                  key={resourceKey}
                  onClick={() => handleResourceClick(resourceKey)}
                >
                  {resourceKey.charAt(0).toUpperCase() + resourceKey.slice(1)}
                </div>
              ))}
            </div>
          </div>
          {/* Course resources */}
          <div className="course-resources py-3 mb-5">
            {resourceComponents[activeResource]}
          </div>
        </div>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
      <MobileNav />
    </section>
  );
};

export default CoursePage;
