import React, { useEffect, useState } from "react";
import OrgSidebar from "../OrgSidebar";
import LogoutModal from "../../../components/LogoutModal";
import OrgHeader from "../OrgHeader";
import MobileOrgNav from "../../../components/MobileOrgNav";
import bellIcon from "../../../assets/img/bell-ringing-04.png";
import MobileNav from "../../../components/MobileNav";
import axios from "../../../api/axios";
import { JellyTriangle } from "@uiball/loaders";

const OrgNotification = () => {
  // const [recentBtn, setRecentBtn] = useState("notification-active");
  // const [allBtn, setAllBtn] = useState("notification-inactive");
  const orgID = sessionStorage.getItem("orgID");
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(10);
  useEffect(() => {
    setLoading(true);
    const fetchNoty = async () => {
      const response = await axios(`org/${orgID}/notifications`);
      const notification = response.data.notifications;
      console.log(notification);
      if (
        notification === "You do not have any notification yet" ||
        notification === ""
      ) {
        setNotifications([]);
      } else {
        setNotifications(notification);
      }
      setLoading(false);
    };
    fetchNoty();
  }, [orgID]);

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };
  return (
    <div className="container-fluid p-0">
      <OrgSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3">
        <OrgHeader />
        <section className="container-fluid px-3 px-md-4">
          <div className="d-flex pb-4">
            <div className="notification-header d-flex m-auto">
              <button className="notification-active">Notifications</button>
            </div>
          </div>

          <div className="notification-wrapper">
            {loading ? (
              <div className="container-fluid d-flex justify-content-center align-items-center py-5">
                <div className="container d-flex justify-content-center">
                  <JellyTriangle size={80} speed={1.75} color="#044aac" />
                </div>
              </div>
            ) : (
              <div className="d-block px-0 py-4">
                {currentNotifications.map((notification) => (
                  <div
                    className="d-flex notification-cell px-4 py-2"
                    key={notification.id}
                  >
                    <div className="notification-icon">
                      <img src={bellIcon} alt="" />
                    </div>
                    <div className="notification-body ms-3">
                      <h4 className="viga-16-400">{notification.title}</h4>
                      <p className="lato-16-400">{notification.message}</p>
                      <div className="notification-date text-grey">
                        <i className="bi bi-calendar pe-2"></i>
                        {formatDate(notification.created_at)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <ul className="pagination">
            {Array.from(
              {
                length: Math.ceil(notifications.length / notificationsPerPage),
              },
              (_, i) => (
                <li
                  key={i}
                  className={`page-item ${
                    currentPage === i + 1 ? "active" : ""
                  }`}
                >
                  <button onClick={() => paginate(i + 1)} className="page-link">
                    {i + 1}
                  </button>
                </li>
              )
            )}
          </ul>

          <MobileNav />
        </section>
      </div>
      <MobileOrgNav />
    </div>
  );
};

export default OrgNotification;
