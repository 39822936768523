// import Home from "./pages/Home/Home";
import SignUp from "./pages/Auth/Signup/SignUp";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import CreateAccount from "./pages/Auth/Signup/CreateAccount";
import AcctSetup from "./pages/Auth/Signup/AcctSetup";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import AcctSetupTutor from "./pages/Auth/Signup/AcctSetupTutor";
import RegistrationSuccess from "./pages/Auth/RegistrationSuccess";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import Courses from "./pages/UserDashboard/StudyResources/Courses";
import UserTutorials from "./pages/UserDashboard/Tutorial/UserTutorials";
import UserWallet from "./pages/UserDashboard/Wallet/UserWallet";
import Test from "./pages/Test";
import TutorialPage from "./pages/UserDashboard/Tutorial/TutorialPage";
import UserNotification from "./pages/UserDashboard/Notifications/UserNotification";
import Help from "./pages/UserDashboard/Help/Help";
// import ResourcePage from "./pages/UserDashboard/StudyResources/ResourcePage";
import Profile from "./components/Profile";
import ProfileInfoPage from "./components/ProfileInfoPage";
import CoursePage from "./pages/UserDashboard/StudyResources/CoursePage";
import TutorDashboard from "./pages/TutorDashboard/Dashboard/TutorDashboard";
import TutorTutorial from "./pages/TutorDashboard/Tutorials/TutorTutorial";
import TutorWallet from "./pages/TutorDashboard/Wallet/TutorWallet";
import TutorNotifications from "./pages/TutorDashboard/Notifications/TutorNotifications";
import EtcBot from "./pages/UserDashboard/AIBot/EtcBot";
// import HomeFlashCards from "./pages/Home/HomeFlashCards";
import AdminTutors from "./pages/Admin/Tutors/AdminTutors";
import AdminStudents from "./pages/Admin/Students/AdminStudents";
import CgpaCalc from "./pages/UserDashboard/CGPA Calc/CgpaCalc";
import AdminFinance from "./pages/Admin/Finance/AdminFinance";
import AdminResource from "./pages/Admin/ResourcesPage/AdminResource";
import ResourceDetails from "./pages/Admin/ResourcesPage/ResourceDetails";
import AdminTutorial from "./pages/Admin/Tutorials/AdminTutorial";
import StudentDetails from "./pages/Admin/Students/StudentDetails";
import AdminTutDetails from "./pages/Admin/Tutorials/AdminTutDetails";
import TutorDetails from "./pages/Admin/Tutors/TutorDetails";
import AdminNotifications from "./pages/Admin/Notifications/AdminNotifications";
import EmailReset from "./pages/Auth/ResetPassword/EmailReset";
import UserVerify from "./pages/Auth/Signup/UserVerify";
import TutorVerify from "./pages/Auth/Signup/TutorVerify";
import WalletSubscription from "./pages/UserDashboard/Wallet/WalletSubscription";
import TutorProfile from "./pages/TutorDashboard/TutorProfile";
import MeetingPage from "./pages/TutorDashboard/Tutorials/MeetingPage";
import AdminLogin from "./pages/Admin/auth/AdminLogin";
import AdminSignup from "./pages/Admin/auth/AdminSignup";
import EarningsPage from "./pages/Admin/Finance/EarningsPage";
import PayoutsPage from "./pages/Admin/Finance/PayoutsPage";
import SubscriptionsDetails from "./pages/Admin/Finance/SubscriptionsDetails";
import AdminUniPage from "./pages/Admin/ResourcesPage/university/AdminUniPage";
import AdminFacultyPage from "./pages/Admin/ResourcesPage/faculties/AdminFacultyPage";
import AdminDepartmentsPage from "./pages/Admin/ResourcesPage/departments/AdminDepartmentsPage";
import AdminCoursesPage from "./pages/Admin/ResourcesPage/courses/AdminCoursesPage";
import AdminTextbooks from "./pages/Admin/ResourcesPage/resources/Textbooks/AdminTextbooks";
import AdminFlashCards from "./pages/Admin/ResourcesPage/resources/FlashCards/AdminFlashCards";
import { AdminVideoResources } from "./pages/Admin/ResourcesPage/resources/Videos/AdminVideoResources";
import AdminNotes from "./pages/Admin/ResourcesPage/resources/Notes/AdminNotes";
import AdminQuizResources from "./pages/Admin/ResourcesPage/resources/Quiz/AdminQuizResources";
import AdminPq from "./pages/Admin/ResourcesPage/resources/PastQuestions/AdminPq";
import TutorHelp from "./pages/TutorDashboard/Help/TutorHelp";

import { pdfjs } from "react-pdf";
import Newhome from "./pages/Home/Home";
import MyPdfViewer from "./pages/UserDashboard/StudyResources/PDF";
import Videoresource from "./pages/UserDashboard/StudyResources/Videoresource";
import UploadedResources from "./pages/UserDashboard/StudyResources/UploadResources/UploadedResources";
import TutorUploadPage from "./pages/TutorDashboard/Upload/TutorUploadPage";
import StudentUploadDetails from "./pages/Admin/ResourcesPage/studentUpload/StudentUploadDetails";
import CoinPage from "./pages/UserDashboard/Wallet/CoinPage";
import CoinRequestPage from "./pages/Admin/Finance/coinConversion/CoinRequestPage";
import AdminPostPage from "./pages/Admin/posts/AdminPostPage";
import AdminPDF from "./pages/Admin/ResourcesPage/AdminPDF";
import UserScholarships from "./pages/UserDashboard/Scholarships/UserScholarships";
import UserScholarshipsElement from "./pages/UserDashboard/Scholarships/ScholarshipDetails";
import AdminOrganization from "./pages/Admin/Organizations/AdminOrganization";
import OrganizationDetails from "./pages/Admin/Organizations/OrganizationDetails";
import OrgDashboard from "./pages/OrgDashboard/Dashboard/OrgDashboard";
import NotFound from "./components/NotFound";
import OrgCourses from "./pages/OrgDashboard/Courses/OrgCourses";
import OrgQuizFeedback from "./pages/OrgDashboard/Quiz/OrgQuizFeedback";
import OrgWallet from "./pages/OrgDashboard/Wallet/OrgWallet";
import OrgNotification from "./pages/OrgDashboard/Notifications/OrgNotification";
import OrgPosts from "./pages/OrgDashboard/Posts/OrgPosts";
import OrgCoursePage from "./pages/OrgDashboard/Courses/OrgCoursePage";
import OrgQuizScores from "./pages/OrgDashboard/Quiz/OrgQuizScores";
import OrgPdf from "./pages/OrgDashboard/Courses/courseMaterials/OrgPdf";
import { useEffect } from "react";
import CoursePreview from "./pages/UserDashboard/StudyResources/CoursePreview";
import UserLms from "./pages/UserDashboard/LMS/UserLms";
import LmsCourse from "./pages/UserDashboard/LMS/LmsCourse";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Newhome />} />
          <Route path="*" element={<NotFound />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="login" element={<Login />} />
          <Route path="userverify" element={<UserVerify />} />
          <Route path="tutorverify" element={<TutorVerify />} />
          <Route path="resetPassword" element={<ResetPassword />} />
          <Route path="emailreset/:email/:token" element={<EmailReset />} />
          <Route path="createAccount/:userType" element={<CreateAccount />} />
          <Route path="acctSetup" element={<AcctSetup />} />
          <Route path="acctSetupTutor" element={<AcctSetupTutor />} />
          <Route path="successful" element={<RegistrationSuccess />} />
          <Route path="userdashboard" element={<UserDashboard />} />
          <Route path="courses" element={<Courses />} />
          <Route path="userTutorials" element={<UserTutorials />}></Route>
          <Route path="userTutorials/livetutorial" element={<TutorialPage />} />
          <Route path="profile/cgpacalc" element={<CgpaCalc />} />
          <Route path="userWallet" element={<UserWallet />} />
          <Route path="userWallet/walletsub" element={<WalletSubscription />} />
          <Route path="userWallet/coinwallet" element={<CoinPage />} />
          <Route path="notifications" element={<UserNotification />} />
          <Route path="help" element={<Help />} />
          <Route
            path="/courses/coursepage/:courseId"
            element={<CoursePage />}
          />
          <Route path="/courses/coursepage/info" element={<CoursePreview />} />

          <Route
            path="adminresource/uploaddetails/:type/:id/:source"
            element={<AdminPDF />}
          />
          <Route
            path="courses/coursepage/resourcepage/document/:source"
            element={<MyPdfViewer />}
          />
          <Route
            path="orgcourses/coursepage/resourcepage/document/:source"
            element={<OrgPdf />}
          />
          <Route
            path="courses/coursepage/resourcepage/:video"
            element={<Videoresource />}
          />

          <Route path="courses/uploadandearn" element={<UploadedResources />} />
          <Route path="profile" element={<Profile />} />
          <Route path="userprofileinfo/profile" element={<Profile />} />
          <Route path="userprofileinfo" element={<ProfileInfoPage />} />
          <Route path="test" element={<Test />} />
          <Route path="etcbot" element={<EtcBot />} />
          <Route path="awards" element={<UserScholarships />} />
          <Route path="awards/:id" element={<UserScholarshipsElement />} />
          <Route path="userlms" element={<UserLms />} />
          <Route path="userlms/:id" element={<LmsCourse />} />
          <Route path="tutor" element={<TutorDashboard />} />
          <Route path="tutortutorials" element={<TutorTutorial />} />
          <Route
            path="tutortutorials/livetutorial/*"
            element={<MeetingPage />}
          />
          <Route path="tutorwallet" element={<TutorWallet />} />
          <Route path="tutorprofile" element={<TutorProfile />} />
          <Route path="tutornotifications" element={<TutorNotifications />} />
          <Route path="tutorhelp" element={<TutorHelp />} />
          <Route path="tutorupload" element={<TutorUploadPage />} />

          <Route path="admin" element={<AdminFinance />} />
          <Route path="admin/earnings" element={<EarningsPage />} />
          <Route path="admin/payouts" element={<PayoutsPage />} />
          <Route
            path="admin/subscriptions"
            element={<SubscriptionsDetails />}
          />
          <Route path="admin/coinrequest" element={<CoinRequestPage />} />
          <Route path="adminresource" element={<AdminResource />} />
          <Route path="adminresource/details" element={<ResourceDetails />} />
          <Route path="adminresource/universities" element={<AdminUniPage />} />
          <Route path="adminresource/faculty" element={<AdminFacultyPage />} />
          <Route
            path="adminresource/departments"
            element={<AdminDepartmentsPage />}
          />
          <Route path="adminresource/courses" element={<AdminCoursesPage />} />
          <Route path="adminresource/textbooks" element={<AdminTextbooks />} />
          <Route
            path="adminresource/flashcards"
            element={<AdminFlashCards />}
          />
          <Route
            path="adminresource/videos"
            element={<AdminVideoResources />}
          />
          <Route path="adminresource/notes" element={<AdminNotes />} />
          <Route path="adminresource/quiz" element={<AdminQuizResources />} />
          <Route path="adminresource/pq" element={<AdminPq />} />

          <Route path="admintutorial" element={<AdminTutorial />} />
          <Route path="admintutorial/details" element={<AdminTutDetails />} />
          <Route path="admintutor" element={<AdminTutors />} />
          <Route
            path="admintutor/details/:tutorId"
            element={<TutorDetails />}
          />
          <Route path="adminstudent" element={<AdminStudents />} />
          <Route path="adminorg" element={<AdminOrganization />} />
          <Route
            path="adminorg/details/:orgId"
            element={<OrganizationDetails />}
          />
          <Route
            path="adminstudent/details/:studentId"
            element={<StudentDetails />}
          />
          <Route
            path="adminresource/uploaddetails/:type/:id"
            element={<StudentUploadDetails />}
          />
          <Route path="adminpost" element={<AdminPostPage />} />
          <Route path="adminnotifications" element={<AdminNotifications />} />
          <Route path="adminlogin" element={<AdminLogin />} />
          <Route path="adminsignup" element={<AdminSignup />} />
          <Route path="/" element={<Newhome />} />
          <Route path="org" element={<OrgDashboard />} />
          <Route path="orgcourses" element={<OrgCourses />} />
          <Route path="orgquiz" element={<OrgQuizFeedback />} />
          <Route path="orgwallet" element={<OrgWallet />} />
          <Route path="orgnotifications" element={<OrgNotification />} />
          <Route path="orgposts" element={<OrgPosts />} />
          <Route
            path="/orgcourses/coursepage/:courseId"
            element={<OrgCoursePage />}
          />
          <Route
            path="/orgquiz/coursescores/:courseId"
            element={<OrgQuizScores />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
