import { ToastContainer, Zoom } from "react-toastify";
import AllCoursesContent from "./AllCoursesContent";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import AddOtherCourse from "./AddOtherCourse";
const AllCourses = () => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const schoolID = sessionStorage.getItem("userSchool");
  const departmentID = sessionStorage.getItem("userDepartment");
  const facultyID = sessionStorage.getItem("userFaculty");
  const [addModal, setAddModal] = useState(false);
  const [userFaculty, setUserFaculty] = useState({});
  const [userDepartment, setUserDepartment] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch all data concurrently using Promise.all
        const [coursesResponse, departmentResponse, facultyResponse] =
          await Promise.all([
            axios.get(`loadResources/${departmentID}`),
            axios.get("listDepartment"),
            axios.get("Faculty"),
          ]);

        const coursesData = coursesResponse.data.data;
        if (coursesData.status === "success") {
          setCourses(coursesData.message);
        } else {
          setCourses([]);
        }
        const departmentData = departmentResponse.data.data;

        const facultyData = facultyResponse.data.data;

        // Check if both faculty and department data are available
        if (facultyData.message && departmentData.message) {
          // Find the corresponding faculty using the user's facultyId
          const foundFaculty = facultyData.message.find(
            (fac) => fac.id === parseInt(facultyID, 10)
          );

          if (foundFaculty) {
            setUserFaculty(foundFaculty);

            const foundDepartment = departmentData.message.find(
              (dep) => dep.id === parseInt(departmentID, 10)
            );
            console.log(foundDepartment);
            setUserDepartment(foundDepartment);
          } else {
            // If faculty is not found, reset the faculty information
            setUserFaculty({});
            setUserDepartment({});
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error fetching courses", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    };
    fetchData();
  }, [schoolID, departmentID, facultyID]);

  const closeAddModal = () => {
    setAddModal(false);
  };
  return (
    <section className="AllCoursesContainer mb-5 mb-md-2 mb-lg-0">
      <div className="d-flex py-3 align-items-center">
        <div className="d-flex">
          <div className="all-course-card border-left-blue mb-2">
            <div className="d-flex text-blue category-card">
              <i className="bi bi-buildings fs-2 px-2"></i>
              {loading ? (
                <span className="spinner-border spinner-border-sm text-blue"></span>
              ) : (
                <p className="lato-18-700 m-0">
                  {userFaculty && userFaculty.faculty_name
                    ? userFaculty.faculty_name
                    : "Faculty Not Found"}
                </p>
              )}
            </div>
          </div>
        </div>
        <button
          className="blue-filled-btn-5p ms-auto"
          onClick={() => setAddModal(true)}
        >
          + Add Other Courses
        </button>
        {/* <input
          type="search"
          className="search-bar-study ms-auto p-3"
          placeholder="Add other courses"
          onChange={(e) => searchItem(e.target.value)}
        /> */}
      </div>
      <div className="course-categories px-1 px-md-0">
        {loading ? (
          <div className="text-center fs-4">
            <span className="spinner-border spinner-border-sm  text-blue"></span>
          </div>
        ) : (
          <AllCoursesContent
            courses={courses}
            userDepartment={userDepartment}
          />
        )}
      </div>
      <AddOtherCourse show={addModal} handleClose={closeAddModal} />
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default AllCourses;
