import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";

const OrgCreatePost = ({ show, handleClose, courseId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const orgID = sessionStorage.getItem("orgID");
  const [message, setMessage] = useState("");
  const [broadcastType, setBroadcastType] = useState("0");
  const [title, setTitle] = useState("");

  const validateForm = () => {
    let result = true;
    if (title === "" || title === null) {
      result = false;
      toast.warning("Input a course");
    }
    if (message === "" || message === null) {
      result = false;
      toast.warning("Write a description");
    }

    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const requestData = {
        title,
        message,
        public: broadcastType,
      };
      try {
        const response = await axios.post(
          `CreateOrgAnnouncement/${orgID}/${courseId}`,
          requestData
        );
        const resp = response.data;
        if (resp.message === "Announcement posted successfully") {
          toast.success("Announcement posted successfully");
          setIsLoading(false);
          handleClose();
        } else {
          throw new Error(resp.message || "Failed to upload post");
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message || "Error submitting request", {
          theme: "colored",
          autoClose: "2000",
        });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <div className="request-tutorial">
          <h3 className="poppins-24-600 text-blue text-center">
            Input Announcement Details
          </h3>
          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-20-500">
              Title
            </label>
            <input
              type="text"
              className="login-input"
              value={title}
              placeholder="Announcement Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="Topic" className="poppins-20-500">
              Message
            </label>
            <input
              type="text"
              className="login-input"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type the announcement message"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="" className="poppins-20-500">
              Broadcast Type
            </label>
            <select
              value={broadcastType}
              onChange={(e) => setBroadcastType(e.target.value)}
              className="login-input"
            >
              <option value="">Select an option</option>
              <option value="0">In-course Announcemment</option>
              <option value="1">General Announcemment</option>
            </select>
          </div>

          <div className="text-center">
            <button
              disabled={isLoading}
              type="submit"
              className="blue-filled-btn"
              onClick={handleSubmit}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              ) : (
                <>Create</>
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OrgCreatePost;
