import { useCallback, useState } from "react";
import tutorialPoster from "../../../assets/img/tutImg.svg";
import PayInfoModal from "./PayInfoModal";
import RequestTutModal from "./RequestTutModal";
import { Pagination } from "react-bootstrap";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { useEffect } from "react";
import axios from "../../../api/axios";

const AllTutorial = ({ tutorials }) => {
  const [currentState, setCurrentState] = useState("Upcoming");
  const [upTutBtn, setUpTutBtn] = useState("notification-active");
  const [attTutBtn, setAttTutBtn] = useState("notification-inactive");
  const [showPayInfo, setShowPayInfo] = useState(false);
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const userID = sessionStorage.getItem("userID");
  const [allTutorials, setAllTutorials] = useState([]);
  const [selectedTutorial, setSelectedTutorial] = useState("");
  const facId = sessionStorage.getItem("userFaculty");

  const fetchTutorial = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `showallTutorial/${facId}/accepted/${userID}`
      );
      const resp = response.data.data;
      if (resp.status === "success") {
        const upcomingTutorials = resp.message.filter(
          (tutorial) => new Date(tutorial.time) > new Date()
        );
        const recordedTutorials = resp.message.filter(
          (tutorial) => tutorial.paid === "Y"
        );
        if (currentState === "Upcoming") {
          setAllTutorials(upcomingTutorials);
        } else if (currentState === "Paid") {
          setAllTutorials(recordedTutorials);
        }
        setLoading(false);
      } else {
        setAllTutorials([]);
        console.log(resp.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error, {
        theme: "colored",
      });
      setLoading(false);
    }
  }, [userID, facId, currentState]);

  useEffect(() => {
    fetchTutorial();
  }, [fetchTutorial]);

  // Callback function to reload data
  const reloadData = useCallback(() => {
    fetchTutorial();
  }, [fetchTutorial]);

  const handleUpcomingTut = (upTut) => {
    setUpTutBtn("notification-active");
    setAttTutBtn("notification-inactive");
    setCurrentState(upTut);
    return upTut;
  };

  const handleAttendedTut = (attTut) => {
    setUpTutBtn("notification-inactive");
    setAttTutBtn("notification-active");
    setCurrentState(attTut);
    return attTut;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const tutorialsPerPage = 5;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastTutorial = currentPage * tutorialsPerPage;
  const indexOfFirstTutorial = indexOfLastTutorial - tutorialsPerPage;
  const currentTutorials = allTutorials.slice(
    indexOfFirstTutorial,
    indexOfLastTutorial
  );

  const handlePay = (amount, id) => {
    setPrice(amount);
    setShowPayInfo(true);
    setSelectedTutorial(id.toString());
    console.log(amount, id.toString());
  };
  const formatDateTime = (dateTimeString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    };
    const formattedDateTime = new Date(dateTimeString).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDateTime;
  };
  return (
    <div className="tutorial-container">
      <div className="d-flex mb-2 align-items-center">
        <div className="notification-header d-flex">
          <button
            className={upTutBtn}
            onClick={() => {
              handleUpcomingTut("Upcoming");
            }}
          >
            Upcoming
          </button>
          <button
            className={attTutBtn}
            onClick={() => {
              handleAttendedTut("Paid");
            }}
          >
            Paid
          </button>
        </div>
      </div>
      {/* Tutorial Cards */}
      {loading ? (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-lg my-5 text-blue"
            role="status"
          ></span>
        </div>
      ) : allTutorials.length === 0 ? (
        <p className="text-center mt-5">No tutorials available</p>
      ) : (
        <div className="row row-cols-1 row-cols-md-3">
          {currentTutorials.map((tutorial, index) => (
            <div className="col d-flex justify-content-center" key={index}>
              <div className="tutorial-card-container d-block p-3 mb-3">
                <div className="card border-0">
                  <img
                    src={tutorialPoster}
                    alt=""
                    className="card-img tutorial-card-img"
                  />
                </div>
                <div className="d-block">
                  <div className="d-flex align-items-center my-2">
                    <span className={tutorial.classname}>
                      {tutorial.status}
                    </span>
                    {tutorial.amount === "0.00" ? (
                      <span className="tutorial-fee ms-auto">Free</span>
                    ) : (
                      <span className="tutorial-fee ms-auto">
                        ₦{tutorial.amount}
                      </span>
                    )}
                  </div>
                  <h5 className="font-20-700 text-blue">{tutorial.title}</h5>
                  <p className="font-12-400 ">{tutorial.about}</p>
                </div>
                <div className="d-flex w-100">
                  <span className="lato-12-300 pe-1">Tutor: </span>
                  <div className="d-block">
                    <p className="lato-12-400 mb-0 fw-bold">{tutorial.tutor}</p>
                  </div>
                </div>
                <div className="d-flex">
                  {/* <span className="lato-12-300 pe-1">Date: </span> */}
                  <div className="d-flex w-100">
                    <p className="lato-14-400 mb-0 fw-bold">
                      {formatDateTime(tutorial.time)}
                    </p>
                    {/* <h6 className="lato-8-400 ms-auto">{tutorial.amount}</h6> */}
                  </div>
                </div>
                {/* Footer buttons */}
                {tutorial.amount === "0.00" ? (
                  <div className="card-footer d-flex align-items-center mt-3">
                    <a href={tutorial.join_meeting}>
                      <button className=" tutorial-join-btn">
                        Join Tutorial
                      </button>
                    </a>
                  </div>
                ) : (
                  <div className="card-foooter d-flex align-items-center mt-3">
                    {tutorial.paid === "" ||
                    tutorial.paid === null ||
                    tutorial.paid === "N" ? (
                      <button
                        className="tutorial-pay-btn me-3"
                        onClick={() => handlePay(tutorial.amount, tutorial.id)}
                      >
                        Pay
                      </button>
                    ) : (
                      <button className="tutorial-pay-btn me-3" disabled>
                        Paid
                      </button>
                    )}
                    {tutorial.paid === "Y" ? (
                      <a href={tutorial.join_meeting}>
                        <button className=" tutorial-join-btn">
                          Join Tutorial
                        </button>
                      </a>
                    ) : (
                      <button className=" tutorial-join-disabled" disabled>
                        Join Tutorial
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Pagination */}
      <div className="pagination-container d-flex mt-3">
        <Pagination className="ms-auto" aria-disabled>
          {Array.from(
            Array(Math.ceil(allTutorials.length / tutorialsPerPage)),
            (e, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>
      </div>
      <PayInfoModal
        show={showPayInfo}
        handleClose={() => setShowPayInfo(false)}
        price={price}
        selectedTutorial={selectedTutorial}
        reloadData={reloadData}
      />
      <RequestTutModal />
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};

export default AllTutorial;
