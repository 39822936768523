import quizIcon from "../../../../assets/img/Quiz.jpg";
import QuizInfo from "../QuizInfo";
import QuizModal from "../QuizModal";
import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast } from "react-toastify";

const Quizresources = ({ courseId }) => {
  const [showQuizInfo, setShowQuizInfo] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState([]);
  const [currentTopic, setcurrentTopic] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`viewQuizByCourse/${courseId}`);
        const resp = response.data;

        if (
          resp.message === "" ||
          resp.message === null ||
          resp.message === "Quiz questions not found for this course."
        ) {
          setQuiz([]);
        } else {
          setQuiz(resp.quiz_questions);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId]);

  const openQuizInfo = () => {
    setShowQuizInfo(true);
  };
  const closeQuizInfo = () => {
    setShowQuizInfo(false);
  };
  const openQuizModal = () => {
    closeQuizInfo();
    setShowQuizModal(true);
  };
  const closeQuizModal = () => {
    setShowQuizModal(false);
  };

  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 ">
      {loading ? (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-blue"
            role="status"
          ></span>
        </div>
      ) : (
        <>
          {quiz.length === 0 ? (
            <div className="text-center">
              <p>No resource available</p>
            </div>
          ) : (
            <>
              {quiz.map((quiz) => (
                <div className="col" key={quiz.topic_id}>
                  <div
                    className="card resource-card border-0"
                    onClick={(e) => {
                      e.preventDefault();
                      openQuizInfo(quiz.quiz);
                      setCurrentQuiz(quiz.quiz);
                      setcurrentTopic(quiz.topic);
                    }}
                    role="button"
                  >
                    <img src={quizIcon} className="card-img-top" alt="" />
                    <div className="card-img-overlay">
                      <h4 className="viga-24-400 text-white">{quiz.topic}</h4>
                      {/* <h5 className="viga-16-400 my-course-notename">
                        {quiz.course_id}
                      </h5> */}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{quiz.topic}</h5>
                      {/* <div className="footnote-grey">
                        <span className="lato-12-400">{quiz.pages}</span>
                        <span className="px-2 bi bi-dot"></span>
                        <span className="lato-12-400">{quiz.type}</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}

      <QuizInfo
        show={showQuizInfo}
        handleClose={closeQuizInfo}
        openQuizModal={openQuizModal}
      />
      <QuizModal
        show={showQuizModal}
        handleClose={closeQuizModal}
        currentQuiz={currentQuiz}
        topic={currentTopic}
      />
    </div>
  );
};

export default Quizresources;
