import { useLayoutEffect, useState, useEffect } from "react";
import blueLogo from "../../assets/img/blueLogo.svg";
import OrgMobileSidebar from "./OrgMobileSidebar";
import { useNavigate } from "react-router-dom";
import UnverifiedOrg from "../../components/UnverifiedOrg";

const OrgHeader = () => {
  const orgID = sessionStorage.getItem("orgID");
  const orgStatus = sessionStorage.getItem("orgStatus");
  const name = sessionStorage.getItem("orgName");
  const orgLogo = sessionStorage.getItem("orgLogo");
  const navigate = useNavigate();
  useEffect(() => {
    if (orgID === "" || orgID === null) {
      navigate("/login");
    }
  });
  const getCurrentDate = () => {
    const date = new Date();
    const options = { month: "long", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const currentDate = getCurrentDate();

  const [orgVerification, setorgVerification] = useState(true);
  useLayoutEffect(() => {
    if (orgStatus === "1") {
      setorgVerification(false);
    } else if (orgStatus === "0") {
      setorgVerification(true);
    }
  }, [orgStatus]);

  return (
    <div className="container-fluid bg-white border-0 px-3 p-md-3 tutor-header sticky-top mb-0 mb-md-3">
      <div className="d-flex align-items-center py-3">
        <div className="logo d-block d-md-none pe-2">
          <img src={blueLogo} alt="Logo" />
        </div>
        <div className="d-block">
          <h3 className="viga-24-400 user-welcome m-0">Welcome, {name}</h3>
        </div>

        <div
          className="d-flex ms-auto align-items-center"
          style={{ width: "60px", height: "60px" }}
        >
          <img className="img-fluid" src={orgLogo} alt="logo" />
        </div>
        {/* Mobile Nav toggle */}
        <OrgMobileSidebar />
      </div>
      <div className="d-none d-md-flex">
        <span className="date ms-auto text-grey">{currentDate}</span>
      </div>
      <UnverifiedOrg show={orgVerification} />
    </div>
  );
};

export default OrgHeader;
