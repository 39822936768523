import OrgSidebar from "../OrgSidebar";
import LogoutModal from "../../../components/LogoutModal";
import OrgHeader from "../OrgHeader";
import MobileOrgNav from "../../../components/MobileOrgNav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";

const OrgQuizScores = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const location = useLocation();
  const courseName = new URLSearchParams(location.search).get("title");
  const orgId = sessionStorage.getItem("orgID");
  const [scores, setScores] = useState([]);
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const data = await axios.get(`OrgViewQuizScore/${orgId}/${courseId}`);
        const courses = data.data.results;
        setScores(courses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [orgId, courseId]);
  return (
    <div>
      <section className="container-fluid p-0">
        <OrgSidebar />
        <LogoutModal />
        <div className="main-content p-1 p-md-3 tutorTutorial">
          <OrgHeader />
          <div className="container-fluid">
            <header className="course-header ps-1">
              <button
                className="btn p-0 mb-1 text-grey lato-24-600"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
              <div className="d-flex">
                <h3 className="lato-24-600">Quiz Feedback</h3>
                <p className="lato-20-600 ms-auto">{courseName}</p>
              </div>
            </header>
            <section>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Score</th>
                    <th>Total Question</th>
                  </tr>
                </thead>
                <tbody>
                  {scores.length > 0 &&
                    scores.map((score) => (
                      <tr key={score.id}>
                        <td>{score.name}</td>
                        <td>{score.desc}</td>
                        <td>{score.score}</td>
                        <td>{score.is_restricted ? "Private" : "Public"}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </section>
          </div>
        </div>
        <MobileOrgNav />
      </section>
    </div>
  );
};

export default OrgQuizScores;
