import React, { useState } from "react";
import OrgSidebar from "../OrgSidebar";
import LogoutModal from "../../../components/LogoutModal";
import OrgHeader from "../OrgHeader";
import MobileOrgNav from "../../../components/MobileOrgNav";
import OrgWalletActivities from "./OrgWalletActivities";
import OrgWalletCard from "./OrgWalletCard";
import { ToastContainer, Zoom } from "react-toastify";
import OrgWithdrawal from "./OrgWithdrawal";
const OrgWallet = () => {
  const [WithdrawModal, setWithdrawModal] = useState(false);
  const closeWithdrawModal = () => setWithdrawModal(false);
  return (
    <div>
      <section className="container-fluid p-0">
        <OrgSidebar />
        <LogoutModal />
        <div className="main-content p-1 p-md-3 tutorTutorial">
          <OrgHeader />
          <div className="d-flex mb-3 px-3">
            <button
              className="ms-auto blue-filled-btn"
              onClick={() => setWithdrawModal(true)}
            >
              Make Withdrawal
            </button>
            <OrgWithdrawal
              show={WithdrawModal}
              handleClose={closeWithdrawModal}
            />
          </div>
          <div className="p-3">
            <OrgWalletCard />
            <OrgWalletActivities />
          </div>
        </div>
        <MobileOrgNav />
      </section>
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};

export default OrgWallet;