import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserSuccess from "../SuccessPage/UserSuccess";
import { ToastContainer, Zoom, toast } from "react-toastify";
import axios from "../../../api/axios";
const UserSetupForm = () => {
  const refID = sessionStorage.getItem("referralID");
  const navigate = useNavigate();
  const [successModal, setsuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [University, setUniversity] = useState([]);
  const [country, setCountry] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [isStudent, setIsStudent] = useState("setup-active");
  const [isLMS, setIsLMS] = useState("setup-inactive");
  const [validPin, setValidPin] = useState(true);
  const [formData, setFormData] = useState({
    program: "",
    school: "",
    country: "",
    level: "",
    dob: "",
    nickname: "",
    gender: "",
    referral_by: refID,
    org_id: "1",
    org_pin: "1234",
  });

  const closesuccessModal = () => setsuccessModal(false);
  useEffect(() => {
    const getDepartments = async () => {
      try {
        const response = await axios.get(`Department`);
        const departments = response.data.data;
        departments.sort((a, b) => a.Department.localeCompare(b.Department));
        setDepartments(departments);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    const getUniversity = async () => {
      try {
        const response = await axios.get(`schools`);
        const University = response.data.data;
        setUniversity(University);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    const getOrgs = async () => {
      try {
        const response = await axios.get(`admin/organizations/all`);
        const University = response.data.organizations;
        setOrgs(University);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    const getCountry = async () => {
      try {
        const response = await axios.get(`country`);
        const Country = response.data;
        setCountry(Country);
      } catch (error) {
        console.error("Failed to fetch country data:", error);
      }
    };
    getOrgs();
    getCountry();
    getUniversity();
    getDepartments();
  }, [navigate]);

  useEffect(() => {
    if (formData.org_id === "1" && formData.org_pin === "1234") {
      setValidPin(false);
    }
  }, [formData.org_id, formData.org_pin]);
  const slides1 = [
    {
      label: "Slide 1",
      fields: [
        {
          label: "What should we call you?",
          name: "nickname",
          type: "text",
          id: 1,
        },
        {
          label: "Country",
          name: "country",
          type: "select",
          options: [],
          id: 2,
        },
        {
          label: "Organization Name",
          name: "org_id",
          type: "select",
          options: [],
          id: 3,
        },
        {
          label: "Organization Password",
          name: "org_pin",
          type: "password",
          id: 4,
        },
      ],
      id: 1,
    },
    {
      label: "Slide 2",
      fields: [
        {
          label: "What is your date of birth?",
          name: "dob",
          type: "date",
          id: 2,
        },
        {
          label: "What is your Gender?",
          name: "gender",
          type: "radio",
          options: ["Male", "Female", "Other"],
          id: 3,
        },
        {
          label: "Enter Referral Code (Optional)",
          name: "referral_by",
          type: "ref",
          id: 4,
        },
      ],
      id: 2,
    },
  ];
  const slides2 = [
    {
      label: "Slide 1",
      fields: [
        {
          label: "What is your Programme of study",
          name: "program",
          type: "select",
          options: [
            "Accounting",
            "Anatomy",
            "Architecture",
            "Biology",
            "Banking and Finance",
            "BioChemistry",
            "Business Administration",
            "Civil Engineering",
            "Computer Engineering",
            "Computer Science",
            "Christian and Religious Studies",
            "Cyber Security",
            "Economics",
            "English",
            "Estate Management",
            "French",
            "Geology",
            "History and International Relations",
            "Industrial Chemistry",
            "Information Technology",
            "Insurance",
            "Law",
            "Mass Communication",
            "Marketing",
            "Mathematics",
            "Mechanical Engineering",
            "Microbiology",
            "Nursing Science",
            "Quantity Surveying",
            "Philosophy",
            "Physics and Electronics",
            "Physiology",
            "Physiotherapy",
            "Political Science",
            "Psychology",
            "Public Administration",
            "Sociology",
            "Statistics",
            "Theatre Arts",
            "Tourism",
            "Transport Management",
          ],
          id: 1,
        },
        {
          label: "Country",
          name: "country",
          type: "select",
          options: [],
          id: 2,
        },
        {
          label: "University",
          name: "school",
          type: "select",
          options: [],
          id: 3,
        },
        {
          label: "Organization Name",
          name: "org_id",
          type: "select",
          options: [],
          id: 4,
        },
        {
          label: "Organization Password",
          name: "org_pin",
          type: "password",
          id: 5,
        },
      ],
      id: 1,
    },
    {
      label: "Slide 2",
      fields: [
        {
          label: "What should we call you?",
          name: "nickname",
          type: "text",
          id: 1,
        },
        {
          label: "What is your date of birth?",
          name: "dob",
          type: "date",
          id: 2,
        },
        {
          label: "What is your Gender?",
          name: "gender",
          type: "radio",
          options: ["Male", "Female", "Other"],
          id: 3,
        },
        {
          label: "What is your level of study",
          name: "level",
          type: "select",
          options: ["100", "200", "300", "400", "500", "PG"],
        },
        {
          label: "Enter Referral Code (Optional)",
          name: "referral_by",
          type: "ref",
          id: 4,
        },
      ],
      id: 2,
    },
  ];
  const slides = isLMS === "setup-active" ? slides1 : slides2;
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };
  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      console.log(formData);
      const selectedDepartment = departments.find(
        (dept) => dept.Department === formData.program
      );
      console.log(selectedDepartment);
      if (selectedDepartment) {
        const formPayload = {
          ...formData,
          faculty: selectedDepartment.FaultyId,
        };
        console.log(formPayload);
        // Get current user email from sessionStorage
        const userID = sessionStorage.getItem("userID");
        // Push data to server
        axios
          .post(`stageOne/${userID}`, formPayload)
          .then((response) => {
            let resp = response.data.data;
            if (resp.status === "success") {
              toast.success("Account is active", {
                theme: "colored",
                autoClose: 3000,
              });
              setsuccessModal(true);
            } else {
              toast.error(resp.message, {
                theme: "colored",
                autoClose: 3000,
              });
            }
            setLoading(false);
          })
          .catch((err) => {
            toast.error("Error: " + err.message, {
              theme: "colored",
              autoClose: 3000,
            });
            setLoading(false);
          });
      } else {
        toast.error("Selected department not found.", {
          theme: "colored",
          autoClose: 3000,
        });
        setLoading(false);
      }
    }
  };

  const validateForm = () => {
    for (const slide of slides) {
      for (const field of slide.fields) {
        const fieldValue = formData[field.name];

        if (field.type === "select" && fieldValue === "") {
          toast.warning(`Please select an option for "${field.label}".`, {
            theme: "colored",
            autoClose: 3000,
          });
          return false;
        } else if (field.type === "orgSelect" && fieldValue === null) {
          toast.warning(`Please select an option for "${field.label}".`, {
            theme: "colored",
            autoClose: 3000,
          });
          return false;
        } else if (field.type === "date" && fieldValue === null) {
          toast.warning(`Please select a date for "${field.label}".`, {
            theme: "colored",
            autoClose: 3000,
          });
          return false;
        } else if (validPin === true) {
          toast.warning("The Organization pin is incorrect", {
            theme: "colored",
            autoClose: 3000,
          });
          return false;
        } else if (
          field.type !== "select" &&
          field.type !== "date" &&
          field.type !== "ref" &&
          fieldValue.trim() === ""
        ) {
          toast.warning(`Please fill in the "${field.label}" field.`, {
            theme: "colored",
            autoClose: 3000,
          });
          return false;
        }
      }
    }
    return true;
  };

  const handleStudents = () => {
    setIsStudent("setup-active");
    setIsLMS("setup-inactive");
    setCurrentSlide(0);
    // Set default org values for students
    setFormData((prev) => ({
      ...prev,
      program: "",
      school: "",
      country: "",
      level: "",
      dob: "",
      nickname: "",
      gender: "",
      org_id: "1",
      org_pin: "1234",
    }));
    setValidPin(false);
  };
  const handleLMS = () => {
    setIsStudent("setup-inactive");
    setIsLMS("setup-active");
    setCurrentSlide(0);
    // Reset org fields for LMS users
    setFormData((prev) => ({
      ...prev,
      program: "",
      school: "",
      country: "",
      level: "",
      dob: "",
      nickname: "",
      gender: "",
      org_id: "1",
      org_pin: "",
    }));
    setValidPin(true);
  };

  function validateAccessPin(event) {
    const inputPin = event.target.value;
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (inputPin.length === 4) {
      axios
        .get(`admin/checkpin/${formData.org_id}/${inputPin}`)
        .then((response) => {
          if (response.data.valid !== 1) {
            setValidPin(true);
            toast.error(
              "Incorrect organization pin, Please provide the correct pin",
              {
                theme: "colored",
                autoClose: 3000,
              }
            );
          } else if (response.data.valid === 1) {
            toast.success("Pin Success", {
              theme: "colored",
              autoClose: 3000,
            });
            setValidPin(false);
          }
        })
        .catch((error) => {
          toast.error("Error verifying organization pin" || error.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setValidPin(true);
        });
    }
  }

  return (
    <div className="carousel-form">
      <div className="d-flex mb-2 align-items-center pb-3">
        <div className="notification-header d-flex">
          <button className={isStudent} onClick={handleStudents}>
            Students & LMS
          </button>
          <button className={isLMS} onClick={handleLMS}>
            LMS Only
          </button>
        </div>
      </div>
      <div className="d-flex w-100 align-items-center justify-content-center mb-4 mb-md-5">
        <div className="indicators ps-4">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`indicator ${currentSlide === index ? "active" : ""}`}
              onClick={() => handleIndicatorClick(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className={isLMS === "setup-active" ? "slides1" : "slides2"}>
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${currentSlide === index ? "active" : ""}`}
          >
            {slide.fields.map((field) => {
              if (field.type === "select") {
                return (
                  <div className="d-block mb-4" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <select
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="form-select carousel-select"
                    >
                      {field.name === "program" && (
                        <>
                          <option value="">Select an option</option>
                          {departments.map((department) => (
                            <option
                              value={department.Department}
                              key={department.DepartmentId}
                            >
                              {department.Department}
                            </option>
                          ))}
                        </>
                      )}
                      {field.name === "school" && (
                        <>
                          <option value="">Select an option</option>
                          {University.map((university) => (
                            <option value={university.id} key={university.id}>
                              {university.schoolName}
                            </option>
                          ))}
                        </>
                      )}
                      {field.name === "country" && (
                        <>
                          <option value="">Select an option</option>
                          {country.map((country) => (
                            <option value={country.id} key={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </>
                      )}
                      {field.name === "org_id" &&
                        orgs.map((orgs) => (
                          <option value={orgs.id} key={orgs.id}>
                            {orgs.name}
                          </option>
                        ))}
                      {field.name === "level" && (
                        <>
                          <option value="">Select an option</option>
                          {field.options.map((option) => (
                            <option value={option} key={option}>
                              {option}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                );
              } else if (field.type === "date") {
                return (
                  <div className="d-block mb-4" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      type="date"
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                );
              } else if (field.type === "radio") {
                return (
                  <div className="mb-5" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <div key={field.name} className="d-flex">
                      {field.options.map((option) => (
                        <div className="form-check px-4" key={option}>
                          <input
                            type="radio"
                            name={field.name}
                            value={option}
                            checked={formData[field.name] === option}
                            onChange={handleInputChange}
                            className="form-check-input"
                          />
                          {option}
                          <label
                            className="form-check-label"
                            key={option}
                          ></label>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              } else if (field.type === "ref") {
                return (
                  <div className="d-block mb-5" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      disabled={refID !== ""}
                      type="text"
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="text-input ps-2"
                    />
                  </div>
                );
              } else if (field.type === "password") {
                return (
                  <div className="d-block mb-5" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      type="password"
                      name={field.name}
                      value={formData[field.name]}
                      onChange={validateAccessPin}
                      className="text-input ps-2"
                      maxLength={4}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="d-block mb-5" key={field.name}>
                    <h2 className="poppins-20-500 text-grey">{field.label}</h2>
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleInputChange}
                      className="text-input ps-2"
                    />
                  </div>
                );
              }
            })}
          </div>
        ))}
      </div>

      {currentSlide === slides.length - 1 && (
        <button
          type="submit"
          disabled={loading}
          className="submit-button"
          onClick={handleSubmit}
        >
          {loading ? "Loading..." : "Join Now"}
        </button>
      )}
      {currentSlide < slides.length - 1 && (
        <button className="next-button" onClick={handleNextSlide}>
          Move on
        </button>
      )}
      <ToastContainer transition={Zoom}></ToastContainer>
      <UserSuccess show={successModal} handleClose={closesuccessModal} />
    </div>
  );
};

export default UserSetupForm;
