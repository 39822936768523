import { useEffect, useState } from "react";
import ProfileModal from "../../components/ProfileModal";
import blueLogo from "../../assets/img/blueLogo.svg";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import { profile } from "../../features/userSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import FreeTrialModal from "../../components/FreeTrialModal";

const getCurrentDate = () => {
  const date = new Date();
  const options = { month: "long", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const DashboardHeader = () => {
  const navigate = useNavigate();
  const currentDate = getCurrentDate();
  const dispatch = useDispatch();
  const userID = sessionStorage.getItem("userID");
  const [loading, setLoading] = useState(true);
  const [freeTrialModal, setFreeTrialModal] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`ShowProfile/${userID}`);
        const userdata = response.data.data.UserRecord;

        dispatch(profile(userdata));
        sessionStorage.setItem("username", userdata.FullName);
        sessionStorage.setItem("nickname", userdata.NickName);
        sessionStorage.setItem("userEmail", userdata.EmailAddress);
        sessionStorage.setItem("userPhone", userdata.PhoneNumber);
        sessionStorage.setItem("userPic", userdata.ProfilePicName);
        sessionStorage.setItem("userPicFile", userdata.ProfilePic);
        sessionStorage.setItem("userSchool", userdata.SchoolID);
        sessionStorage.setItem("userDepartment", userdata.DepartmentID);
        sessionStorage.setItem("userFaculty", userdata.FaultyID);
        sessionStorage.setItem("userRef", userdata.UserReference);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (!userID) {
      navigate("/login");
    } else {
      fetchUserData();
    }
  }, [userID, navigate, dispatch]);

  const username = sessionStorage.getItem("username");
  const nickname = sessionStorage.getItem("nickname");
  const useremail = sessionStorage.getItem("userEmail");
  const userPhone = sessionStorage.getItem("userPhone");
  const userPic = sessionStorage.getItem("userPic");
  const userPlan = sessionStorage.getItem("userPlan");

  const subdays = sessionStorage.getItem("userSubDays");

  // Inside your component:
  const location = useLocation();

  useEffect(() => {
    const subDaysNumber = Number(subdays);
    if (
      location.pathname === "/courses" &&
      userPlan === "Free" &&
      subDaysNumber > 0
    ) {
      setFreeTrialModal(true);
    }
  }, [userPlan, subdays, location]);

  return (
    <div className="container-fluid bg-white border-0 p-md-3 dashboard-header sticky-top mb-md-4">
      <div className="d-flex align-items-center">
        <div className="logo pe-2">
          <img src={blueLogo} className="d-block d-md-none" alt="" />
        </div>
        {loading ? (
          <div className="d-flex me-auto align-items-center">
            <h3 className="viga-24-400 user-welcome m-0">Welcome,</h3>
            <span className="spinner-border spinner-border-sm text-blue"></span>
          </div>
        ) : (
          <div className="d-block me-auto">
            <h3 className="viga-24-400 user-welcome m-0">
              Welcome, {nickname}
            </h3>
            <span className="lato-16-400 text-grey">
              Active Subscription : {sessionStorage.getItem("userPlan")}
            </span>
          </div>
        )}

        <div className="d-none d-md-none d-lg-block">
          <div className="d-flex align-items-center">
            <Link to="/notifications">
              <i className="bi bi-bell pe-4 fs-5"></i>
            </Link>
            <span className="user-name pe-2">{nickname}</span>
            <ProfileModal
              useremail={useremail}
              username={username}
              userPhone={userPhone}
              nickname={nickname}
              userPic={userPic}
            />
          </div>
          <div className="d-none d-md-flex">
            <span className="date ms-auto pe-3 lato-16-400 text-grey">
              {currentDate}
            </span>
          </div>
        </div>

        <div className="d-flex d-lg-none align-items-center">
          <Link to="/notifications">
            <i className="bi bi-bell pe-4 fs-5"></i>
          </Link>
          <Link to="/userprofileinfo">
            <img src={userPic} className="profile-pic-sm" alt="user-icon" />
          </Link>
        </div>
      </div>
      <FreeTrialModal
        show={freeTrialModal}
        handleClose={() => setFreeTrialModal(false)}
      />
    </div>
  );
};

export default DashboardHeader;
