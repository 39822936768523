import DashboardSidebar from "../DashboardSidebar";
import LogoutModal from "../../../components/LogoutModal";
import DashboardHeader from "../DashboardHeader";
import MobileNav from "../../../components/MobileNav";
import mylogo from "../../../assets/Company_logo.png";
import otherlogo from "../../../assets/company_logo2.png";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const UserLms = () => {
  const userId = sessionStorage.getItem("userID");
  const [loading, setLoading] = useState(true);
  const [activeOrgs, setActiveOrgs] = useState([]);
  const [otherOrgs, setOtherOrgs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`student/viewOrg/${userId}`);
        const resp = response.data;

        if (!resp.organizations || resp.organizations.length === 0) {
          setActiveOrgs([]);
          setOtherOrgs([]);
        } else {
          const active = resp.organizations.filter(
            (org) => org.tag === "active"
          );
          const others = resp.organizations.filter(
            (org) => org.tag !== "active"
          );
          setActiveOrgs(active);
          setOtherOrgs(others);
        }
      } catch (error) {
        toast.error("Error fetching organizations");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content px-2 px-md-4">
        <DashboardHeader />
        <section className="px-2 px-md-3">
          <header className="viga-20-400 mb-2">My Organisation</header>
          <div className="row row-cols-1 row-cols-md-3 g-3">
            {loading ? (
              <div className="text-center">
                <span
                  className="spinner-border spinner-border-sm text-blue"
                  role="status"
                ></span>
              </div>
            ) : (
              activeOrgs.map((org) => (
                <div className="col" key={org.id}>
                  <div className="my-org-card">
                    <div
                      style={{
                        height: "100px",
                        width: "100px",
                      }}
                    >
                      <img
                        className="img-fluid"
                        src={org.banner || mylogo}
                        alt=""
                      />
                    </div>
                    <h5 className="lato-18-700">{org.name}</h5>
                    <p className="lato-14-400">{org.description}</p>
                    {/* <Link to={`/userlms/${org.id}?name=${org.name}`}> */}
                    <button className="btn btn-dark w-100">View</button>
                    {/* </Link> */}
                  </div>
                </div>
              ))
            )}
          </div>

          <header className="viga-20-400 my-2">Other Organisations</header>
          <div className="row row-cols-1 row-cols-md-3 g-3">
            {otherOrgs.map((org) => (
              <div className="col" key={org.id}>
                <div className="other-org-card shadow">
                  <div
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={org.banner || otherlogo}
                      alt=""
                    />
                  </div>
                  <h5 className="lato-18-700">{org.name}</h5>
                  <p className="lato-14-400">{org.description}</p>
                  {/* <Link to={`/userlms/${org.id}?name=${org.name}`}> */}
                  <button className="other-org-btn">View</button>
                  {/* </Link> */}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <MobileNav />
    </div>
  );
};

export default UserLms;
