import { Modal, Button } from "react-bootstrap";

const DeeletePostModal = ({
  show,
  handleClose,
  handleConfirmDelete,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Announcement</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this announcement?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleConfirmDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeeletePostModal;
