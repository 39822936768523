import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const AdminMobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <section className="d-block d-md-block d-lg-none ms-3">
      <FontAwesomeIcon
        icon={faBars}
        className=" fs-4"
        role="button"
        onClick={toggleSidebar}
      />
      {/* <button className="toggle-button" onClick={toggleSidebar}>
            Toggle
          </button> */}
      <div className={`sidebar-sm ${isOpen ? "open" : ""}`}>
        <nav className="navbar">
          <NavLink to="/admin" className="navactive py-2">
            <div className="nav-item d-flex">
              <i className="bi bi-grid-fill nav-icon"></i>
              Finances
            </div>
          </NavLink>
          <NavLink to="/adminresource" className="navactive py-2">
            <div className="nav-item d-flex">
              <i className="bi bi-file-bar-graph nav-icon"></i>
              Resources
            </div>
          </NavLink>
          <NavLink to="/admintutorial" className="navactive py-2">
            <div className="nav-item d-flex">
              <i className="bi bi-people nav-icon"></i>
              Tutorials
            </div>
          </NavLink>
          <NavLink to="/admintutor" className="navactive py-2">
            <div className="nav-item d-flex">
              <i className="bi bi-clipboard nav-icon"></i>
              Tutors
            </div>
          </NavLink>
          <NavLink to="/adminstudent" className="navactive py-2">
            <div className="nav-item d-flex">
              <i className="bi bi-building nav-icon"></i>
              Students
            </div>
          </NavLink>
          <NavLink to="/adminorg" className="navactive py-2">
            <div className="nav-item d-flex">
              <i className="bi bi-building nav-icon"></i>
              LMS
            </div>
          </NavLink>
          <NavLink to="/" className="navactive py-2">
            <div className="nav-item d-flex">
              <i className="bi bi-bell nav-icon"></i>
              Notifications
            </div>
          </NavLink>
        </nav>
      </div>
    </section>
  );
};

export default AdminMobileSidebar;
