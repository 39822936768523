import React, { useState } from "react";
import AdminSidebar from "../AdminSidebar";
import LogoutModal from "../../../components/LogoutModal";
import DupAdminHeader from "../DupAdminHeader";
import AdminMobileSidebar from "../AdminMobileSidebar";
import AdminPostContainer from "./AdminPostContainer";
import AdminScholarshipContainer from "./AdminScholarshipContainer";

const AdminPostPage = () => {
  const [isNotifications, setisNotifications] = useState(true);
  const [isScholarships, setisScholarships] = useState(false);
  const [content, setContent] = useState(<AdminPostContainer />);
  const handleAnnouncements = () => {
    setisNotifications(true);
    setisScholarships(false);
    setContent(<AdminPostContainer />);
  };
  const handleScholarships = () => {
    setisNotifications(false);
    setisScholarships(true);
    setContent(<AdminScholarshipContainer />);
  };
  const allPostBtn = isNotifications
    ? "notification-active"
    : "notification-inactive";
  const pubPostBtn = isScholarships
    ? "notification-active"
    : "notification-inactive";

  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <DupAdminHeader />
        <div className="d-flex">
          <div className="notification-header d-flex m-auto mb-4">
            <button className={allPostBtn} onClick={handleAnnouncements}>
              Announcement
            </button>
            <button className={pubPostBtn} onClick={handleScholarships}>
              Scholarships
            </button>
          </div>
        </div>
        {content}
      </div>
      <AdminMobileSidebar />
    </section>
  );
};

export default AdminPostPage;
