import "./userDashboard.css";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import MobileNav from "../../components/MobileNav";
import LogoutModal from "../../components/LogoutModal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { ToastContainer, Zoom } from "react-toastify";
import Feeds from "./Dashboard/Feeds/Feeds";
const UserDashboard = () => {
  const navigate = useNavigate();
  const userID = sessionStorage.getItem("userID");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (userID === "" || userID === null) {
      navigate("/login");
    }
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`UserDashboard/${userID}`);
        const userdata = response.data.data.UserRecord;
        console.log(userdata);
        sessionStorage.setItem(
          "userPlan",
          userdata.UserProfile.SubscriptionPlan
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [userID, navigate]);

  return (
    <div className="container-fluid p-0">
      {/* {loading ? (
        <div className="preloader-container text-center d-flex justify-content-center">
          <div className="spinner-grow text-blue  my-auto " role="status"></div>
        </div>
      ) : ( */}
        <>
          <DashboardSidebar />
          <LogoutModal />
          <div className="main-content px-2 px-md-4 userDashboard">
            <DashboardHeader />
            <Feeds />
          </div>
        </>
      {/* )} */}
      <MobileNav />
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};

export default UserDashboard;
