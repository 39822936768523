import React, { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast, ToastContainer, Zoom } from "react-toastify";
import videoIcon from "../../../../assets/Videoresources.png";
import { Link } from "react-router-dom";
import OrgCreateVideo from "./OrgCreateVideo";

const OrgCourseVideos = ({ courseId }) => {
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const orgId = sessionStorage.getItem("orgID");
  const [courseModal, setCourseModal] = useState(false);

  const openModal = () => setCourseModal(true);
  const closeModal = () => setCourseModal(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`Org/viewVideo/${orgId}/${courseId}`);
        const resp = response.data;
        console.log(resp.message);

        if (
          resp.message === "" ||
          resp.message === "No record Found" ||
          resp.message === "no record found" ||
          resp.message === null
        ) {
          setVideos([]);
        } else {
          setVideos(resp.videos);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId, orgId]);
  return (
    <div>
      <header className="d-flex">
        <button className="blue-filled-btn ms-auto" onClick={openModal}>
          Add New Video
        </button>
      </header>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 mt-2">
        {loading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm text-blue"
              role="status"
            ></span>
          </div>
        ) : videos.length === 0 ? (
          <div className="text-center">
            <p>No resource available</p>
          </div>
        ) : (
          videos.map(
            (video) =>
              video !== null && (
                <div className="col" key={video.id}>
                  <div className="card resource-card border-0">
                    <Link
                      to={`/courses/coursepage/resourcepage/document/${encodeURIComponent(
                        video.NoteUrl
                      )}`}
                      className="text-decoration-none text-dark"
                      onClick={() => {
                        sessionStorage.setItem("activeResourceID", video.id);
                        sessionStorage.setItem("activeResourceType", "video");
                      }}
                    >
                      <img src={videoIcon} className="card-img-top" alt="" />
                    </Link>

                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <h6 className="card-title">{video.title}</h6>
                      </div>
                      <div className="footnote-grey">
                        <div className="d-flex">
                          <div className="viga-14-400">{video.year}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )
        )}
      </div>

      <ToastContainer transition={Zoom}></ToastContainer>
      <OrgCreateVideo
        show={courseModal}
        handleClose={closeModal}
        courseId={courseId}
      />
    </div>
  );
};

export default OrgCourseVideos;
