import React from "react";
import whiteLogo from "../../../assets/img/whiteLogo.svg";
import blueLogo from "../../../assets/img/blueLogo.svg";
import UserSetupForm from "./UserSetupForm";

const AcctSetup = () => {
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-5 setup-left">
          <img
            src={whiteLogo}
            alt="Logo"
            className=" logo d-none d-md-block d-lg-block"
          />
          <img
            src={blueLogo}
            alt="Logo"
            className="logo d-block d-md-none d-lg-none"
          />
          <div className="mt-auto mb-3">
            <div className="d-block ps-5">
              <h3 className="viga-24-400 text-white">ETC Vibes</h3>
              <p className="text-white w-75">
                providing you with everything required to make your striaght As!
                on a single platform..
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-7  p-2 p-md-5 m-auto">
          <div className="d-flex justify-content-center d-flex d-md-none">
            <img src={blueLogo} className="logo " alt="Logo" />
          </div>
          <h2 className="viga-24-400 text-blue text-center">
            Let’s get you started
          </h2>
          <div className="container d-flex justify-content-center my-4">
            {/* <CarouselContent /> */}
            <UserSetupForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcctSetup;
