import React, { useState } from "react";
import DashboardSidebar from "../pages/UserDashboard/DashboardSidebar";
import DashboardHeader from "../pages/UserDashboard/DashboardHeader";
import CGPAcomponent from "../pages/UserDashboard/Dashboard/CGPAcomponent";
import LogoutModal from "./LogoutModal";
import MobileNav from "./MobileNav";
import { ToastContainer, Zoom, toast } from "react-toastify";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const userID = sessionStorage.getItem("userID");
  const initialProfile = {
    username: sessionStorage.getItem("username"),
    nickname: sessionStorage.getItem("nickname"),
    useremail: sessionStorage.getItem("userEmail"),
    userPhone: sessionStorage.getItem("userPhone"),
    userPic: sessionStorage.getItem("userPic"),
    userRef: sessionStorage.getItem("userRef"),
  };

  const [profile, setProfile] = useState(initialProfile);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const navigate = useNavigate();


  const handleToggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSaveProfile = () => {
    const formData = new FormData();
    formData.append("NickName", profile.nickname);
    formData.append("PhoneNumber", profile.userPhone);

    if (profilePicFile) {
      formData.append("ProfilePic", profilePicFile);
    } else {
      formData.append("ProfilePic", profile.userPic);
    }

    setSaving(true);

    axios
      .post(`UpdateProfile/${userID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        let resp = response.data.data;
        if (resp.status === "success") {
          console.log(resp.message);
          toast.success("Profile Updated", {
            theme: "colored",
            autoClose: 2000,
          });
          setProfile({
            ...profile,
            userPic: resp.UserRecord.ProfilePicName,
            nickname: resp.UserRecord.NickName,
            userPhone: resp.UserRecord.PhoneNumber,
          });
          setProfilePicFile(null);
          setSaving(false);
          setEditMode(false);
        } else {
          toast.error(resp.message, {
            theme: "colored",
            autoClose: 3000,
          });
          setSaving(false);
        }
      })
      .catch((err) => {
        toast.error("Edit failed: " + err.message, {
          theme: "colored",
          autoClose: 3000,
        });
        setSaving(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleProfilePicChange = (e) => {
    setProfilePicFile(e.target.files[0]);
  };

  const [buttonText, setButtonText] = useState("Copy Link");

  const handleCopyLink = () => {
    const link = `https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setButtonText("Link Copied");
        setTimeout(() => {
          setButtonText("Copy Link");
        }, 3000);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };

  const generateWhatsAppLink = () => {
    const message = `Join ETC Vibes using my referral code: ${profile.userRef}. Sign up here: https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    return `https://wa.me/?text=${encodeURIComponent(message)}`;
  };

  const generateFacebookLink = () => {
    const url = `https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
  };

  const generateTwitterLink = () => {
    const message = `Join ETC Vibes using my referral code: ${profile.userRef}. Sign up here:`;
    const url = `https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      message
    )}&url=${encodeURIComponent(url)}`;
  };

  const generateLinkedInLink = () => {
    const url = `https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`;
    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`;
  };
  return (
    <section className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 userDashboard">
        <DashboardHeader />
        <div className="p-4">
          <button
            className=" d-block d-lg-none btn p-0 mb-1 text-grey lato-24-600"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="bi bi-chevron-left"></i>
          </button>
          <h2 className="viga-24-400">My Profile</h2>
          <div className="row">
            {/* Profile Picture Column */}
            <div className="col-12 col-lg-4">
              <div className="mb-3">
                <img
                  src={profile.userPic}
                  className="profile-pic-lg mb-2"
                  alt="Profile pic"
                />
                {editMode ? (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfilePicChange}
                    className="form-control w-50"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* First Info Column */}
            <div className="col-12 col-lg-4">
              {/* Full Name */}
              <div className="d-flex mb-2">
                <i className="bi bi-person-circle profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Full Name</p>
                  <span className="profile-name lato-20-600">
                    {profile.username}
                  </span>
                </div>
              </div>

              {/* Phone Number */}
              <div className="d-flex mb-2">
                <i className="bi bi-telephone profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Phone Number</p>
                  {editMode ? (
                    <input
                      type="text"
                      name="userPhone"
                      className="form-control"
                      value={profile.userPhone}
                      onChange={handleChange}
                    />
                  ) : (
                    <span className="profile-name lato-20-600">
                      {profile.userPhone}
                    </span>
                  )}
                </div>
              </div>

              {/* Referral ID */}
              <div className="d-flex mb-2">
                <i className="bi bi-person-circle profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Referral ID</p>
                  <span className="profile-name lato-20-600">
                    {profile.userRef}
                  </span>
                </div>
              </div>
            </div>

            {/* Second Info Column */}
            {/* Second Info Column */}
            <div className="col-12 col-lg-4">
              {/* Email */}
              <div className="d-flex mb-2">
                <i className="bi bi-envelope profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Email Address</p>
                  <span className="profile-name lato-20-600">
                    {profile.useremail}
                  </span>
                </div>
              </div>

              {/* Nickname */}
              <div className="d-flex mb-3">
                <i className="bi bi-person-circle profile-grey me-2"></i>
                <div className="d-block">
                  <p className="profile-grey m-0">Nickname</p>
                  {editMode ? (
                    <input
                      type="text"
                      name="nickname"
                      className="form-control"
                      value={profile.nickname}
                      onChange={handleChange}
                    />
                  ) : (
                    <span className="profile-name lato-20-600">
                      {profile.nickname}
                    </span>
                  )}
                </div>
              </div>

              {/* Edit Buttons */}
              <div className="d-flex">
                {editMode ? (
                  <>
                    <button
                      className="blue-filled-btn ms-auto"
                      onClick={handleSaveProfile}
                      disabled={saving}
                    >
                      {saving ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></span>
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      className="grey-btn ms-2"
                      onClick={handleToggleEditMode}
                      disabled={saving}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    className="blue-filled-btn ms-auto"
                    onClick={handleToggleEditMode}
                  >
                    Edit Profile
                  </button>
                )}
              </div>
            </div>
          </div>

          <hr />
          {/* Referal Container */}
          <div className="referral-cgpa">
            <div className="card rounded shadow p-3 border-1 referral-container">
              <h4 className="viga-24-400">Refer a Friend and get reward !</h4>
              <p className="lato-20-400 text-grey">
                Share your referral link with friends.
              </p>
              <div className="referal-search">
                <input
                  type="text"
                  disabled
                  className="referal-input-bar"
                  placeholder={`https://etcvibes.com/createAccount/student?referral_by=${profile.userRef}`}
                />
                <button
                  onClick={handleCopyLink}
                  className="blue-filled-btn ms-auto text-nowrap"
                >
                  {buttonText}
                </button>
              </div>
              <p className="text-grey lato-20-400">Share your code via</p>
              <div className="d-flex referral-share-icons">
                <a
                  href={generateWhatsAppLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="bi bi-whatsapp pe-3 fs-3"
                    style={{ color: "grey" }}
                  ></i>
                </a>
                <a
                  href={generateFacebookLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="bi bi-facebook pe-3 fs-3"
                    style={{ color: "grey" }}
                  ></i>
                </a>
                <a
                  href={generateLinkedInLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="bi bi-linkedin pe-3 fs-3"
                    style={{ color: "grey" }}
                  ></i>
                </a>
                <a
                  href={generateTwitterLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="bi bi-twitter-x pe-3 fs-3"
                    style={{ color: "grey" }}
                  ></i>
                </a>
              </div>
            </div>
            <CGPAcomponent />
          </div>
        </div>
      </div>

      <MobileNav />
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default Profile;
