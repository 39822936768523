import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LogoutModal from "../../../components/LogoutModal";
import MobileNav from "../../../components/MobileNav";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const ScholarshipDetails = () => {
  const { id } = useParams(); // Get the scholarship ID from the URL
  const [scholarship, setScholarship] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchScholarshipDetails = async () => {
      try {
        const response = await axios.get(`/scholarships/${id}`);
        setScholarship(response.data);
      } catch (error) {
        toast.error("Error fetching scholarship details.", {
          theme: "colored",
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchScholarshipDetails();
  }, [id]);

  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content px-2 px-md-4">
        <DashboardHeader />
        {loading ? (
          <div className="container text-center mt-4">
            <span
              className="spinner-border spinner-border-lg"
              role="status"
            ></span>
          </div>
        ) : (
          <section className="px-4" style={{ marginBottom: "100px" }}>
            <h4 className="viga-24-400">{scholarship.title}</h4>
            <div className="container scholarship-container p-3">
              <p className="scholarship-info poppins-14-400">
                {scholarship.description}
              </p>

              <div className="scholarship-eligibility">
                <h4 className="scholarship-title poppins-14-700">
                  Eligibility:
                </h4>
                <ul className="poppins-14-400">
                  {scholarship.eligibility.split(". ").map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              <div className="scholarship-deadline">
                <h4 className="scholarship-title poppins-14-700">Deadline:</h4>
                <p className="poppins-14-400">{scholarship.deadline}</p>
              </div>

              <div className="d-flex mt-3">
                <p className="poppins-14-400">
                  Created At:{" "}
                  {new Date(scholarship.created_at).toLocaleDateString()}
                </p>
                <a
                  href={scholarship.apply_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ms-auto"
                >
                  <button className="blue-filled-btn">Apply Now</button>
                </a>
              </div>
            </div>
          </section>
        )}
      </div>
      <MobileNav />
    </div>
  );
};

export default ScholarshipDetails;
