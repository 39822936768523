import "../org.css";
import OrgHeader from "../OrgHeader";
import LogoutModal from "../../../components/LogoutModal";
import { useNavigate } from "react-router-dom";
import OrgSidebar from "../OrgSidebar";
import MobileOrgNav from "../../../components/MobileOrgNav";
import OrgHighlightCards from "./OrgHighlightCards";
import OrgCourseHighlights from "./OrgCourseHighlights";

const OrgDashboard = () => {
  // const [loading, setLoading] = useState(true);
  const orgID = sessionStorage.getItem("orgID");
  const navigate = useNavigate();
  if (!orgID) {
    navigate("/login");
  }
  return (
    <div className="container-fluid p-0">
      <OrgSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 orgDashboard ">
        <OrgHeader />
        <OrgHighlightCards />
        <OrgCourseHighlights />
      </div>
      <MobileOrgNav />
    </div>
  );
};

export default OrgDashboard;
