import bellIcon from "../../../assets/img/bell-ringing-04.png";
import cardCheck from "../../../assets/img/credit-card-check.png";
import coins from "../../../assets/img/coins-stacked.png";

const MyUpdates = ({ notifications }) => {
  const formatDate = (dateString) => {
    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };
  return (
    <div className="myupdates">
      <h4 className="viga-24-400">My Updates</h4>
      <section className="notification-wrapper py-2">
        {notifications.map((notification) => (
          <div className="d-flex notification-cell p-4" key={notification.id}>
            <div className="notification-icon">
              <img src={bellIcon} alt="" />
            </div>
            <div className="notification-body ms-3">
              <h4 className="viga-16-400">{notification.comment}</h4>
              <p className="lato-16-400">{notification.decription}</p>
              <div className="notification-date text-grey">
                <i className="bi bi-calendar pe-2"></i>
                {formatDate(notification.created_at)}
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default MyUpdates;
