import { Modal, ModalBody } from "react-bootstrap";
import { Link } from "react-router-dom";

const FreeTrialModal = ({ show, handleClose }) => {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className="container">
          <h2 className="viga-24-400 text-blue text-center mb-3">
            🔔 Important Notice 🔔
          </h2>
          <p className="text-danger poppins-15-400">Free Trial Notice</p>
          <p className="poppins-15-400">
            You're currently on a 3-day free trial. After that, you'll lose
            access unless you subscribe.
          </p>
          <Link to="/userWallet/walletsub">
          <button className="btn-blue px-4 py-2">Subscribe</button>
          </Link>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FreeTrialModal;
