import React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, Zoom, toast } from "react-toastify";
import api from "../../../api/axios";
import pdf from "../../../assets/ETC Privacy.pdf";
import GoogleSignin from "../GoogleSignin";
const StudentForm = () => {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get("referral_by");
  sessionStorage.setItem("referralID", ref || "");
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [isChecked, setIschecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const validateForm = () => {
    if (fullname.trim() === "") {
      toast.warning("Please enter your full name", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (email.trim() === "") {
      toast.warning("Please enter your email address", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password.trim() === "") {
      toast.warning("Please enter a password", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (phone.trim() === "") {
      toast.warning("Please enter a phone number", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password !== c_password) {
      toast.warning("Passwords do not match", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (password.length < 8) {
      toast.warning("Password should be at least 8 characters long", {
        theme: "colored",
        autoClose: 3000,
      });
      return false;
    }

    if (!password.match(/^(?=.*\d)(?=.*[@$!%-*?&_#~><])/)) {
      toast.warning(
        "Password should contain at least one letter, one number, and one special character",
        {
          theme: "colored",
          autoClose: 3000,
        }
      );
      return false;
    }

    return true;
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowcPassword = () => {
    setShowcPassword(!showcPassword);
  };

  const handlecheckbox = (e) => {
    setIschecked(!isChecked);
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const regobj = { fullname, email, phone, password };

      try {
        const response = await api.post("CreateUsers", regobj);

        setIsLoading(false);
        const userdata = response.data.data;
        sessionStorage.setItem("userID", userdata.UserId);
        sessionStorage.setItem("userEmail", userdata.Email);
        sessionStorage.setItem("userSubDays", userdata.subdays);
        navigate("/userverify");
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.data) {
          // If the error response contains a message key, display it
          if (error.response.data.message) {
            toast.error(error.response.data.message, {
              theme: "colored",
              autoClose: 3000,
            });
          }

          // If the error response contains errors key, display the first error
          if (error.response.data.errors) {
            const firstErrorKey = Object.keys(error.response.data.errors)[0];
            const firstError = error.response.data.errors[firstErrorKey][0];
            toast.error(firstError, {
              theme: "colored",
              autoClose: 3000,
            });
          }
        } else {
          // Display a generic error message if the response doesn't contain expected keys
          toast.error("Signup failed, error: " + error.message, {
            theme: "colored",
            autoClose: 3000,
          });
        }
      }
    }
  };

  return (
    <form
      action=""
      className="login-form text-center mt-5 mb-3"
      onSubmit={handleSignup}
    >
      <div className="mb-4 d-flex ">
        <input
          type="text"
          className="login-input"
          placeholder="Full Name"
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex ">
        <input
          type="email"
          className="login-input"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex ">
        <input
          type="number"
          className="login-input"
          placeholder="Phone number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="mb-4 d-flex">
        <input
          type={showPassword ? "text" : "password"}
          className="login-input"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowPassword}
        ></i>
      </div>
      <div className="mb-4 d-flex">
        <input
          type={showcPassword ? "text" : "password"}
          className="login-input"
          placeholder="Confirm Password"
          value={c_password}
          onChange={(e) => setCPassword(e.target.value)}
        />
        <i
          className={`c_pass bi ${
            showcPassword ? "bi-eye-slash-fill" : "bi-eye"
          } password-toggle`}
          onClick={toggleShowcPassword}
        ></i>
      </div>

      <div className="">
        <input type="checkbox" checked={isChecked} onChange={handlecheckbox} />
        <span>
          {" "}
          By signing up, I accept ETC Vibes'{" "}
          <a href={pdf} target="blank">
            Privacy Policy
          </a>
        </span>
      </div>
      <div className="mt-4">
        <button
          type="submit"
          className={`${isChecked ? "submit-btn" : "checked-btn"}`}
          disabled={isLoading || !isChecked}
        >
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          ) : (
            <span style={{ color: "white" }}>Create Account</span>
          )}
        </button>
      </div>
      <div className="ms-auto my-3 d-flex justify-content-center">
        <GoogleSignin />
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </form>
  );
};

export default StudentForm;
