import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";

const OrgCreateQuiz = ({ show, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [topic, setTopic] = useState("");
  const orgId = sessionStorage.getItem("orgID");
  const [courseId, setCourseId] = useState("");
  const [questionCards, setQuestionCards] = useState([
    {
      id: 1,
      question: "",
      answer: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    },
  ]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const courseData = await axios.get(`OrgViewCoursesCreated/${orgId}`);
        const courses = courseData.data.courses;
        setCourses(courses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [orgId]);

  const addQuestionCard = () => {
    setQuestionCards((prevCards) => [
      ...prevCards,
      {
        id: prevCards.length + 1,
        question: "",
        answer: "",
        option1: "",
        option2: "",
        option3: "",
      },
    ]);
  };
  const handleQuestionChange = (index, field, value) => {
    const updatedQuestionCards = [...questionCards];
    updatedQuestionCards[index][field] = value;
    setQuestionCards(updatedQuestionCards);
  };

  const removeQuestionCard = (index) => {
    setQuestionCards((prevCards) => prevCards.filter((_, i) => i !== index));
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const quiz_questions = questionCards.map(
      ({ question, answer, option1, option2, option3 }) => ({
        question,
        answer,
        option1,
        option2,
        option3,
      })
    );

    const obj = {
      quiz_questions: [
        {
          org_id: orgId,
          time: 15,
          course_id: courseId,
          topic,
          quiz: quiz_questions,
        },
      ],
    };
    console.log(quiz_questions);
    console.log(obj);
    try {
      await axios.post(`CreateQuiz/${orgId}/${courseId}`, obj);
      handleClose();
      toast.success("Quiz added successfully", {
        theme: "colored",
        autoClose: 2000,
      });
    } catch (error) {
      console.error("Error creating quiz:", error);
      toast.error("Failed to add quiz");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader closeButton>
        <h3 className="fw-bold text-blue">Add Quiz Details</h3>
      </ModalHeader>
      <ModalBody>
        <form className="container-fluid" onSubmit={submitForm}>
          <div className="row row-cols-1">
            <div className="col">
              <div className="mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Course
                </label>
                <select
                  className="resource-input"
                  onChange={(e) => setCourseId(e.target.value)}
                  value={courseId}
                >
                  <option value="">Select Course</option>
                  {courses.map(({ id, title }) => (
                    <option key={id} value={id}>
                      {title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Topic
                </label>
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  className="resource-input"
                />
              </div>
            </div>
            <div className="col">
              <div className="quiz-fixed-column">
                {questionCards.map((question, index) => (
                  <div className="card rounded p-3 mb-3" key={question.id}>
                    <div className="d-flex align-items-center">
                      <div className="me-auto">
                        <h6>
                          No. of Questions:{" "}
                          <span className="text-blue">{question.id}</span>
                        </h6>
                      </div>
                      <i
                        className="bi bi-trash text-danger fs-5"
                        onClick={() => removeQuestionCard(index)}
                      ></i>
                    </div>
                    <hr />
                    <div className="admin-quiz-questions">
                      <div className="mb-3">
                        <textarea
                          className="quiz-textbox"
                          cols="30"
                          rows="3"
                          placeholder="Enter Question"
                          value={question.question}
                          onChange={(e) =>
                            handleQuestionChange(
                              index,
                              "question",
                              e.target.value
                            )
                          }
                        ></textarea>
                      </div>
                      <div className="admin-quiz-answers">
                        {/* Option 1 */}
                        <div className="mb-3 quiz-option-container">
                          <input
                            type="text"
                            name=""
                            className="admin-quiz-option"
                            placeholder="Enter Option"
                            value={question.option1}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "option1",
                                e.target.value
                              )
                            }
                          />
                          <input
                            type="radio"
                            name={`answer${question.id}`}
                            value={question.option1}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                question.option1
                              )
                            }
                          />
                        </div>
                        {/* Option 2 */}
                        <div className="mb-3 quiz-option-container">
                          <input
                            type="text"
                            name=""
                            className="admin-quiz-option"
                            placeholder="Enter Option"
                            value={question.option2}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "option2",
                                e.target.value
                              )
                            }
                          />
                          <input
                            type="radio"
                            name={`answer${question.id}`}
                            value={question.option2}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                question.option2
                              )
                            }
                          />
                        </div>
                        {/* Option 3 */}
                        <div className="mb-3 quiz-option-container">
                          <input
                            type="text"
                            name=""
                            className="admin-quiz-option"
                            placeholder="Enter Option"
                            value={question.option3}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "option3",
                                e.target.value
                              )
                            }
                          />
                          <input
                            type="radio"
                            name={`answer${question.id}`}
                            value={question.option3}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                question.option3
                              )
                            }
                          />
                        </div>
                        {/* Option 4 */}
                        <div className="mb-3 quiz-option-container">
                          <input
                            type="text"
                            name=""
                            className="admin-quiz-option"
                            placeholder="Enter Option"
                            value={question.option4}
                            onChange={(e) =>
                              handleQuestionChange(
                                index,
                                "option4",
                                e.target.value
                              )
                            }
                          />
                          <input
                            type="radio"
                            name={`answer${question.id}`}
                            value={question.option4}
                            onChange={() =>
                              handleQuestionChange(
                                index,
                                "answer",
                                question.option4
                              )
                            }
                          />
                        </div>
                        <p className="text-center">Select the correct answer</p>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="blue-filled-btn text-center"
                  onClick={addQuestionCard}
                >
                  Add Question
                </button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex">
          <button onClick={handleClose} className="blue-outline-btn">
            Close
          </button>
          <button
            className="blue-filled-btn text-center ms-4"
            type="submit"
            onClick={submitForm}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
              ></span>
            ) : (
              <span>Upload Quiz</span>
            )}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default OrgCreateQuiz;
