import { useEffect, useState } from "react";
import pqIcon from "../../../../assets/img/Pastquestions solution.jpg";
import { toast } from "react-toastify";
import axios from "../../../../api/axios";
import { Link } from "react-router-dom";

const PqResources = ({ courseId }) => {
  const [Pastquestions, setPastquestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const userID = sessionStorage.getItem("userID");
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`pastquestions/${courseId}/${userID}`);
        const resp = response.data.data;
        console.log(resp.message);
        //
        // const getView = await axios.get(`Views/pastquestion/13`)
        // const ratingsview = await axios.get(`rate/pastquestion/{}`)

        if (
          resp.message === "" ||
          resp.message === "No content available, check the next resource" ||
          resp.message === "No record Found" ||
          resp.message === "no record found"
        ) {
          setPastquestions([]);
        } else {
          setPastquestions(resp.message);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId, userID]);

  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
      {loading ? (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-blue"
            role="status"
          ></span>
        </div>
      ) : (
        <>
          {Pastquestions.length === 0 ? (
            <div className="text-center">
              <p>No resource available</p>
            </div>
          ) : (
            <>
              {Pastquestions.map((textbook) => (
                <div className="col" key={textbook.id}>
                  <div className="card resource-card border-0">
                    <Link
                      to="/courses/coursepage/info"
                      state={{
                        pdfUrl: textbook.sourceurl,
                        id: textbook.id,
                        name: textbook.Name,
                        description: textbook.Description,
                        course: textbook.course,
                        author: textbook.author,
                        views: textbook.views,
                        rating: textbook.rating,
                        institution: textbook.institution,
                        resourceType: "pastquestion",
                        courseId: courseId,
                      }}
                      className="text-decoration-none text-dark"
                      onClick={() => {
                        sessionStorage.setItem("activeResourceID", textbook.id);
                        sessionStorage.setItem(
                          "activeResourceType",
                          "pastquestion"
                        );
                      }}
                    >
                      <img src={pqIcon} className="card-img-top" alt="" />
                    </Link>
                    <div className="rating-overlay">
                      <span className="text-white">
                        Rated {textbook.rating}
                      </span>
                      <span className="text-gold fs-5">★</span>
                    </div>
                    <div className="p-2">
                      <div className="d-flex justify-content-between gap-4">
                        <div>
                          <h6 className="viga-16-400 my-3">
                            {textbook.Description}
                          </h6>
                          <h6 className="card-title">{textbook.Name}</h6>
                        </div>
                        <div className="d-flex flex-column align-items-center my-3">
                          <i className="bi bi-eye"></i>
                          {textbook.views}
                        </div>
                      </div>
                      <div className="d-flex footnote-grey">
                        <div>
                          <h6 className="viga-14-400">
                            {/* Author:{" "} */}
                            {textbook.author == null
                              ? "ETC Vibes"
                              : textbook.author}
                          </h6>
                          <div className="viga-14-400">
                            {/* From: {textbook.institution} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PqResources;
