import DashboardSidebar from "../DashboardSidebar";
import LogoutModal from "../../../components/LogoutModal";
import MobileNav from "../../../components/MobileNav";
import DashboardHeader from "../DashboardHeader";
import HelpCenter from "./HelpCenter";
import { useNavigate } from "react-router-dom";

const Help = () => {
  const navigate = useNavigate();

  return (
    <section className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <MobileNav />
      {/* Main content */}
      <div className="main-content p-1">
        <DashboardHeader />
        <div className="container-fluid help-container bg-white px-4 mb-5 mb-md-2">
          <button
            className=" d-block d-lg-none btn p-0 mb-1 text-grey lato-24-600"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="bi bi-chevron-left"></i>
          </button>
          <div className="help-header text-center py-2">
            <span className="viga-24-400">Help Center</span>
            <a href="mailto:info@etcvibes.com">info@etcvibes.com</a>
          </div>
          <HelpCenter />
        </div>
      </div>
    </section>
  );
};

export default Help;
