import AdminSidebar from "../AdminSidebar";
import AdminHeader from "../AdminHeader";
import LogoutModal from "../../../components/LogoutModal";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { toast, ToastContainer, Zoom } from "react-toastify";

const OrganizationDetails = () => {
  const { orgId } = useParams(); // Get the student ID from the URL
  const [resource, setResource] = useState([]);
  const [loading, setloading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchOrgDetails = async () => {
    try {
      const response = await axios.get(`admin/organizations/${orgId}`);
      const orgDetails = response.data.organizations;
      setResource(orgDetails);
      setloading(false);
    } catch (error) {
      toast.error("Error fetching organization details:", error);
      setloading(false);
    }
  };
  useEffect(() => {
    fetchOrgDetails();
  }, []);

  const formatDateString = (dateString) => {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(dateString).toLocaleString("en-US", options);
  };

  const downloadFile = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      // Extract filename and extension
      const filename = fileUrl.split("/").pop();
      const fileExtension = filename.split(".").pop().toLowerCase();

      // Set the correct MIME type
      let mimeType = "application/octet-stream"; // Default
      if (fileExtension === "pdf") mimeType = "application/pdf";
      else if (["png", "jpg", "jpeg"].includes(fileExtension))
        mimeType = `image/${fileExtension}`;

      // Set attributes for download
      a.href = url;
      a.download = filename;
      a.type = mimeType;

      // Append to body, trigger click, then remove
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const handleAccept = async () => {
    setActionLoading(true);
    try {
      await axios.put(`admin/organizations/${orgId}/approve`);
      toast.success("Organization approved successfully", {
        theme: "colored",
        autoClose: 2000,
      });
      fetchOrgDetails();
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error approving organization"
      );
    } finally {
      setActionLoading(false);
    }
  };

  const handleReject = async () => {
    setActionLoading(true);
    try {
      await axios.put(`admin/organizations/${orgId}/reject`);
      toast.success("Organization rejected successfully", {
        theme: "colored",
        autoClose: 2000,
      });
      fetchOrgDetails();
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error rejecting organization"
      );
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <section className="container-fluid p-0">
      <AdminSidebar />
      <LogoutModal />
      <div className="main-content p-1 p-md-3 adminDashboard">
        <AdminHeader />
        <div className="container-fluid page-details">
          <div className="d-flex align-items-center">
            <Link to="/adminorg">
              <i className="bi bi-arrow-left fs-1 text-dark pe-2"></i>
            </Link>
            <span className="viga-24-400">Review Organization</span>
          </div>

          {loading ? (
            <span
              className="spinner-border spinner-border-lg text-primary"
              role="status"
            ></span>
          ) : (
            <div className="container">
              {resource.map((details) => (
                <>
                  <div className="d-block mb-4" key={details.id}>
                    <div className="d-flex align-items-center mb-2">
                      <span className="lato-24-700 pe-2">
                        Organization Name:{" "}
                      </span>
                      <span className="lato-24-400">{details.name}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="lato-24-700 pe-2">Email: </span>
                      <span className="lato-24-400">{details.email}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="lato-24-700 pe-2">Address :</span>
                      <span className="lato-24-400">{details.address}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="lato-24-700 pe-2">Country:</span>
                      <span className="lato-24-400">{details.country}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="lato-24-700 pe-2">Description:</span>
                      <span className="lato-24-400">{details.description}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="lato-24-700 pe-2">Status:</span>
                      <span className="lato-24-400">
                        {details.verified === 1 ? "Approved" : "Pending"}
                      </span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="lato-24-700 pe-2">Date Created:</span>
                      <span className="lato-24-400">
                        {formatDateString(details.created_at)}
                      </span>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary mb-4"
                    onClick={() => downloadFile(details.proveAddressDoc)}
                  >
                    Download Proof Document
                  </button>

                  <div className="d-block d-md-flex mb-3 align-items-center">
                    <div className="d-flex">
                      <button
                        className="me-2 btn btn-danger"
                        onClick={handleReject}
                        disabled={actionLoading}
                      >
                        {actionLoading ? "Processing..." : "Reject"}
                      </button>
                      <button
                        className="btn btn-primary mx-2"
                        onClick={handleAccept}
                        disabled={actionLoading}
                      >
                        {actionLoading ? "Processing..." : "Approve"}
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
    </section>
  );
};

export default OrganizationDetails;
