import { useEffect, useState } from "react";
import axios from "../../../../api/axios";
import { toast, ToastContainer, Zoom } from "react-toastify";
import quizIcon from "../../../../assets/img/Quiz.jpg";
import OrgCreateQuiz from "./OrgCreateQuiz";

const OrgCourseQuiz = ({ courseId }) => {
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(false);
  const orgId = sessionStorage.getItem("orgID");
  const [courseModal, setCourseModal] = useState(false);

  const openModal = () => setCourseModal(true);
  const closeModal = () => setCourseModal(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`Org/viewQuiz/${orgId}/${courseId}`);
        const resp = response.data;

        if (
          resp.message === "" ||
          resp.message === null ||
          resp.message === "Quiz questions not found for this course."
        ) {
          setQuiz([]);
        } else {
          setQuiz(resp.quiz_questions);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseId, orgId]);

  return (
    <section>
      <header className="d-flex">
        <button className="blue-filled-btn ms-auto" onClick={openModal}>
          Add New Quiz
        </button>
      </header>
      <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4 mt-2">
        {loading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm text-blue"
              role="status"
            ></span>
          </div>
        ) : (
          <>
            {quiz.length === 0 ? (
              <div className="text-center">
                <p>No resource available</p>
              </div>
            ) : (
              <>
                {quiz.map((quiz) => (
                  <div className="col" key={quiz.topic_id}>
                    <div
                      className="card resource-card border-0"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   openQuizInfo(quiz.quiz);
                      //   setCurrentQuiz(quiz.quiz);
                      //   setcurrentTopic(quiz.topic);
                      // }}
                      role="button"
                    >
                      <img src={quizIcon} className="card-img-top" alt="" />
                      <div className="card-img-overlay">
                        <h4 className="viga-24-400 text-white">{quiz.topic}</h4>
                        {/* <h5 className="viga-16-400 my-course-notename">
                    {quiz.course_id}
                  </h5> */}
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">{quiz.topic}</h5>
                        {/* <div className="footnote-grey">
                    <span className="lato-12-400">{quiz.pages}</span>
                    <span className="px-2 bi bi-dot"></span>
                    <span className="lato-12-400">{quiz.type}</span>
                  </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
      <ToastContainer transition={Zoom}></ToastContainer>
      <OrgCreateQuiz
        show={courseModal}
        handleClose={closeModal}
        courseId={courseId}
      />
    </section>
  );
};

export default OrgCourseQuiz;
