import Heropic from "../../assets/Hero.png";
import Video from "../../assets/Videoresources.png";
import PQ from "../../assets/img/Pastquestions solution.jpg";
import NewQuiz from "../../assets/img/Quiz.jpg";
import NewNotes from "../../assets/img/note.jpg";
import Textbook from "../../assets/img/textbook.jpg";
import Flashcard from "../../assets/img/Flashcards.jpg";
import BGHero from "../../assets/BG Hero.png";
import Resources from "../../assets/Group 239.png";
import Students from "../../assets/Group 6.png";
import { useState } from "react";
import QuizInfo from "../UserDashboard/StudyResources/QuizInfo";
import QuizModal from "../UserDashboard/StudyResources/QuizModal";
import Resource from "./Resource";

const Hero = ({ landing }) => {
  const [showQuizInfo, setShowQuizInfo] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState([]);
  const [currentTopic, setcurrentTopic] = useState([]);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [currentLink, setCurrentLink] = useState("");
  const [type, setType] = useState("");
  const openQuizInfo = () => {
    setShowQuizInfo(true);
  };
  const closeQuizInfo = () => {
    setShowQuizInfo(false);
  };
  const openQuizModal = () => {
    closeQuizInfo();
    setShowQuizModal(true);
  };
  const closeQuizModal = () => {
    setShowQuizModal(false);
  };
  const closePdfModal = () => {
    setShowPdfModal(false);
  };

  return (
    <>
      <section className="heroSec">
        <div className="mainSec">
          <img src={Heropic} className="heroPic" alt="" />{" "}
          <h1>
            L<span style={{ color: "#ffc619" }}>earn</span> anywhere, anytime!
          </h1>
          <p>Unlock Your Academic Potential with Etc Vibes.</p>
          <div className="heroResources">
            {landing.map((each) =>
              each.type === "Quiz" ? (
                <div
                  key={each.id}
                  onClick={(e) => {
                    e.preventDefault();
                    openQuizInfo(each.questions);
                    setCurrentQuiz(each.questions);
                    setcurrentTopic(each.topic);
                  }}
                  role="button"
                >
                  <img src={NewQuiz} style={{ borderRadius: "20px" }} alt="" />
                </div>
              ) : each.type === "Flashcard" ? (
                <div
                  key={each.id}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPdfModal(true);
                    setType(each.type);
                    setCurrentLink(each);
                  }}
                >
                  <img
                    style={{ borderRadius: "20px" }}
                    src={Flashcard}
                    alt=""
                  />
                </div>
              ) : (
                <div
                  key={each.id}
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentLink(each.url);
                    setShowPdfModal(true);
                    setType(each.type);
                  }}
                  role="button"
                >
                  <img
                    style={{ borderRadius: "20px" }}
                    src={
                      each.type === "textbook"
                        ? Textbook
                        : each.type === "note"
                        ? NewNotes
                        : each.type === "video"
                        ? Video
                        : PQ
                    }
                    alt=""
                  />
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <div className="bgHeroSec">
        <img src={BGHero} alt="" className="bgHero" />
        <div className="bgHeroResources">
          <img src={Resources} alt="" />
          <div>
            <h5 className="p-0 m-0">70+</h5>
            <p className="p-0 m-0">Study Resources</p>
          </div>
        </div>
        <div className="bgHeroStudents">
          <img src={Students} alt="" />
          <div>
            <h5 className="p-0 m-0">1000+</h5>
            <p className="p-0 m-0">Assisted Students</p>
          </div>
        </div>
      </div>{" "}
      <QuizInfo
        show={showQuizInfo}
        handleClose={closeQuizInfo}
        openQuizModal={openQuizModal}
      />
      <QuizModal
        show={showQuizModal}
        handleClose={closeQuizModal}
        currentQuiz={currentQuiz}
        topic={currentTopic}
      />
      <Resource
        link={currentLink}
        handleClose={closePdfModal}
        show={showPdfModal}
        type={type}
      />
    </>
  );
};

export default Hero;
