import React from "react";
import LogoutModal from "../../../components/LogoutModal";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import { TutorialContainer } from "./TutorialContainer";
import MobileNav from "../../../components/MobileNav";
import { ToastContainer, Zoom } from "react-toastify";

const UserTutorials = () => {
  return (
    <div className="container-fluid p-0">
      <DashboardSidebar />
      <LogoutModal />
      <div className="main-content userTutorials px-2 px-md-4">
        <DashboardHeader />
        <TutorialContainer />
      </div>
      <MobileNav />
      <ToastContainer transition={Zoom}></ToastContainer>
    </div>
  );
};

export default UserTutorials;
