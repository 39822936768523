import React, { useState } from "react";
import flashImg from "../../../../assets/img/Flashcards.jpg";
import { Modal, Card, Col, Row } from "react-bootstrap";
import flashCardSvg from "../../../../assets/flashCardSvg.svg";

const Flashcards = ({ feeds }) => {
  const filteredFeed = feeds.filter((item) => item.type === "flashcard");
  const [showFlashcardModal, setShowFlashcardModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] = useState(null);

  function handleNextQuestion() {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setShowAnswer(false);
  }
  const handleFlashcardClick = (card) => {
    setCurrentFlashcard(card);
    setCurrentQuestionIndex(0);
    setShowAnswer(false);
    setShowFlashcardModal(true);
  };
  return (
    <div className="feedCards">
      {filteredFeed.length === 0 && "No resources available"}
      {filteredFeed.map((each) => (
        <div className="col" key={each.id}>
          <div
            className="card resource-card border-0 h-100"
            onClick={() => handleFlashcardClick(each)}
          >
            <img src={flashImg} className="card-img-top" alt={each.type} />
            <div className="p-2 mt-4">
              <div className="d-flex">
                <h6 className="card-title">{each.topic}</h6>
                {/* <div className="ms-auto">
                  <i className="bi bi-eye pe-2"></i>
                </div> */}
              </div>
              <div className="footnote-grey">
                <h6 className="viga-14-400 m-0">ETC Vibes . {each.type}</h6>
                <div className="viga-14-400 text-grey">
                  From: {each.university}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Modal
        show={showFlashcardModal}
        onHide={() => setShowFlashcardModal(false)}
        centered
        size="md"
      >
        <Modal.Header closeButton>Flashcard Quiz</Modal.Header>
        <Modal.Body style={{ minHeight: "50vh" }}>
          {currentFlashcard && (
            <Row className="row-cols-1">
              <Col>
                <Card
                  className={`mb-3 ${showAnswer ? "flipped" : ""}`}
                  onClick={() => setShowAnswer(!showAnswer)}
                >
                  <div className="d-flex justify-content-center card-header">
                    <img src={flashCardSvg} alt="" />
                    <div className="ms-3 d-block">
                      <h3 className="poppins-24-700">
                        {currentFlashcard.topic}
                      </h3>
                    </div>
                  </div>
                  <div className="card-inner flashQuizzes">
                    <div className="card-front">
                      <Card.Body>
                        <Card.Title>
                          {
                            currentFlashcard.flashcards[currentQuestionIndex]
                              ?.question
                          }
                        </Card.Title>
                        {!showAnswer && (
                          <button className="flashcard-btn">Show Answer</button>
                        )}
                      </Card.Body>
                    </div>
                    <div className="card-back">
                      <Card.Body>
                        <Card.Title>
                          {
                            currentFlashcard.flashcards[currentQuestionIndex]
                              ?.answer
                          }
                        </Card.Title>
                        {currentQuestionIndex <
                        currentFlashcard.flashcards.length - 1 ? (
                          <button
                            onClick={handleNextQuestion}
                            className="flashcard-btn"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setCurrentQuestionIndex(0);
                              setShowAnswer(false);
                            }}
                            className="flashcard-btn"
                          >
                            Restart Quiz
                          </button>
                        )}
                      </Card.Body>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Flashcards;
