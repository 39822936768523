import { NavLink } from "react-router-dom";

const MobileNav = () => {
  return (
    <nav className="fixed-bottom d-flex d-md-flex d-lg-none w-100">
      <div className="container-fluid my-3 mx mobile-nav mx-2 px-2 shadow">
        <div className="row row-cols-5 w-100 mx-0">
          <div className="col d-flex justify-content-center py-1">
            <NavLink to="/userDashboard" className="nav-link">
              {({ isActive }) => (
                <div
                  className={`nav-item d-flex align-items-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive ? "bi-grid-fill" : "bi-grid"
                    } fs-5`}
                  ></i>
                  {isActive && <span className="ms-2 lato-14-400">Feeds</span>}
                </div>
              )}
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink to="/courses" className="nav-link">
              {({ isActive }) => (
                <div
                  className={`nav-item d-flex align-items-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive
                        ? "bi-file-earmark-richtext-fill"
                        : "bi-file-earmark-richtext"
                    } fs-5`}
                  ></i>
                  {isActive && (
                    <span className="ms-2 lato-14-400">Resources</span>
                  )}
                </div>
              )}
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink to="/usertutorials" className="nav-link">
              {({ isActive }) => (
                <div
                  className={`nav-item d-flex align-items-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive ? "bi-people-fill" : "bi-people"
                    } fs-5`}
                  ></i>
                  {isActive && <span className="ms-2 lato-14-400">Tutorial</span>}
                </div>
              )}
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink to="/awards" className="nav-link">
              {({ isActive }) => (
                <div
                  className={`nav-item d-flex align-items-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive ? "bi-award-fill" : "bi-award"
                    } fs-5`}
                  ></i>
                  {isActive && <span className="ms-2 lato-14-400">Awards</span>}
                </div>
              )}
            </NavLink>
          </div>
          <div className="col d-flex justify-content-center py-1">
            <NavLink to="/userlms" className="nav-link">
              {({ isActive }) => (
                <div
                  className={`nav-item d-flex align-items-center ${
                    isActive ? "mobile-active" : "mobile-inactive"
                  }`}
                >
                  <i
                    className={`bi ${
                      isActive ? "bi-building-fill" : "bi-building"
                    } fs-5`}
                  ></i>
                  {isActive && <span className="ms-2 lato-14-400">LMS</span>}
                </div>
              )}
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileNav;
